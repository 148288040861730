import { createHttpLink } from '@apollo/client';
import { getApiBaseUrl } from '@/core/utils';

const BASE_API_URL = getApiBaseUrl();

const httpLink = createHttpLink({
  uri: `${BASE_API_URL}/graphql`
});

export default httpLink;
