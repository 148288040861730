import React from 'react';

const CalendarInvert = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33333 3.16669H12.6667C13.1269 3.16669 13.5 3.53978 13.5 4.00002V13.3334C13.5 13.7936 13.1269 14.1667 12.6667 14.1667H3.33333C2.8731 14.1667 2.5 13.7936 2.5 13.3334V4.00002C2.5 3.53978 2.8731 3.16669 3.33333 3.16669Z"
      fill="#333333"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.668 1.33331V3.99998"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33203 1.33331V3.99998"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 6.66669H14" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CalendarInvert;
