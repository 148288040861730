export const thousandSeparators = (x) => x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;
export const bankAccount = (accountNumber) => {
  const cleanedNumber = `${accountNumber}`.replace(/\D/g, '');
  const match = cleanedNumber.match(/^(\d{4})(\d{2})(\d{5})$/);
  if (match) {
    const formatAccountNumber = `${match[1]} ${match[2]} ${match[3]}`;

    return formatAccountNumber;
  }
  return '';
};
