import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AppModal } from '@/components/ui';
import { bankAccount } from '@/core/utils';
import LeaseDetailsContext from '@/context/LeaseDetailsContext';

const SecurityDetailsModal = ({ id, leaseInfo }) => {
  const { getConvertToCurrency } = useContext(LeaseDetailsContext);
  const { t } = useTranslation();

  const showExtraDateColumn = () => {
    if (leaseInfo?.depositAccountCreatedAt && leaseInfo?.finance?.security === 'deposit_account') {
      return <td className="montserrat-500 text-gray font-14 padding-tb-20" />;
    }
    if (
      leaseInfo?.depositInsuranceCreatedAt &&
      leaseInfo?.finance?.security === 'deposit_insurance'
    ) {
      return <td className="montserrat-500 text-gray font-14 padding-tb-20" />;
    }
    return false;
  };

  const showExtraDateRow = () => {
    if (leaseInfo?.depositAccountCreatedAt || leaseInfo?.depositInsuranceCreatedAt) {
      return <td className="padding-tb-20 montserrat-500 text-faded-black font-14" />;
    }
    return false;
  };

  return (
    <AppModal
      id={id}
      type="modal-lg"
      options={{
        title: t('leases.security_details'),
        modalHeader: true,
        modalFooter: true,
        btnClose: true,
        btnCancel: false
      }}
    >
      <div className="margin-tb-30 allInvoiceModal">
        <table className="table table-striped table-borderless ">
          <thead>
            <tr>
              <td className="montserrat-500 text-gray font-14 padding-l-30 padding-tb-20">
                {t('leases.type')}
              </td>

              <td className="montserrat-500 text-gray font-14 padding-tb-20">
                {' '}
                {leaseInfo?.finance?.security === 'deposit_insurance'
                  ? t('leases.leases_status.deposit_guarantee')
                  : t('leases.leases_status.deposit_account')}
              </td>
              {showExtraDateColumn()}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                {t('user_profile.status')}
              </td>
              <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                {' '}
                {leaseInfo?.securityDetails?.status
                  ? t(`leases.leases_status.${leaseInfo?.securityDetails?.status}`)
                  : ''}
              </td>
              {(() => {
                if (
                  leaseInfo?.depositAccountCreatedAt &&
                  leaseInfo?.finance?.security === 'deposit_account'
                ) {
                  return (
                    <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                      {moment(leaseInfo?.depositAccountCreatedAt)?.format('DD.MM.YYYY')}
                    </td>
                  );
                }
                if (
                  leaseInfo?.depositInsuranceCreatedAt &&
                  leaseInfo?.finance?.security === 'deposit_insurance'
                ) {
                  return (
                    <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                      {moment(leaseInfo?.depositInsuranceCreatedAt)?.format('DD.MM.YYYY')}
                    </td>
                  );
                }
                return false;
              })()}
            </tr>

            {leaseInfo?.finance?.security === 'deposit_insurance' && (
              <tr>
                <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                  {t('leases.amount_payable')}
                </td>
                <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                  {getConvertToCurrency(leaseInfo?.securityDetails?.depositInsurancePayable)}
                </td>
                {showExtraDateRow()}
              </tr>
            )}

            {leaseInfo?.finance?.security === 'deposit_account' && (
              <tr>
                <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                  {t('label.amount')}
                </td>
                <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                  {leaseInfo?.depositAmount ? getConvertToCurrency(leaseInfo?.depositAmount) : '-'}
                </td>
                {showExtraDateRow()}
              </tr>
            )}

            <tr>
              <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                {leaseInfo?.finance?.security === 'deposit_insurance'
                  ? 'Bank Account'
                  : t('leases.deposit_account_number')}
              </td>
              <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                {leaseInfo?.securityDetails?.bankNumber
                  ? bankAccount(leaseInfo?.securityDetails?.bankNumber)
                  : '-'}
              </td>
              {showExtraDateRow()}
            </tr>

            {leaseInfo?.finance?.security === 'deposit_insurance' && (
              <tr>
                <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                  {t('leases.kid')}
                </td>
                <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                  {leaseInfo?.securityDetails?.depositInsuranceKid}
                </td>
                {showExtraDateRow()}
              </tr>
            )}

            {leaseInfo?.finance?.security === 'deposit_account' && (
              <tr>
                <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                  {t('leases.bank')}
                </td>
                <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                  {leaseInfo?.securityDetails?.depositAccountBankName === 'bn_bank'
                    ? t(`leases.${leaseInfo?.securityDetails?.depositAccountBankName}`)
                    : leaseInfo?.securityDetails?.depositAccountBankName}
                </td>
                {showExtraDateRow()}
              </tr>
            )}

            {leaseInfo?.finance?.security === 'deposit_insurance' && (
              <>
                <tr>
                  <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                    {t('leases.guaranteed_amount')}
                  </td>
                  <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                    {getConvertToCurrency(leaseInfo?.depositInsuranceAmount)}
                  </td>
                  {showExtraDateRow()}
                </tr>
                <tr>
                  <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                    {t('leases.reference_number')}
                  </td>
                  <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                    {leaseInfo?.depositInsuranceRefNumber || '-'}
                  </td>
                  {showExtraDateRow()}
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </AppModal>
  );
};

export default SecurityDetailsModal;
