import { size } from 'lodash';
import getUserId from './getUserId';

export const setUserInfo = (user, token) => {
  localStorage.setItem(`${getUserId(token)}_Info`, JSON.stringify(user));
};

export const getUserInfo = () => {
  const userId = getUserId();
  const userInfo = localStorage.getItem(`${userId}_Info`) || {};
  return size(userInfo) ? JSON.parse(userInfo) : {};
};

export const setUserRedirectPath = (pathname) => localStorage.setItem('userRedirectPath', pathname);

export const getUserRedirectPath = () => localStorage.getItem('userRedirectPath');

export const setPartnerLogo = (logoUrl) => localStorage.setItem('partnerLogo', logoUrl);

export const setPartnerSiteURL = (partnerSiteURL) =>
  localStorage.setItem('partnerSiteURL', partnerSiteURL);

export const setPartnerName = (name) => localStorage.setItem('partnerName', name);

export const getPartnerName = () => localStorage.getItem('partnerName');

export const setProfileAvatar = (url) => localStorage.setItem(`${getUserId()}_profileAvatar`, url);

export const getProfileAvatar = () => localStorage.getItem(`${getUserId()}_profileAvatar`);
