import Cookies from 'js-cookie';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

const getUserId = (newToken) => {
  const token = newToken || Cookies.get('accessToken');
  if (token) {
    const decoded = jwt_decode(token);
    const { userId } = decoded.data;
    return userId;
  }
  return '';
};

export default getUserId;
