import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import AuthLink from '@/apollo/helpers/authLink';
import ErrorLink from '@/apollo/helpers/errorLink';
import HttpLink from '@/apollo/helpers/httpLink';

const client = new ApolloClient({
  link: from([AuthLink, ErrorLink, HttpLink]),
  cache: new InMemoryCache()
});

export default client;
