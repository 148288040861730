import React from 'react';

const LeaseIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.9">
      <path
        d="M18 34.75V22.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6.5V3.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4044 8C8.52869 8 7.11299 9.70194 7.45453 11.5463L8.93601 19.5463C9.19934 20.9683 10.4397 22 11.8859 22H24.1141C25.5603 22 26.8007 20.9683 27.064 19.5463L28.5455 11.5463C28.887 9.70194 27.4713 8 25.5956 8H10.4044Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 13.25H11.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 16.75H18H13.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default LeaseIcon;
