import React from 'react';

const AppDropdown = ({ dropBtn, dropComponent, customClass = '' }) => (
  <div className={`app-dropdown ${customClass}`}>
    {dropBtn}
    {dropComponent}
  </div>
);

export default AppDropdown;
