import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Documentation from '@/views/lease/TenantLeaseProcesss/Documentation';
import TenantESigning from '@/views/lease/TenantLeaseProcesss/TenantESigning';
import LandlordESigning from '@/views/lease/TenantLeaseProcesss/LandlordESigning';
import LeaseFinalProcess from '@/views/lease/TenantLeaseProcesss/LeaseFinalProcess';
import DepositPaidPayment from '@/views/lease/TenantLeaseProcesss/DepositPaidPayment';
import AppContext from '@/context/app-context/Context';
import { canAccessLeasePage } from '@/core/utils';
import LeaseStatus from '@/views/lease/components/LeaseStatus';
import LeaseOverview from '@/views/lease/LeaseOverview';

const TenantLeaseProcess = ({
  leaseInfo = {},
  setSeeLeaseButton,
  setBackButton,
  fromLandlordPage
}) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { userTypes } = appContext;

  useEffect(() => {
    if (!canAccessLeasePage(userTypes)) return navigate('/');
    return false;
  }, []);

  const TenantLeaseProcessStep = () => {
    let currentState = '';
    const isPaidFirstInvoice =
      leaseInfo?.firstInvoice?.status === 'paid' ||
      leaseInfo?.firstInvoice?.status === 'overpaid' ||
      leaseInfo?.firstInvoice?.status === 'lost' ||
      leaseInfo?.firstInvoice?.status === 'credited';
    const isPaidDepositAmount =
      leaseInfo?.deposit?.securityStatus === 'paid' ||
      leaseInfo?.deposit?.securityStatus === 'over_paid' ||
      !leaseInfo?.deposit?.securityStatus;

    if (!leaseInfo?.leaseContractPdfGenerated) {
      currentState = (
        <div>
          <Documentation leaseInfo={leaseInfo} />
        </div>
      );
    }
    if (leaseInfo?.leaseContractPdfGenerated) {
      currentState = (
        <div>
          <Documentation active />
        </div>
      );
    }

    if (
      (fromLandlordPage &&
        !leaseInfo?.tenantLeaseSigningStatus?.signed &&
        leaseInfo?.landlordLeaseSigningStatus?.signingUrl) ||
      (!fromLandlordPage &&
        leaseInfo?.tenantLeaseSigningStatus?.signingUrl &&
        !leaseInfo?.tenantLeaseSigningStatus?.signed)
    ) {
      currentState = (
        <div>
          <TenantESigning
            signingUrl={leaseInfo?.tenantLeaseSigningStatus?.signingUrl}
            agent={leaseInfo?.agent}
            fromLandlordPage={fromLandlordPage}
            listingId={leaseInfo?.propertyId}
          />
        </div>
      );
    }

    if (
      (fromLandlordPage &&
        leaseInfo?.tenantLeaseSigningStatus?.signed &&
        !leaseInfo?.landlordLeaseSigningStatus?.signed) ||
      (!fromLandlordPage && leaseInfo?.tenantLeaseSigningStatus?.signed)
    ) {
      currentState = (
        <div>
          <LandlordESigning
            agent={leaseInfo?.agent}
            signingUrl={leaseInfo?.landlordLeaseSigningStatus?.signingUrl}
            fromLandlordPage={fromLandlordPage}
            listingId={leaseInfo?.propertyId}
          />
        </div>
      );
    }

    if (leaseInfo?.landlordLeaseSigningStatus?.signed) {
      currentState = (
        <div>
          <LeaseFinalProcess agent={leaseInfo?.agent} listingId={leaseInfo?.propertyId} />
        </div>
      );
    }

    // paid status
    if (
      leaseInfo?.landlordLeaseSigningStatus?.signed &&
      (isPaidDepositAmount ||
        isPaidFirstInvoice ||
        leaseInfo?.firstInvoice?.status ||
        leaseInfo?.securityStatus)
    ) {
      setSeeLeaseButton(true);
      currentState = (
        <div>
          <DepositPaidPayment
            deposit={leaseInfo?.deposit}
            firstInvoice={leaseInfo?.firstInvoice}
            agent={leaseInfo?.agent}
            security={leaseInfo?.securityStatus}
            fromLandlordPage={fromLandlordPage}
            listingId={leaseInfo?.propertyId}
          />
        </div>
      );
    }
    if (!currentState || (isPaidDepositAmount && isPaidFirstInvoice)) {
      return (
        <LeaseOverview
          setSeeLeaseButton={setSeeLeaseButton}
          setBackButton={setBackButton}
          leaseInfo={leaseInfo}
        />
      );
    }
    return currentState;
  };

  return (
    <div className="margin-t-10 tenantLeaseProcess">
      <TenantLeaseProcessStep />
    </div>
  );
};
export default TenantLeaseProcess;
