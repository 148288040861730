import JwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { logOut, delayCall, getApiBaseUrl } from '@/core/utils';
import { handleUnauthorizedUser } from '@/views/login/login-helper';
import { AppSpinner } from '@/components/ui';
import { getExchangeInfo } from '@/core/utils/getPriceByCurrency';

const getTokenExpiryDate = (token) => {
  if (token) {
    const decoded = JwtDecode(token);
    const { exp } = decoded;
    return exp;
  }
  return '';
};

const isTokenExpired = (token, minTime = 0) => {
  const exp = getTokenExpiryDate(token);
  if (Date.now() + minTime >= exp * 1000) {
    return true;
  }
  return false;
};

const InitialLoader = () => (
  <div style={{ marginTop: '20%' }}>
    <AppSpinner />
  </div>
);

const initialApiCall = async () => {
  await getExchangeInfo();
  return true;
};

const AuthWrapper = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');
  const refreshToken = Cookies.get('refreshToken');
  const refreshTime = 900000; // 15 minute

  useEffect(async () => {
    if (loading) {
      await initialApiCall();
    }
    if (accessToken && refreshToken) {
      if (isTokenExpired(refreshToken)) {
        await logOut();
      }

      // For initial browse
      if (
        loading &&
        !navigate?.location?.state?.fromLogin &&
        isTokenExpired(accessToken, refreshTime)
      ) {
        delayCall(() => handleUnauthorizedUser());
      }

      const intervalCall = setInterval(() => {
        delayCall(() => handleUnauthorizedUser());
      }, refreshTime);

      setLoading(false);
      return () => clearInterval(intervalCall);
    }
    setLoading(false);
    return false;
  }, [loading, accessToken]);

  useEffect(() => {
    const url = `${getApiBaseUrl()}/graphql`;
    const fetchMaintenanceError = async () => {
      await axios.get(url).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message === 'Maintenance mode on') {
          navigate('/maintenance');
        }
      });
    };

    fetchMaintenanceError();
  }, []);

  return loading ? <InitialLoader /> : <div>{children}</div>;
};

export default AuthWrapper;
