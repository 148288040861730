import Cookies from 'js-cookie';
import { size } from 'lodash';
import { getUserId } from '@/core/utils';

export const isOnlyPartnerLandlord = (userRoles) => {
  const roles = size(userRoles) ? userRoles : Cookies.get(`${getUserId()}_roles`) || [];
  if (size(roles) && JSON.parse(roles).includes('partner_landlord')) return true;
  return false;
};

export const isOnlyPartnerTenant = (userRoles) => {
  const roles = size(userRoles) ? userRoles : Cookies.get(`${getUserId()}_roles`) || [];
  if (size(roles) && JSON.parse(roles).includes('partner_tenant')) return true;
  return false;
};
