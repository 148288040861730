import { ApolloProvider } from '@apollo/client';
import loadable from '@loadable/component';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { size } from 'lodash';
import Layout from './components/layout';
import client from '@/apollo';
import PrivateRoute from '@/views/private-route/PrivateRoute';
import getValidSubDomain from '@/helpers/getValidSubDomain';
import AppSpinner from '@/components/ui/preloader/AppSpinner';
import { getUserId } from '@/core/utils';

// Context API
import AppState from '@/context/app-context/State';
import FilterState from '@/context/filter-context/FilterState';
import AuthWrapper from '@/views/login/AuthWrapper';
import LeaseDetails from '@/views/lease/leaseInfo';

// Components
const HomePage = loadable(() => import('@/views/homepage'));
const RegisterAsTenant = loadable(() => import('@/views/register/register-tenant'));
const RegisterAsLandlord = loadable(() => import('@/views/register/register-landlord'));
const FindAHome = loadable(() => import('@/views/find-home'));
const ListYourPlace = loadable(() => import('@/views/list-your-place'));
const ListingDetails = loadable(() => import('@/views/list-your-place/listings-details'));
const AdditionalFeature = loadable(() => import('@/views/list-your-place/additional-features'));
const DescriptionAndPhotos = loadable(() => import('@/views/list-your-place/description-photos'));
const PreviewAndPublishView = loadable(() => import('@/views/list-your-place/preview-publish'));
const PropertyManagement = loadable(() => import('@/views/property-management'));
const Lease = loadable(() => import('@/views/lease'));
const MyListing = loadable(() => import('@/views/my-listing'));
const Inbox = loadable(() => import('@/views/inbox'));
const Login = loadable(() => import('@/views/register/register-landlord'));
const FindHomePropertyDetail = loadable(() => import('@/views/my-listing-details'));
const MyListingPropertyDetail = loadable(() => import('@/views/my-listing-details'));
const AboutPage = loadable(() => import('@/views/about-us'));
const PrivacyPolicyPage = loadable(() => import('@/views/privacy-policy'));
const TermsAndConditionPage = loadable(() => import('@/views/terms-condition'));
const AboutCookiesPage = loadable(() => import('@/views/about-cookies'));
const NotFound = loadable(() => import('@/views/not-found'));
const LoginSuccessful = loadable(() => import('@/views/loginTest'));
const Profile = loadable(() => import('@/views/profile'));
const CreatePassword = loadable(() => import('@/views/register/create-password/index.js'));
const ResetPasswordByLink = loadable(() => import('@/views/register/ResetPassByLink.js'));
const DepositAccountkycFormPage = loadable(() => import('@/views/depositAccountkyc'));
const AcceptUserInvitation = loadable(() => import('@/views/user-accept-invitation'));
const EditListingDetails = loadable(() =>
  import('@/views/my-listing/edit-listing/edit-listing-details')
);
const EditAdditionalFeature = loadable(() =>
  import('@/views/my-listing/edit-listing/edit-additional-feature')
);
const EditDescriptionAndPhotos = loadable(() =>
  import('@/views/my-listing/edit-listing/edit-description-and-photos')
);
const PreviewAndPublish = loadable(() =>
  import('@/views/my-listing/edit-listing/PreviewAndPublish')
);
const MaintenancePage = loadable(() => import('@/views/maintenance/index'));

const InitialLoader = () => (
  <div style={{ marginTop: '20%' }}>
    <AppSpinner />
  </div>
);

const DomainWrapper = ({ children, ...rest }) => {
  if (rest?.loading || !size(rest?.subDomain)) return <InitialLoader />;
  const hasAccess = () => {
    if (rest?.subDomain?.isPublic || rest?.subDomain?.isSubDomainExists) return true;
    return false;
  };
  if (hasAccess()) return children;
  return <Navigate to="/404" />;
};

const Main = () => {
  const [partner, setPartner] = useState({});
  const [loading, setLoading] = useState(true);
  const publicToken = process.env.REACT_APP_API_KEY;

  const routes = [
    {
      path: '/',
      component: getUserId() || partner?.partnerId ? <Navigate to="/find-home" /> : <HomePage />,
      show: true
    },
    { path: '/login', component: <Login />, show: true },
    { path: '/reset-password', component: <Login />, show: true },
    { path: '/reset-password/:id', component: <Login />, show: true },
    { path: '/verify-email', component: <Login />, show: true },
    { path: '/verify-email/:id', component: <Login />, show: true },
    { path: '/register-as-tenant', component: <RegisterAsTenant />, show: true },
    { path: '/register-as-tenant/:id', component: <RegisterAsTenant />, show: true },
    { path: '/provide-new-password', component: <RegisterAsTenant />, show: true },
    { path: '/login/:id', component: <LoginSuccessful />, show: partner?.isSubDomainExists },
    {
      path: '/register-as-landlord',
      component: <RegisterAsLandlord />,
      show: partner?.isPublic
    },
    {
      path: '/register-as-landlord/:id',
      component: <RegisterAsLandlord />,
      show: partner?.isPublic
    },
    {
      path: '/find-home',
      component: <FindAHome />,
      show: true
    },
    {
      path: '/find-home/:id',
      component: <FindHomePropertyDetail />,
      show: true
    },
    {
      path: '/listing/:id',
      component: <MyListingPropertyDetail />,
      show: true
    },
    {
      path: '/privacy-policy',
      component: <PrivacyPolicyPage />,

      show: true
    },
    {
      path: '/about-cookies',
      component: <AboutCookiesPage />,

      show: true
    },
    {
      path: '/terms-conditions',
      component: <TermsAndConditionPage />,
      show: true
    },
    {
      path: '/edit-listing/listing-details/:id',
      component: <EditListingDetails />,
      show: true
    },
    {
      path: '/edit-listing/additional-feature/:id',
      component: <EditAdditionalFeature />,
      show: true
    },
    {
      path: '/edit-listing/description-and-photos/:id',
      component: <EditDescriptionAndPhotos />,
      show: true
    },
    {
      path: '/edit-listing/preview-and-publish/:id',
      component: <PreviewAndPublish />,
      show: true
    },
    {
      path: '/about-us',
      component: <AboutPage />,
      show: true
    },
    {
      path: '/property-management',
      component: <PropertyManagement />,
      show: partner?.isPublic
    },
    {
      path: '/lease',
      component: (
        <PrivateRoute>
          <Lease />
        </PrivateRoute>
      ),
      show: partner?.isSubDomainExists
    },
    {
      path: '/profile',
      component: (
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      ),
      show: true
    },
    {
      path: '/my-listing',
      component: (
        <PrivateRoute>
          <MyListing />
        </PrivateRoute>
      ),
      show: true
    },
    {
      path: '/my-listing/:slug',
      component: (
        <PrivateRoute>
          <MyListing />
        </PrivateRoute>
      ),

      show: true
    },
    {
      path: '/lease/:id',
      component: <LeaseDetails />,
      show: partner?.isSubDomainExists
    },
    {
      path: '/inbox',
      component: (
        <PrivateRoute>
          <Inbox />
        </PrivateRoute>
      ),

      show: true
    },
    {
      path: '/inbox/:chatId',
      component: (
        <PrivateRoute>
          <Inbox />
        </PrivateRoute>
      ),
      show: true
    },
    {
      path: '/list-your-place',
      component: (
        <PrivateRoute>
          <ListYourPlace />
        </PrivateRoute>
      ),
      show: partner?.isPublic
    },
    {
      path: '/list-your-place/listing-details',
      component: (
        <PrivateRoute>
          <ListingDetails />
        </PrivateRoute>
      ),
      show: partner?.isPublic
    },
    {
      path: '/list-your-place/additional-feature',
      component: (
        <PrivateRoute>
          <AdditionalFeature />
        </PrivateRoute>
      ),
      show: partner?.isPublic
    },

    {
      path: '/list-your-place/preview-and-publish',
      component: (
        <PrivateRoute>
          <PreviewAndPublishView />
        </PrivateRoute>
      ),
      show: partner?.isPublic
    },
    {
      path: '/list-your-place/description-and-photos',
      component: (
        <PrivateRoute>
          <DescriptionAndPhotos />
        </PrivateRoute>
      ),
      show: partner?.isPublic
    },
    {
      path: '/list-your-place/:id',
      component: (
        <PrivateRoute>
          <ListYourPlace />
        </PrivateRoute>
      ),
      show: partner?.isPublic
    },
    {
      path: '/create-new-password/*',
      component: <CreatePassword />,
      show: true
    },
    {
      path: '/deposit/kyc_form/:referenceNumber',
      component: <DepositAccountkycFormPage />,
      show: true
    },
    {
      path: '/acceptInvitation',
      component: <AcceptUserInvitation />,
      show: true
    }
  ];

  useEffect(() => {
    getValidSubDomain(setPartner, setLoading);
  }, [loading]);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/maintenance" element={<MaintenancePage />} />
        </Routes>

        <AuthWrapper>
          <AppState>
            <FilterState>
              <Routes>
                <Route element={<NotFound />} path="/404" />
              </Routes>
              <DomainWrapper subDomain={partner} loading={loading}>
                <Layout subDomain={partner}>
                  {size(partner) && (
                    <Routes>
                      {routes.map(({ path, component, show }) =>
                        show ? <Route path={path} key={path} element={component} /> : ''
                      )}
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  )}
                </Layout>
              </DomainWrapper>
            </FilterState>
          </AppState>
        </AuthWrapper>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default Main;
