import React from 'react';
import { AppBadge } from '@/components/ui';

const LeaseStatus = ({ leaseStatus }) => {
  if (leaseStatus === 'in_progress') {
    return <AppBadge label="In progress" variant="blue" pill />;
  }
  if (leaseStatus === 'paid') {
    return <AppBadge label="Paid" variant="purple" pill />;
  }
  if (leaseStatus === 'upcoming') {
    return <AppBadge label="Upcoming" variant="purple" pill />;
  }
  if (leaseStatus === 'new') {
    return <AppBadge label="New" variant="purple" pill />;
  }
  if (leaseStatus === 'active') {
    return <AppBadge label="active" variant="success" pill />;
  }

  return <AppBadge label="close" variant="disabled" pill />;
};

export default LeaseStatus;
