import moment from 'moment';
import { times } from 'lodash';

export const getThisDayHours = () =>
  Array.from({ length: 24 }, (_, i) => i).reduce((r, hour) => {
    r.push(moment({ hour, minute: 0 }).format('h A'));
    return r;
  }, []);

export function getCurrentWeek() {
  const currentDate = moment();
  const weekStart = currentDate.clone().startOf('week');

  const days = [];

  for (let i = 0; i <= 6; i += 1) {
    days.push(moment(weekStart).add(i, 'days').format('dd'));
  }
  return days;
}

export function getPrevWeek() {
  const currentDate = moment();
  const weekStart = currentDate.startOf('week').subtract(7, 'days');

  const days = [];

  for (let i = 0; i <= 6; i += 1) {
    days.push(moment(weekStart).add(i, 'days').format('dd'));
  }
  return days;
}

export function getCurrentMonth() {
  const daysInCurrentMonth = [];
  const curentMonthDates = moment().startOf('month');

  times(curentMonthDates.daysInMonth(), () => {
    daysInCurrentMonth.push(curentMonthDates.format('DD'));
    curentMonthDates.add(1, 'day');
  });

  return daysInCurrentMonth;
}

export function getPrevMonth() {
  const daysInPrevMonth = [];
  const prevMonthDates = moment().startOf('month').subtract(1, 'month');

  times(prevMonthDates.daysInMonth(), () => {
    daysInPrevMonth.push(prevMonthDates.format('DD'));
    prevMonthDates.add(1, 'day');
  });

  return daysInPrevMonth;
}

// Find all month
export const allMonthInYear = moment.monthsShort();
