import React from 'react';

const UniteLogo = () => (
  <svg width="113" height="39" viewBox="0 0 113 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4068 33.9665C11.7522 33.9665 9.60453 31.8395 9.60453 29.2104V19.6982C9.60453 14.4401 5.30917 10.186 0 10.186V29.2104C0 34.4686 4.29536 38.7226 9.60453 38.7226C14.9137 38.7226 19.1424 34.4025 19.2091 29.2104C19.1424 31.7866 17.0214 33.9665 14.4068 33.9665Z"
      fill="#2B8768"
    />
    <path
      d="M19.209 10.186V29.2104C19.209 34.4685 23.5043 38.7226 28.8135 38.7226V0.673828C23.5177 0.673828 19.209 4.92789 19.209 10.186Z"
      fill="#2B8768"
    />
    <path
      d="M32.7891 10.2653V0.739868H36.871V10.2653C36.871 13.1057 37.9915 14.5854 40.2593 14.5854C42.527 14.5854 43.6475 13.1057 43.6475 10.2653V0.739868H47.7295V10.2653C47.7295 15.2856 45.0482 18.126 40.2593 18.126C35.4703 18.126 32.7891 15.2724 32.7891 10.2653Z"
      fill="#F8F8FA"
    />
    <path
      d="M50.3438 0.739868H53.1718L61.2956 6.26223V0.739868H65.3775V17.8486H61.2956V10.8598L54.439 6.2358V17.8486H50.3571L50.3438 0.739868Z"
      fill="#F8F8FA"
    />
    <path d="M68.1387 0.739868H72.2206V17.8486H68.1387V0.739868Z" fill="#F8F8FA" />
    <path
      d="M78.2502 4.26731H73.5146V0.739868H87.1744V4.28052H82.4389V17.8486H78.2636L78.2502 4.26731Z"
      fill="#F8F8FA"
    />
    <path
      d="M88.4824 0.739868H100.875V4.28052H92.5643V7.46446H100.288V10.8862H92.5643V14.308H101.182V17.8486H88.4824V0.739868Z"
      fill="#F8F8FA"
    />
    <path
      d="M102.355 5.12602C102.355 2.03455 104.343 0 107.678 0C111.013 0 113 2.04776 113 5.12602C113 8.20427 111.026 10.2917 107.678 10.2917C104.33 10.2917 102.355 8.17785 102.355 5.12602ZM112 5.12602C112 2.60264 110.413 0.911586 107.678 0.911586C104.943 0.911586 103.356 2.61585 103.356 5.12602C103.356 7.63618 104.957 9.38008 107.678 9.38008C110.399 9.38008 112 7.6626 112 5.12602ZM110.279 8.13821C108.999 8.13821 108.492 7.94004 108.105 7.14736L107.985 6.90955C107.878 6.69817 107.771 6.57927 107.571 6.57927H107.318V8.08537H105.57V2.28557H107.985C109.492 2.28557 110.186 3.27642 110.186 4.32012C110.186 5.16565 109.879 5.76016 109.345 6.06402C109.452 6.11687 109.506 6.1565 109.626 6.24898L109.679 6.28862C109.786 6.36789 110.106 6.47358 110.279 6.47358V8.13821ZM107.798 5.08638C108.185 5.08638 108.412 4.875 108.412 4.47866C108.412 4.05589 108.185 3.80488 107.811 3.80488H107.304V5.08638H107.798Z"
      fill="#F8F8FA"
    />
    <path d="M32.9229 21.6138H37.0048V35.1819H44.5017V38.7225H32.9229V21.6138Z" fill="#F8F8FA" />
    <path d="M45.8223 21.6138H49.9042V38.7225H45.8223V21.6138Z" fill="#F8F8FA" />
    <path
      d="M51.0781 21.6138H55.4669L58.2415 29.5142C58.9352 31.4827 59.4154 32.8831 59.7622 34.0721C60.2291 32.8171 60.6693 31.5355 61.3763 29.4217L63.9642 21.6006H68.3529L61.3763 38.7225H58.1081L51.0781 21.6138Z"
      fill="#F8F8FA"
    />
    <path d="M69.5801 21.6138H73.662V38.7225H69.5801V21.6138Z" fill="#F8F8FA" />
    <path
      d="M76.3701 21.6138H79.1981L87.3219 27.1361V21.6138H91.4039V38.7225H87.3219V31.7337L80.452 27.1097V38.7093H76.3701V21.6138Z"
      fill="#F8F8FA"
    />
    <path
      d="M102.582 31.7602V28.8537H110.519V29.686C110.519 35.5386 107.477 39 102.181 39C96.5387 39 93.2705 35.4594 93.2705 30.1748C93.2705 24.8903 96.3786 21.3496 102.301 21.3496C104.476 21.3496 106.904 22.0366 108.398 23.3842L106.73 26.2378C105.423 25.2866 104.276 24.877 102.528 24.877C99.1933 24.877 97.5392 27.0701 97.5392 30.1748C97.5392 33.2795 99.0866 35.4726 102.181 35.4726C104.449 35.4726 106.01 34.2703 106.317 31.7734L102.582 31.7602Z"
      fill="#F8F8FA"
    />
  </svg>
);

export default UniteLogo;
