import React, { createContext, useState } from 'react';

export const sidebarContext = createContext();

// Get Item from local Storage
// eslint-disable-next-line consistent-return
function getSidebarFromLocalStorage() {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('normal')) {
      return JSON.parse(localStorage.getItem('normal'));
    }
    if (localStorage.getItem('icon-only')) {
      return JSON.parse(localStorage.getItem('icon-only'));
    }
    if (localStorage.getItem('floating')) {
      return JSON.parse(localStorage.getItem('floating'));
    }
    return 'normal';
  }
}

const SidebarContext = ({ children }) => {
  // States
  const [sidebar, setSidebar] = useState(getSidebarFromLocalStorage);

  // Click event for sidebar change
  const addBodyClass = (className) => document.body.classList.add(className);
  const removeBodyClass = (className) => document.body.classList.remove(className);

  //  Set Item in localStorage
  // useEffect(()=>{
  if (typeof window !== 'undefined') {
    const sideBarElement = document.documentElement;
    if (sidebar.toLowerCase() === 'normal') {
      localStorage.setItem('normal', JSON.stringify(sidebar));
      localStorage.removeItem('floating');
      localStorage.removeItem('icon-only');
      sideBarElement.setAttribute('sidebar', 'normal');
    } else if (sidebar.toLowerCase() === 'icon-only') {
      localStorage.setItem('icon-only', JSON.stringify(sidebar));
      localStorage.removeItem('normal');
      localStorage.removeItem('floating');
      sideBarElement.setAttribute('sidebar', 'icon-only');
      addBodyClass('sidebar-icon-only');
    } else if (sidebar.toLowerCase() === 'floating') {
      localStorage.setItem('floating', JSON.stringify(sidebar));
      localStorage.removeItem('normal');
      localStorage.removeItem('icon-only');
      sideBarElement.setAttribute('sidebar', 'floating');
      addBodyClass('sidebar-hover-only');
    } else {
      return null;
    }
  }

  // }, [sidebar]);

  // Click event for sidebar change
  const togglingLeftMenu = (action) => {
    if (action === 'active-icon-only-menu') {
      removeBodyClass('sidebar-hover-only');
      if (document.body.className === 'sidebar-icon-only') {
        removeBodyClass('sidebar-icon-only');
      } else {
        addBodyClass('sidebar-icon-only');
      }
      setSidebar('icon-only');
    } else if (action === 'active-floating-menu') {
      removeBodyClass('sidebar-icon-only');
      if (document.body.className === 'sidebar-hover-only') {
        removeBodyClass('sidebar-hover-only');
      } else {
        addBodyClass('sidebar-hover-only');
      }
      setSidebar('floating');
    } else if (action === 'active-normal-menu') {
      removeBodyClass('sidebar-icon-only');
      removeBodyClass(`sidebar-hover-only`);
      if (sidebar !== 'normal') {
        setSidebar('normal');
      }
    }
  };

  return (
    <sidebarContext.Provider value={{ sidebar, togglingLeftMenu }}>
      {children}
    </sidebarContext.Provider>
  );
};

export default SidebarContext;
