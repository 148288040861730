import Cookies from 'js-cookie';
import getUserId from './getUserId';

const logOut = async (urlReplace) => {
  const partnerLogo = localStorage.getItem('partnerLogo');
  await localStorage.clear();
  await localStorage.setItem('partnerLogo', partnerLogo);
  await Cookies.remove('accessToken');
  await Cookies.remove('refreshToken');
  // await Cookies.remove('partnerId');
  await localStorage.removeItem('userProfile');
  await Cookies.remove(`${getUserId()}_roles`);

  const redirectUrl = `https://${window.location.host}/login`;

  if (urlReplace) window.location.replace(redirectUrl);
  else window.location.location = redirectUrl;
};
export default logOut;
