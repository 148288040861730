/* eslint-disable no-plusplus */
import { map } from 'lodash';
import React from 'react';
import { Bar, defaults } from 'react-chartjs-2';

defaults.animation = false;

const RangeHistogram = ({ data, highlight, range }) => {
  const barDataValues = [];
  const backgroundColor = [];
  const labels = [];
  data
    ?.sort((a, b) => a - b)
    .forEach((item) => {
      barDataValues.push(item || 0);
      const color = '#EEEEEE';
      backgroundColor.push(color);
      labels.push(1);
    });

  const barData = {
    labels,
    datasets: [
      {
        barThickness: 10,
        maxBarThickness: 8,
        backgroundColor,
        data: barDataValues,
        borderWidth: 0
      }
    ]
  };
  const options = {
    responsive: true,
    maxBarThickness: 4,
    events: [],
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      xAxis: {
        display: false
      },
      yAxes: {
        display: false,
        ticks: {
          min: 0
        }
      }
    }
  };
  return <Bar data={barData} options={options} height={40} />;
};

export default RangeHistogram;
