/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import Nouislider from 'nouislider-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'nouislider/distribute/nouislider.css';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { size, debounce } from 'lodash';
import { AppDropdownFilter } from '../dropdown';
import { modalClose } from '@/core/utils';
import RangeHistogram from '@/components/ui/range/RangeHistogram';

const AppFilterRange = ({
  id = 'js-filter',
  callback,
  callbackClear,
  histogram,
  histogramData,
  filterBy = 'Filter name',
  minimum = 3000,
  maximum = 17000,
  minRange = 1000,
  maxRange = 20000,
  step = 0,
  removeFromListingQuery,
  addToListingQuery,
  setFilterLoading
}) => {
  const { t } = useTranslation();
  // state
  const [state, setState] = useState({
    minimum,
    maximum
  });
  const sliderRaf = useRef('');
  const [clear, setClear] = useState(false);

  // change slider
  const onSlide = (value) => {
    setState((oldValue) => ({
      ...oldValue,
      minimum: Math.round(value[0]),
      maximum: Math.round(value[1])
    }));
  };

  // handle input value
  const changeHandler = (e) => {
    const value = Number(e.target.value);

    if (e.target.name === 'minimum' && minimum < value) {
      sliderRaf.current.noUiSlider.set([value, state.maximum]);
    }
    if (e.target.name === 'maximum' && maximum > value && minimum < value) {
      sliderRaf.current.noUiSlider.set([state.minimum, value]);
    }
  };
  const debouncedChangeHandler = debounce(changeHandler, 500);
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    debouncedChangeHandler(e);
  };

  // reference
  const filterRangeRef = useRef();

  // clear value
  const handleClear = () => {
    setState({
      minimum: minRange,
      maximum: maxRange
    });
    sliderRaf.current.noUiSlider.set([minimum, maximum]);
    // eslint-disable-next-line no-unused-expressions
    callbackClear && callbackClear();
    // if (filterBy === 'monthly_rent') {
    //   localStorage.removeItem('monthlyRent');
    // }
    setClear(true);
    modalClose(filterRangeRef);
    if (setFilterLoading) setFilterLoading(true);
  };

  // Apply value
  const handleApply = () => {
    // callback
    // eslint-disable-next-line no-unused-expressions
    callback && callback(state);

    // if (clear && filterBy === 'monthly_rent') {
    //   removeFromListingQuery('monthlyRent');
    //   setClear(false);
    // } else if (clear && filterBy === 'space') {
    //   removeFromListingQuery('space');
    //   setClear(false);
    // } else if (clear && filterBy === 'number_of_bedrooms') {
    //   removeFromListingQuery('bedroom');
    //   setClear(false);
    // } else {
    //   if (filterBy === 'monthly_rent') addToListingQuery({ monthlyRent: { ...state } });
    //   if (filterBy === 'space') addToListingQuery({ space: { ...state } });
    //   if (filterBy === 'number_of_bedrooms') addToListingQuery({ bedroom: { ...state } });
    // }

    modalClose(filterRangeRef);
    if (setFilterLoading) setFilterLoading(true);
  };

  useEffect(() => {
    if (size(localStorage.getItem('monthlyRent') && id === 'monthly_rent')) {
      setState(JSON.parse(localStorage.getItem('monthlyRent')));
    }
  }, [localStorage.getItem('monthlyRent')]);

  return (
    <AppDropdownFilter
      id={`${id}`}
      ref={filterRangeRef}
      handleApply={handleApply}
      handleClear={handleClear}
      state={state}
      disabled={maximum < state.maximum || minimum > state.minimum || state.minimum > state.maximum}
    >
      <div className="dropdown-item filter-dropdown-range">
        <div className="row">
          <div className="col-12 filter-range-text">
            <h6 className="filter-range-title  padding-t-20 padding-b-10">
              {t(`filter_by.${filterBy}`)}
            </h6>
            <p className="filter-range-subtitle padding-b-20">
              {t('filter_by.slider_readme1')} {t('filter_by.slider_readme2')}{' '}
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            {histogram && (
              <RangeHistogram
                data={histogramData}
                highlight={[state.minimum, state.maximum]}
                range={[minRange, maxRange]}
              />
            )}
            <Nouislider
              instanceRef={sliderRaf}
              behaviour="smooth-sanp"
              range={{ min: [minRange], max: [maxRange] }}
              start={[minimum, maximum]}
              onUpdate={onSlide}
              step={step}
              connect
            />
          </div>
        </div>

        <div className="row">
          {/* <div className="col-12"> */}
          <div className="rate-status mt-primary d-flex justify-content-center">
            <div className="rate-left-cols">
              <p className="text-center">{t('common.minimum')}</p>
              <div className="rate-value">
                <input
                  name="minimum"
                  type="number"
                  className="rate-value-text-input"
                  id={`${id}-minimum-text`}
                  min={0}
                  value={state.minimum}
                  onChange={handleChange}
                />
                <span className="rate-value-currency">kr</span>
              </div>
              {minimum > state.minimum ? (
                <span className="error-span">Value can't be less then {minimum}</span>
              ) : (
                ''
              )}
            </div>

            <div className="rate-right-cols">
              <p className="text-center">{t('common.maximum')}</p>
              <div className="rate-value">
                <input
                  name="maximum"
                  type="number"
                  className="rate-value-text-input"
                  value={state.maximum}
                  onChange={handleChange}
                />
                <span className="rate-value-currency">kr</span>
              </div>
              {maximum < state.maximum ? (
                <span className="error-span">Value can't be More then {maximum}</span>
              ) : (
                ''
              )}
              {state.minimum > state.maximum ? (
                <span className="error-span">Value can't be less then {minimum}</span>
              ) : (
                ''
              )}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </AppDropdownFilter>
  );
};

export default AppFilterRange;
