import React from 'react';
import { FilePlus, MessageCircle, Send, User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { AppAvatar, AppButton, LandLordKeyIcon } from '@/components/ui';

const Documentation = ({ leaseInfo = {}, active }) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="row">
        <div className="col-md-8 col-xl-8 col-xxl-8 col-xxxl-9 ">
          <div className="card card-with-shadow border-0 padding-30  h-100">
            <div className="d-flex flex-column justify-content-between  h-100">
              <h6 className="montserrat-600 font-18 text-faded-black text-center margin-t-20">
                {/* {!active ? (
                'New lease'
              ) : (
                <>
                  {t('leases.the_tenancy_agreement_is_being_created_and')}
                  <br />
                  {t('leases.soon_ready_for_you_to_sign')}{' '}
                </>
              )} */}
              </h6>

              <div className="d-flex align-items-center stepProgress">
                <div className="stepProgress__container">
                  <div className="stepProgress__bar" id="progress" style={{ width: `0%` }} />

                  <div
                    className={`stepProgress__circle ${active && 'stepProgress__circle--active'}`}
                  >
                    <FilePlus />
                  </div>

                  <div className="stepProgress__circle ">
                    <Send />
                  </div>

                  <div className="stepProgress__circle">
                    <User />
                  </div>

                  <div className="stepProgress__circle svg-icon-25">
                    <LandLordKeyIcon />
                  </div>

                  <div className="stepProgress__text">
                    <span className="montserrat-500 font-14 text-faded-black">
                      {t('leases.documentation')}
                    </span>
                    <span className="montserrat-500 font-14 text-faded-black">
                      {t('leases.tenant_e_signing')}
                    </span>
                    <span className="montserrat-500 font-14 text-faded-black">
                      {t('leases.landlord_e_signing')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-xl-4 col-xxl-4 col-xxxl-3 ">
          <div className="card card-with-shadow border-0 padding-20 ">
            <div className=" padding-30 bg-base height-225">
              <h6 className="montserrat-400 font-14 text-faded-black">{t('label.agent')}</h6>
              <div className="d-flex align-items-center margin-t-20">
                <div>
                  <AppAvatar img={leaseInfo?.agent?.img} width={60} />
                </div>

                <div className="margin-l-20">
                  <h6 className="montserrat-500 font-16 text-faded-black line-height-22 ">
                    {leaseInfo?.agent?.name}
                  </h6>

                  <h6 className="montserrat-500 font-12 text-faded-black">{leaseInfo?.partner}</h6>
                </div>
              </div>

              <div className="d-flex justify-content-center margin-t-30">
                <AppButton
                  btnClass="btn btn-sub-primary"
                  icon={<MessageCircle />}
                  label={t('label.send_message')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
