import moment from 'moment';

import { getFormattedDate, updateUrlWithHeightAndWidth } from '@/core/utils';

const getAddress = ({ country, postalCode, city, streetNumber, apartmentId }) => {
  let address = '';
  if (postalCode) address += `${postalCode}, `;
  if (streetNumber) address += `${streetNumber} `;
  if (city) address += `${city} `;
  if (country) address += `${country} `;
  if (apartmentId) address += `${apartmentId} `;
  return address;
};

const getRemainingDate = (startOfDate, endOfDate) => {
  if (!startOfDate) return '';
  const endDate = endOfDate ? moment(endOfDate) : moment();
  const duration = moment.duration(endDate.diff(moment(startOfDate)));

  return {
    year: duration.years() || '',
    month: duration.months() || '',
    days: duration.days() || ''
  };
};

const getRemainingPercentage = (startOfDate, endOfDate) => {
  if (!startOfDate || moment().isSame(startOfDate) || moment(startOfDate).isAfter(moment())) {
    return 0;
  }
  const endDate = endOfDate ? moment(endOfDate) : moment();
  const daysDifference = moment(endDate).diff(startOfDate, 'days');
  const difference = moment().diff(startOfDate, 'days');
  const Percentage = Math.round((difference / daysDifference) * 100);

  return Percentage === 100 ? 0 : Percentage;
};

const getInvoiceAmount = (rentStatus, amount = 0) => {
  if (rentStatus === 'paid') return 'All good!';
  if (rentStatus === 'over_paid') return `+${amount} `;
  if (rentStatus === 'to_pay') return `${amount} `;
  if (['overdue', 'eviction_notice', 'eviction_notice_due'].includes(rentStatus)) return amount;
  return '';
};

// For invoice status label
const getInvoiceLabel = (rentStatus) => {
  if (['paid', 'over_paid'].includes(rentStatus)) return 'next_invoice';
  if (['to_pay', 'overdue', 'eviction_notice'].includes(rentStatus)) return 'due_date';
  if (rentStatus === 'eviction_notice_due') return 'eviction';
  return '';
};

// For invoice button label
const getInvoiceOpenLabel = (rentStatus) => {
  if (rentStatus === 'paid') return 'all_invoice';
  if (rentStatus === 'to_pay' || rentStatus === 'overdue') return 'open_invoice';
  if (rentStatus === 'eviction_notice') return 'eviction_notice';
  if (rentStatus === 'over_paid') return '';
  if (rentStatus === 'eviction_notice_due') return 'eviction_letter';
  return '';
};

const getPaidStatus = (status, type) => {
  if (
    status &&
    ((!['created', 'due', 'partially_paid']?.includes(status) && type === 'deposit_insurance') ||
      (['paid', 'overpaid']?.includes(status) && type === 'deposit_account'))
  ) {
    return true;
  }
  return false;
};

const prepareSingleLease = (lease) => {
  const {
    _id = '',
    propertyId = '',
    property = {},
    leaseStatus = '',
    isMovedIn = false,
    numOfIssues = 0,
    amount = 0,
    invoiceStatus = '',
    partner = '',
    contractEndDate = '',
    contractStartDate = '',
    agent = { name: '', avatar: '' },
    rentStatus = '',
    invoiceAmount = '',
    monthlyRentAmount = '',
    depositAmount = '',
    totalPaymentAmount = 0,
    depositAccountStatus = '',
    depositAccountNumber = '',
    depositAccountBankName = '',
    depositAccountCreatedAt,
    monthlyPayoutDate = '',
    assignmentTo = '',
    assignmentFrom = '',
    brokeringCommissionType = '',
    brokeringCommissionAmount = '',
    rentalManagementCommissionType = '',
    rentalManagementCommissionAmount = '',
    addons = '',
    nextPayoutDate = '',
    invoiceAccountNumber = '',
    accountNumber = '',
    kidNumber = '',
    payBeforeMonth = '',
    invoiceDueDate = '',
    evictionNoticeSentOn = '',
    leaseFileId = '',
    depositInsuranceStatus = '',
    depositInsuranceBankAccount = '',
    depositInsuranceKid = '',
    depositInsuranceAmount = '',
    depositInsurancePayable = '',
    depositInsuranceCreatedAt,
    depositInsuranceRefNumber,
    security = '',
    dueDate = '',
    payoutTo = '',
    nextPayoutAmount = 0,
    payBeforeMonthEnabled = '',
    nextInvoiceDate = '',

    // Tenant/landlord step data
    leaseContractPdfGenerated = '',
    enabledEsigning = '',
    tenantLeaseSigningStatus = {},
    landlordLeaseSigningStatus = {},
    firstInvoice = {},
    agentAssignmentSigningStatus = {},
    assignmentContractPdfGenerated = '',
    landlordAssignmentSigningStatus = {},
    enabledLeaseEsigning = false,
    leaseSerial = '',
    invoicePaidAfterPayoutDate,
    evictionNoticeFileId,
    evictionLetterFileId
  } = lease;

  const {
    name = '',
    placeId = '',
    city = '',
    cityPlaceId = '',
    lat = '',
    lng = '',
    country = '',
    streetNumber = '',
    sublocality = '',
    countryShortName = '',
    postalCode = '',
    apartmentId = ''
  } = property;
  const todayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const getSecurityAmount = () => {
    if (security === 'deposit_account') return depositAmount;
    if (security === 'deposit_insurance') return depositInsuranceAmount;
    return '';
  };

  const getSecurityStatus = () => {
    if (security === 'deposit_account') return depositAccountStatus;
    if (security === 'deposit_insurance') return depositInsuranceStatus;
    return '';
  };
  const getInvoiceDate = (status) => {
    if (status === 'paid' || status === 'over_paid') return nextInvoiceDate;
    if (status === 'eviction_notice_due') return evictionNoticeSentOn;
    if (['overdue', 'eviction_notice', 'to_pay'].includes(status)) return invoiceDueDate;
    return '';
  };

  const getBankNumber = () => {
    if (security === 'deposit_account') return depositAccountNumber;
    if (security === 'deposit_insurance') return depositInsuranceBankAccount;
    return 0;
  };

  return {
    id: _id,
    propertyId,
    locationTitle: name,
    address: getAddress({ country, postalCode, city, streetNumber, apartmentId }),
    partner,
    date: `${getFormattedDate(contractStartDate, 'D.M.YYYY')} - ${
      contractEndDate ? getFormattedDate(contractEndDate, 'D.M.YYYY') : 'undetermined'
    }`,
    leaseStatus,
    leaseFileId,
    leaseSerial,
    rentStatus: invoiceStatus,
    amount: Math.round(amount),
    remaining: getRemainingDate(todayDate, contractEndDate),
    remainingPercentage: getRemainingPercentage(contractStartDate, contractEndDate),
    issues: numOfIssues,
    moveIn: isMovedIn,
    moveOut: isMovedIn === false,
    property,
    agent: {
      name: agent.name,
      img: updateUrlWithHeightAndWidth(agent.avatar) || '/assets/images/user.png',
      tenantId: agent.tenantId,
      phone: agent?.phoneNumber,
      email: agent?.email,
      partner
    },
    tenantStatusEnum: [
      'eviction_notice_due',
      'eviction_notice',
      'overdue',
      'to_pay',
      'over_paid',
      'paid'
    ],
    securityDetails: {
      depositAccountStatus,
      depositAccountNumber,
      depositAccountBankName: depositAccountBankName || 'bn_bank',
      payoutTo,
      bankNumber: getBankNumber(),
      guaranteedAmount: 0,
      depositInsurancePayable,
      depositInsuranceKid,
      status: getSecurityStatus(),
      isPaid: getPaidStatus(getSecurityStatus(), security)
    },
    // for Tenant
    finance: {
      monthlyRentAmount,
      accountNumber,
      dueDate,
      invoiceAccountNumber,
      kidNumber,
      // security
      security: security && security !== 'no_deposit' ? security : '',
      securityAmount: getSecurityAmount()
    },

    invoiceInfo: {
      invoiceStatus,
      amount: getInvoiceAmount(invoiceStatus, Math.round(amount)),
      invoiceLabel: getInvoiceLabel(invoiceStatus),
      invoiceDate: getInvoiceDate(invoiceStatus),
      invoiceOpenLabel: getInvoiceOpenLabel(invoiceStatus)
    },
    // For landlord
    payoutInfo: {
      monthlyRentAmount,
      dueDate: dueDate || 0,
      security: security && security !== 'no_deposit' ? security : '',
      securityAmount: getSecurityAmount(),
      // Payout
      monthlyPayoutDate: monthlyPayoutDate || 0,
      payoutTo,
      payoutAmount: Math.round(amount),
      // For next payout
      nextPayoutAmount,
      nextPayoutDate: nextPayoutDate?.replace(/,/g, '.'),
      invoiceAmount,
      rentStatus
    },
    assignmentInfo: {
      period: `${assignmentFrom} - ${assignmentTo || 'undetermined'}`,
      guarantee: payBeforeMonthEnabled ? `Yes - ${payBeforeMonth}` : '',
      addons,
      // commission
      brokeringCommissionAmount:
        brokeringCommissionType === 'percent'
          ? `${brokeringCommissionAmount} %`
          : brokeringCommissionAmount,
      rentalManagementCommissionAmount:
        rentalManagementCommissionType === 'percent'
          ? `${rentalManagementCommissionAmount} %`
          : brokeringCommissionAmount
    },
    // landlord/tenant process page
    deposit: {
      bankNumber: getBankNumber(),
      securityStatus: getSecurityStatus(),
      securityAmount: getSecurityAmount(),
      depositInsuranceKid,
      security: security && security !== 'no_deposit' ? security : '',
      isPaid: getPaidStatus(getSecurityStatus(), security)
    },
    leaseContractPdfGenerated,
    enabledEsigning,
    tenantLeaseSigningStatus,
    landlordLeaseSigningStatus,
    firstInvoice,
    agentAssignmentSigningStatus,
    assignmentContractPdfGenerated,
    landlordAssignmentSigningStatus,
    enabledLeaseEsigning,
    depositAccountCreatedAt,
    depositInsuranceCreatedAt,
    depositInsuranceRefNumber,
    depositInsuranceAmount,
    depositAmount,
    invoicePaidAfterPayoutDate: invoicePaidAfterPayoutDate || false,
    evictionNoticeFileId: evictionNoticeFileId || '',
    evictionLetterFileId: evictionLetterFileId || ''
  };
};
export default prepareSingleLease;
