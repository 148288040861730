import React, { createContext, useState } from 'react';

export const themeContext = createContext();

// Get Item from local Storage
function getThemeFromLocalStorage() {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('dark')) {
      return JSON.parse(localStorage.getItem('dark'));
    }
    if (localStorage.getItem('light')) {
      return JSON.parse(localStorage.getItem('light'));
    }
    return 'light';
  }
  return '';
}

const ThemeContext = ({ children }) => {
  const [theme, setTheme] = useState(getThemeFromLocalStorage);

  // useEffect(()=>{
  if (typeof window !== 'undefined') {
    const htmlElement = document.documentElement;
    if (theme.toLowerCase() === 'light') {
      localStorage.setItem('light', JSON.stringify(theme));
      localStorage.removeItem('dark');
      htmlElement.setAttribute('theme', 'light');
    } else if (theme.toLowerCase() === 'dark') {
      localStorage.setItem('dark', JSON.stringify(theme));
      localStorage.removeItem('light');
      htmlElement.setAttribute('theme', 'dark');
    } else {
      return null;
    }
  }
  // }, [theme])

  // Click event for theme change
  const handleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  return <themeContext.Provider value={{ theme, handleTheme }}>{children}</themeContext.Provider>;
};

export default ThemeContext;
