import Cookies from 'js-cookie';
import { onError } from '@apollo/client/link/error';
import { handleUnauthorizedUser } from '@/views/login/login-helper';
import { toastCall, toastConfig, translateLanguage, logOut, delayCall } from '@/core/utils';

toastConfig();
const toastMsg = (msg) => {
  toastCall('danger', msg, 'top-right');
};

const redirectToLoginPage = () => {
  Cookies.remove('accessToken');
  Cookies.remove('refreshToken');
  window.location.replace(`https://${window.location.host}/login`);
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (message === 'Maintenance mode on') {
        window?.location?.replace('/maintenance');
      }
    });

    if (graphQLErrors[0]?.message === 'account_deactivated') {
      delayCall(() => {
        toastMsg(translateLanguage('errors.you_have_been_deactivated'));

        setTimeout(() => {
          logOut(true);
        }, 500);
      });
    }
  }

  if (networkError) {
    const { statusCode } = networkError;
    if (statusCode === 403) {
      handleUnauthorizedUser().then((tokenRefreshed) => {
        if (tokenRefreshed) {
          window.location.replace(window.location.href);
        } else {
          redirectToLoginPage();
        }
      });
    } else if (statusCode === 400) {
      toastMsg(translateLanguage('errors.bad_request'));
    } else if (statusCode === 401 || statusCode === 503) {
      toastMsg(translateLanguage('errors.unauthorized'));
      redirectToLoginPage();
    } else if (statusCode === 404) {
      toastMsg(translateLanguage('errors.not_found'));
    } else if (statusCode === 500) {
      toastMsg(translateLanguage('errors.internal_server_error'));
    } else {
      toastMsg(translateLanguage('errors.bad_request'));
    }
  }
});

export default errorLink;
