import React from 'react';

const CheckActive = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21" cy="21" r="21" fill="#2B8768" />
    <line
      x1="11.9295"
      y1="20.8373"
      x2="19.4518"
      y2="27.2849"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="square"
    />
    <line
      x1="20.4112"
      y1="28.0567"
      x2="30.1218"
      y2="14.3564"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="square"
    />
  </svg>
);

export default CheckActive;
