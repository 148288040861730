/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import Calendar from 'react-date-range/dist/components/Calendar';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { removeAllShowClass } from '@/core/utils';
import { AppFilterButton, AppButton } from '..';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfDay(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
  oneYearAhead: addYears(startOfDay(addDays(new Date(), -1)), 1),
  twoYearAhead: addYears(startOfDay(addDays(new Date(), -1)), 2),
  threeYearAhead: addYears(startOfDay(addDays(new Date(), -1)), 3)
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  }
};

function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

// function convert(str) {
//   const months = {
//     Jan: '01',
//     Feb: '02',
//     Mar: '03',
//     Apr: '04',
//     May: '05',
//     Jun: '06',
//     Jul: '07',
//     Aug: '08',
//     Sep: '09',
//     Oct: '10',
//     Nov: '11',
//     Dec: '12'
//   };
//   const dateNew = str.split(' ');

//   return [dateNew[2], months[dateNew[1]], dateNew[3]].join('.');
// }

const AppCalendar = ({
  callBack,
  context,
  id,
  options: {
    type = '',
    placeholder = 'Pick a date',
    btnProps,
    disablePrevDates,
    initialDate,
    initialDateRange = {
      startDate: '',
      endDate: ''
    },
    dateFormat = '',
    languageKey = 'calendar',
    disabledCalendarClear,
    labels = ['Today', 'Yesterday', 'This week', 'Last week', 'This month', 'Last month']
  } = {}
}) => {
  // Date conversion for showing input value
  const FormatDateForInput = (date) => {
    if (dateFormat === 'YYYY.MM.DD') {
      return moment(date).format('YYYY.MM.DD');
    }
    const dateToArray = moment(date).format('YYYY/MM/DD').split('/');
    return [dateToArray[2], dateToArray[1], dateToArray[0]].join('.');
  };

  // Convert Date format to initialize date props
  const convertDate = (date) => {
    if (dateFormat === 'YYYY.MM.DD') {
      const newDate = `${date}`.split('.');
      const formatDate = [newDate[1], newDate[2], newDate[0]].join('.');
      return formatDate;
    }
    const newDate = `${date}`.split('.');

    const formatDate = [newDate[1], newDate[0], newDate[2]].join('.');
    return formatDate;
  };

  const removeShowClass = () => {
    const allFilterClasses = document.querySelectorAll('.input-date-range');
    removeAllShowClass(allFilterClasses);
  };

  // Initialize Date in Date range
  const initialDateRangeFunction = () => {
    if (Object.values(initialDateRange)[0] !== '' && Object.values(initialDateRange)[1] !== '') {
      const preDate = {
        startDate: new Date(convertDate(initialDateRange.startDate)),
        endDate: new Date(convertDate(initialDateRange.endDate)),
        key: 'selection'
      };
      return [preDate];
    }

    return [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ];
  };

  const { t } = useTranslation();
  const [date, setDate] = useState(initialDate !== undefined && new Date(convertDate(initialDate)));
  const [showInputData, setShowInputData] = useState(initialDate);
  const [showInputDateRange, setShowInputDateRange] = useState(
    Object.values(initialDateRange)[0] !== ''
      ? `${initialDateRange?.startDate} - ${initialDateRange?.endDate}`
      : placeholder
  );

  const [showInputSingleDateRange, setShowInputSingleDateRange] = useState(
    Object.values(initialDateRange)[0] !== '' ? `${initialDateRange?.endDate}` : placeholder
  );

  const [dateRange, setDateRange] = useState(initialDateRangeFunction());

  const defaultStaticRange = createStaticRanges([
    {
      label: t(`${languageKey}.today`),
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday
      })
    },
    {
      label: t(`${languageKey}.yesterday`),
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday
      })
    },

    {
      label: t(`${languageKey}.this_week`),
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek
      })
    },
    {
      label: t(`${languageKey}.last_week`),
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek
      })
    },
    {
      label: t(`${languageKey}.this_month`),
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth
      })
    },
    {
      label: t(`${languageKey}.last_month`),
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth
      })
    },
    {
      label: t(`${languageKey}.this_year`),
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear
      })
    },
    {
      label: t(`${languageKey}.last_year`),
      range: () => ({
        startDate: defineds.startOfLastYear,
        endDate: defineds.endOfLastYear
      })
    },
    {
      label: t(`${languageKey}.one_year_ahead`),
      range: () => ({
        startDate: dateRange[0].startDate,
        endDate: addYears(startOfDay(addDays(dateRange[0].startDate, -1)), 1)
      })
    },
    {
      label: t(`${languageKey}.two_year_ahead`),
      range: () => ({
        startDate: dateRange[0].startDate,
        endDate: addYears(startOfDay(addDays(dateRange[0].startDate, -1)), 2)
      })
    },
    {
      label: t(`${languageKey}.three_year_ahead`),
      range: () => ({
        startDate: dateRange[0].startDate,
        endDate: addYears(startOfDay(addDays(dateRange[0].startDate, -1)), 3)
      })
    }
  ]);

  const defaultStaticRanges = defaultStaticRange.filter((i) => labels.find((f) => f === i.label));

  const handleDate = (item) => {
    setShowInputData(FormatDateForInput(item));
    callBack(item, context);
  };

  // Apply button
  const handleApply = () => {
    if (dateRange[0].startDate !== null && dateRange[0].endDate !== null) {
      const start = FormatDateForInput(dateRange[0].startDate);

      const end = FormatDateForInput(dateRange[0].endDate);
      // callBack(`${start} - ${end}`);
      if (callBack) {
        callBack(dateRange, context);
      }

      if (type === 'input-date-range') {
        setShowInputDateRange(`${start} - ${end}`);
      }

      if (type === 'input-single-date-range') {
        setShowInputSingleDateRange(`${end}`);
      }

      setShowInputData(`${start} - ${end}`);

      // remove show class
      const allFilterClasses = document.querySelectorAll('.input-date-range');
      removeAllShowClass(allFilterClasses);

      const allFilterClassesBtn = document.querySelectorAll('.btn-filter');
      removeAllShowClass(allFilterClassesBtn);

      if (type === 'dropdown-calendar' || type === 'dropdown-date-range') {
        const allFilterClassesDropdownBtn = document.querySelectorAll('.btn');
        removeAllShowClass(allFilterClassesDropdownBtn);
      }
    }
  };

  const handleClear = () => {
    if (type !== 'input-calendar') {
      setDateRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);

      // remove show class
      const allFilterClasses = document.querySelectorAll('.input-date-range');
      removeAllShowClass(allFilterClasses);

      const allFilterClassesBtn = document.querySelectorAll('.btn-filter');
      removeAllShowClass(allFilterClassesBtn);

      if (type === 'dropdown-calendar' || type === 'dropdown-date-range') {
        const allFilterClassesDropdownBtn = document.querySelectorAll('.btn');
        removeAllShowClass(allFilterClassesDropdownBtn);
      }
    }

    if (type === 'input-calendar') {
      setDate(null);
    }
    if (callBack) {
      callBack('clear', context);
    }

    setShowInputData('');
    setShowInputDateRange('');
    setShowInputSingleDateRange('');
  };

  const handleDefaultDropdownBehaviour = (e) => {
    // if (!type.split('-').some((item) => item === 'calendar') ) {

    // }
    e.stopPropagation();
  };

  return (
    <div className="app-dropdown app-input-dropdown">
      {type === 'filter-date-range' && <AppFilterButton {...btnProps} />}

      {type === 'dropdown-calendar' && <AppButton id={`btn-${id}`} dropdown="true" {...btnProps} />}

      {type === 'dropdown-date-range' && (
        <AppButton id={`btn-${id}`} dropdown="true" {...btnProps} />
      )}

      {type === 'input-calendar' && (
        <div
          className="form-group single-item-search input-date-range"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <input
            type="text"
            className="form-control"
            value={showInputData}
            onChange={() => {}}
            placeholder={placeholder}
          />
          <div className="select-down-icon svg-icon-20">
            <CalendarIcon />
          </div>
        </div>
      )}

      {type === 'filter-calendar' && <AppFilterButton {...btnProps} />}

      {/* Input date range */}
      {type === 'input-date-range' && (
        <div
          className="form-group single-item-search input-date-range"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <input
            type="text"
            className="form-control"
            placeholder={placeholder}
            value={showInputDateRange}
            onChange={() => {}}
          />
          <div className="select-down-icon svg-icon-20">
            <CalendarIcon />
          </div>
        </div>
      )}

      {type === 'input-single-date-range' && (
        <div
          className="form-group single-item-search input-date-range"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <input
            type="text"
            className="form-control"
            placeholder={placeholder}
            value={showInputSingleDateRange}
            onChange={() => {}}
          />
          <div className="select-down-icon svg-icon-20">
            <CalendarIcon />
          </div>
        </div>
      )}

      <ul
        className="dropdown-menu  single-item-search input-date-range"
        aria-labelledby="dropdownMenuButton"
        onClick={handleDefaultDropdownBehaviour}
        role="menu"
        tabIndex="0"
      >
        <div className="dropdown-wrapper">
          <div className="filter-datepicker">
            {type === 'input-calendar' && (
              <Calendar
                onChange={(item) => {
                  setDate(item);
                  removeShowClass();
                  handleDate(item);
                }}
                date={date}
                showMonthArrow
                color="#1c2e45"
                minDate={disablePrevDates && addDays(new Date(), 0)}
              />
            )}

            {type === 'dropdown-calendar' && (
              <Calendar
                onChange={(item) => {
                  setDate(item);
                  handleDate(item);
                }}
                date={date}
                showMonthArrow={false}
                color="#1c2e45"
              />
            )}

            {type === 'filter-calendar' && (
              <Calendar
                onChange={(item) => {
                  setDate(item);
                  handleDate(item);
                }}
                date={date}
                showMonthArrow={false}
                color="#1c2e45"
              />
            )}

            {(type === 'input-date-range' || type === 'input-single-date-range') && (
              <DateRangePicker
                onChange={(item) => {
                  setDateRange([item.selection]);
                }}
                showSelectionPreview
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={dateRange}
                direction="vertical"
                showMonthAndYearPickers
                showMonthArrow
                rangeColors={['#1c2e45']}
                showDateDisplay={false}
                editableDateInputs={false}
                inputRanges={[]}
                showPreview
                weekdayDisplayFormat="EEEEEE"
                staticRanges={defaultStaticRanges}
              />
            )}
            {type === 'filter-date-range' && (
              <DateRangePicker
                onChange={(item) => {
                  setDateRange([item.selection]);
                }}
                showSelectionPreview
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={dateRange}
                direction="vertical"
                showMonthAndYearPickers
                showMonthArrow
                rangeColors={['#1c2e45']}
                showDateDisplay={false}
                editableDateInputs={false}
                inputRanges={[]}
                showPreview
                weekdayDisplayFormat="EEEEEE"
                minDate={disablePrevDates && addDays(new Date(), 0)}
                staticRanges={defaultStaticRanges}
              />
            )}

            {type === 'dropdown-date-range' && (
              <DateRangePicker
                onChange={(item) => {
                  setDateRange([item.selection]);
                }}
                showSelectionPreview
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={dateRange}
                direction="vertical"
                showMonthAndYearPickers
                showMonthArrow
                rangeColors={['#1c2e45']}
                showDateDisplay={false}
                editableDateInputs={false}
                inputRanges={[]}
                showPreview
                weekdayDisplayFormat="EEEEEE"
                staticRanges={defaultStaticRanges}
              />
            )}
          </div>

          <div className="filter-dropdown-footer">
            <div className="dropdown-divider" />
            <div
              className={`padding-tb-20 padding-lr-30 d-flex align-items-center ${
                type.split('-').some((item) => item === 'calendar')
                  ? 'justify-content-end'
                  : 'justify-content-between'
              } `}
            >
              <span
                className={`${disabledCalendarClear ? 'disabled' : ''}`}
                onClick={handleClear}
                role="button"
                tabIndex="0"
              >
                {t('common.clear')}
              </span>

              {!type.split('-').some((item) => item === 'calendar') ? (
                <button type="button" className={`btn btn-sub-primary `} onClick={handleApply}>
                  Apply
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </ul>
    </div>
  );
};

const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-calendar"
  >
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
    <line x1="16" y1="2" x2="16" y2="6" />
    <line x1="8" y1="2" x2="8" y2="6" />
    <line x1="3" y1="10" x2="21" y2="10" />
  </svg>
);

export default AppCalendar;
