import {
  handleSetCity,
  handleSetCountry
} from '@/views/list-your-place/listings-details/helpers/getLocation';

const getMapLocationForInput = async (location) => {
  try {
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode({
      location
    });
    if (response && response.results[0]) {
      const city = handleSetCity(response.results[0].address_components, 'long-name');
      const country = handleSetCountry(response.results[0]?.address_components, 'long-name');
      return { city, country };
    }
    return {};
  } catch (e) {
    return {};
  }
};

export default getMapLocationForInput;
