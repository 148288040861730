import React, { useCallback, useEffect, useState } from 'react';
import { User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { size } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { AppModal, AppAvatar, AppSpinner } from '@/components/ui';
import { GET_TENANT_DETAILS } from '../graphql/getTenantDetails.gql';
import { delayCall, toastCall } from '@/core/utils';

const TenantDetailsModal = ({ contractId, openModal }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [tenantInfo, setTenantInfo] = useState({});

  const updateTenantInfo = useCallback((data) => {
    if (data) {
      const { getLeaseTenants } = data;
      if (size(getLeaseTenants)) setTenantInfo(getLeaseTenants?.data);
      setIsLoading(false);
    }
  }, []);

  const [getTenants] = useLazyQuery(GET_TENANT_DETAILS, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      queryData: { contractId }
    },
    onCompleted: (data) => updateTenantInfo(data)
  });

  useEffect(() => {
    if (openModal) {
      setIsLoading(true);
      getTenants().then((response) => {
        if (response?.errors) {
          delayCall(() => {
            if (response?.errors[0].statusCode === 404)
              toastCall('danger', t('errors.unauthorized'));
            else toastCall('danger', t('errors.bad_request'));
          });
        }
      });
    }
  }, [openModal]);

  const LoaderOrEmptyList = () => {
    if (isLoading) return <AppSpinner />;
    return t('label.nothing_found');
  };

  return (
    <AppModal
      title={t('leases.tenant_details')}
      id={`tenant-details-${contractId}`}
      type="modal-lg"
      options={{
        title: t('leases.tenant_details'),
        modalHeader: true,
        modalFooter: true,
        btnClose: true,
        btnCancel: false
      }}
    >
      <div className="padding-30">
        {!isLoading && size(tenantInfo) ? (
          <>
            <h6 className="montserrat-500 text-gray font-12">{t('leases.main_tenant')}</h6>
            <div className="d-flex align-items-center margin-t-20">
              {tenantInfo?.mainTenant?.user?.avatarKey ? (
                <AppAvatar img={tenantInfo?.mainTenant?.user?.avatarKey} />
              ) : (
                <AppAvatar icon={<User />} />
              )}
              <div className="margin-l-15">
                <h6 className="montserrat-500 text-faded-black font-18">
                  {' '}
                  {tenantInfo?.mainTenant?.user?.name}
                </h6>
                <div className="d-flex align-items-center margin-t-5">
                  {tenantInfo?.mainTenant?.user?.phoneNumber && (
                    <h6 className="montserrat-500 text-gray font-16">
                      T
                      <span className="font-16 margin-l-5">
                        {tenantInfo?.mainTenant?.user?.phoneNumber}
                      </span>
                    </h6>
                  )}

                  <h6 className="montserrat-500 text-gray font-16 margin-l-30">
                    E
                    <span className="font-16 margin-l-5">
                      {tenantInfo?.mainTenant?.user?.email}
                    </span>
                  </h6>
                </div>
              </div>
            </div>

            {tenantInfo?.coTenants[0].user ? (
              <>
                <div className="dropdown-divider margin-tb-20" />
                <h6 className="montserrat-500 text-gray font-12">{t('leases.co_tenant')}</h6>
                {tenantInfo.coTenants.map((tenant) => (
                  <>
                    <div className="d-flex align-items-center margin-t-20">
                      <div className="dropdown-divider margin-tb-20" />

                      {tenant?.user?.avatarKey ? (
                        <AppAvatar img={tenant?.user?.avatarKey} />
                      ) : (
                        <AppAvatar icon={<User />} />
                      )}
                      <div className="margin-l-15">
                        <h6 className="montserrat-500 text-faded-black font-18">
                          {tenant?.user?.name}
                        </h6>
                        <div className="d-flex align-items-center margin-t-5">
                          {tenant?.user?.phoneNumber && (
                            <h6 className="montserrat-500 text-gray font-16">
                              T<span className="font-16 margin-l-5">{tenant.user.phoneNumber}</span>
                            </h6>
                          )}

                          {tenant?.user?.email && (
                            <h6 className="montserrat-500 text-gray font-16 margin-l-30">
                              E <span className="font-16 margin-l-5"> {tenant.user.email}</span>
                            </h6>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-divider margin-tb-20" />
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <span className="padding-40 margin-t-30 border-0 text-center">
            <LoaderOrEmptyList />
          </span>
        )}
      </div>
    </AppModal>
  );
};

export default TenantDetailsModal;
