import React, { useContext, useEffect, useState } from 'react';
import { ChevronDown, User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { PublicSiteSidebarData } from '@/data';
import { canAccessLeasePage, updateUrlWithHeightAndWidth } from '@/core/utils';
import { layoutContext } from '@/components/layout/context';
import { AppAvatar } from '@/components/ui';
import AppContext from '@/context/app-context/Context';
import { getProfileAvatar } from '@/core/utils/localStorage';

const Sidebar = ({ subDomain }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const { userTypes, user } = appContext;

  const sidebarFilteredData = PublicSiteSidebarData.filter((item) => {
    if (subDomain && subDomain?.isSubDomainExists && item.key === 'myListing') return false;
    if (
      (!subDomain || !subDomain?.isSubDomainExists || !canAccessLeasePage(userTypes)) &&
      item.key === 'leases'
    )
      return false;
    return true;
  });

  // Context value
  const { sidebarOffCanvas } = useContext(layoutContext);

  // submenu open
  const [subMenu, setSubMenu] = useState({
    indexValue: null,
    action: false
  });

  // Submenu State handler
  const handleSubMenu = (item, index) => {
    if (item.subNav) {
      setSubMenu({ indexValue: index, action: true });
      if (subMenu.action && subMenu.indexValue === index) {
        setSubMenu({ indexValue: index, action: false });
      } else {
        setSubMenu({ indexValue: index, action: true });
      }
    }
  };

  // Get active nav class
  const getActiveNav = (path) => {
    const locationArray = location.pathname.split('/');
    const newArray = locationArray.slice(1);
    const firstItemofNewArray = `/${newArray[0]}`;

    if (firstItemofNewArray === path) {
      return 'active';
    }
    return '';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Get show subnav class
  const getActiveShow = (path) => {
    const locationArray = location.pathname.split('/');
    const newArray = locationArray.slice(1);
    const firstItemofNewArray = `/${newArray[0]}/${newArray[1]}`;

    if (firstItemofNewArray === path) {
      return 'show';
    }
    return '';
  };

  const avatarProps = {
    width: '50',
    bgColor: 'bg-primary'
  };

  if (user?.avatar || getProfileAvatar()) {
    const profileAvatar = user?.avatar || getProfileAvatar();
    avatarProps.img = updateUrlWithHeightAndWidth(profileAvatar);
  } else {
    avatarProps.icon = <User />;
  }

  return (
    <>
      <nav
        className={`sidebar publicSite-sidebar sidebar-offcanvas ${
          sidebarOffCanvas ? 'active' : ''
        }`}
        id="sidebar"
      >
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <AppAvatar {...avatarProps} />
        </div>
        <ul className="nav nav-scrolling">
          {sidebarFilteredData.map((item, index) => (
            <li className="nav-item" key={item.key || item.subNav.key} id={`nav-item-${index}`}>
              <Link to={item.path}>
                {item.subNav ? (
                  <div
                    className={`nav-link ${getActiveNav(item.path)}`}
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index + 1}`}
                    aria-expanded="false"
                    aria-controls={`collapse${index + 1}`}
                  >
                    <span className="icon-wrapper">{item.icon}</span>
                    <span className="menu-title">{t(`sidebar.${item.title}`)}</span>
                    <span className="sidemenu-down-arrow">
                      <ChevronDown />
                    </span>
                  </div>
                ) : (
                  <div className={`nav-link ${getActiveNav(item.path)}`}>
                    <span className="icon-wrapper">{item.icon}</span>
                    <span className="menu-title">{t(`sidebar.${item.title}`)}</span>
                  </div>
                )}
              </Link>

              {item.subNav && (
                <div
                  className={`collapse js-ui-components ${getActiveShow(item.path)}`}
                  id={`collapse${index + 1}`}
                  aria-labelledby={`heading${index + 1}`}
                  data-bs-parent="#sidebar"
                >
                  <ul className="nav flex-column sub-menu custom-scrollbar">
                    {item.subNav.map((subItem) => (
                      <li className="nav-item" key={subItem.key}>
                        {subItem.heading ? (
                          <span className="nav-link nav-link-subtitle">{subItem.heading}</span>
                        ) : (
                          <Link to={subItem.path}>
                            <div
                              role="button"
                              tabIndex="0"
                              onClick={() => handleSubMenu(item, index + 1)}
                              className={`nav-link ${
                                location.pathname.includes(subItem.path) ? 'active' : ''
                              }`}
                            >
                              {t(`sidebar.${subItem.title}`)}
                            </div>
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
