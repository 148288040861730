import axios from 'axios';
import size from 'lodash';

const capitalize = (word) => word && word[0].toUpperCase() + word.slice(1);

const fileUploadWithUploadPolicy = async (url, policyData, file, imageKey) => {
  try {
    const formData = new FormData();
    // Preparing policy data
    policyData.map((item) => {
      if (item.name === 'key') formData.append(item.name, item.value);
      else formData.append(capitalize(item.name), item.value);
      return item;
    });

    formData.append('file', file);

    const response = await axios.post(url, formData);

    if (response && (response.status === 204 || response.status === 200)) {
      return { success: true, imageKey };
    }
    return { error: true, success: false };
  } catch (error) {
    return { error: true, success: false };
  }
};
export default fileUploadWithUploadPolicy;
