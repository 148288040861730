/* eslint-disable no-unused-vars */
import React from 'react';
import { User } from 'react-feather';

const AppAvatar = ({
  width = 60,
  img = '',
  shadow,
  bordered,
  active,
  inactive,
  customClass,
  imgText,
  icon = <User />,
  bgColor = 'bg-primary'
}) => (
  <div className={`avatars-w-${width}`}>
    {img && (
      <img
        src={img}
        alt="Avatar"
        className={`rounded-circle ${shadow ? 'avatar-shadow' : ''} ${
          bordered ? 'avatar-bordered' : ''
        } ${customClass}`}
      />
    )}
    {!img && (
      <div
        className={`no-img align-items-center text-center rounded-circle ${customClass} ${bgColor}`}
      >
        {imgText || icon}
      </div>
    )}
    {active && <span className={`${active ? 'bg-success' : ''} status ${customClass}`} />}
    {inactive && <span className={`${inactive ? 'bg-secondary' : ''} status ${customClass}`} />}
  </div>
);

export default AppAvatar;
