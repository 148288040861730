import React from 'react';
import { ActionIcon } from '../customSvg';

const AppFilterButton = ({
  iconRight,
  title,
  iconLeft,
  btnClick,
  customClass = '',
  type = 'filter',
  filterActive = '',
  icon,
  btnVariant,
  actionIcon = <ActionIcon />
}) => (
  // buttonList = ['filter', 'filter-rounded', 'filter-action', 'filter-no-button', 'filter-status'];
  <div
    className="btn-filter "
    role="button"
    tabIndex="0"
    onClick={btnClick}
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {type === 'filter' && (
      <div className={`filter filter-btn-default ${filterActive} ${customClass}`}>
        {iconLeft && <span className="btn-icon-right me-2">{iconLeft}</span>}
        <span className="btn-title">{title || 'Demo Title'}</span>
        {iconRight && <span className="btn-icon-right ms-2">{iconRight}</span>}
      </div>
    )}

    {type === 'filter-rounded' && (
      <div
        className={`filter filter-rounded ${filterActive} ${btnVariant ? 'btn-gray-light' : ''}`}
      >
        {iconLeft && <span className="btn-icon-right me-2">{iconLeft}</span>}
        <span className="btn-title">{title || 'Demo Title'}</span>
        {iconRight && <span className="btn-icon-right ms-2">{iconRight}</span>}
      </div>
    )}

    {type === 'filter-action' && (
      <div
        className={`filter filter-action d-flex align-items-center justify-content-center ${filterActive}`}
      >
        <span className="btn-logo">{actionIcon}</span>
      </div>
    )}

    {type === 'filter-no-button' && (
      <div className={`filter filter-no-button ${filterActive}`}>
        {iconLeft && <span className="btn-icon-right me-2">{iconLeft}</span>}
        <span className="btn-title">{title || 'Demo no Button'}</span>
        {iconRight && <span className="btn-icon-right ms-2">{iconRight}</span>}
      </div>
    )}

    {type === 'filter-status' && (
      <div className={`filter filter-status ${filterActive}`}>
        <span className="btn-icon-right me-2">{iconLeft}</span>
        <span className="btn-title">{title || 'Status'}</span>
        <span className="btn-icon-right ms-3">{iconRight}</span>
      </div>
    )}

    {type === 'filter-action-btn' && (
      <div className={`filter filter-action-btn ${customClass}`}>
        {iconLeft && <span className="btn-icon-right me-2">{iconLeft}</span>}
        <span className="btn-title">{title || 'Demo Title'}</span>
        {iconRight && <span className="btn-icon-right ms-2">{iconRight}</span>}
      </div>
    )}

    {type === 'filter-base-btn' && (
      <div className={`filter filter-base-btn ${customClass}`}>
        {iconLeft && <span className="btn-icon-right me-2">{iconLeft}</span>}
        <span className="btn-title">{title || 'Demo Title'}</span>
        {iconRight && <span className="btn-icon-right ms-2">{iconRight}</span>}
      </div>
    )}

    {type === 'filter-icon-only' && (
      <div className={`filter filter-icon-only ${customClass}`}>
        {icon && <span className="btn-icon">{icon}</span>}
      </div>
    )}
  </div>
);
export default AppFilterButton;
