const statusTypeVariantList = {
  // Disabled
  archived: 'disabled',
  unlisted: 'disabled',
  closed: 'disabled',
  created: 'disabled',
  new: 'disabled',
  waiting_for_creation: 'disabled',
  inactive: 'disabled',
  cancelled: 'disabled',
  canceled: 'disabled',
  score_not_calculated: 'disabled',
  // Success
  active: 'success',
  occupied: 'success',
  paid: 'success',
  balanced: 'success',
  completed: 'success',
  low_risk: 'success',

  refunded: 'success',
  partially_refunded: 'danger',
  // Warning
  vacant: 'warning',
  invited: 'warning',
  fees_due: 'warning',
  eviction_notice: 'warning',
  partially_credited: 'warning',
  waiting_for_payment: 'warning',
  due: 'warning',
  high_risk: 'warning',
  sending: 'warning',
  incomplete: 'warning',

  // Danger
  maintenance: 'danger',
  rejected: 'danger',
  overdue: 'danger',
  credited: 'danger',
  lost: 'danger',
  eviction_notice_due: 'danger',
  failed: 'danger',
  unspecified: 'danger',
  refunds: 'danger',
  very_high_risk: 'danger',
  deleted: 'danger',
  // Blue
  in_progress: 'blue',
  partially_paid: 'blue',
  partially_balanced: 'blue',
  // Teal
  fees_paid: 'teal',
  // Green
  upcoming: 'green',
  sent: 'green',
  approved: 'green',
  sent_to_bank: 'green',
  // Yellow
  estimated: 'yellow',
  person: 'yellow',
  soon_ending: 'yellow',
  interested: 'yellow',
  tenant: 'yellow',
  auto: 'yellow',
  non_rent: 'yellow',
  waiting_for_signature: 'yellow',
  pending_for_approval: 'yellow',
  pending: 'yellow',
  overpaid: 'yellow',
  medium_risk: 'yellow',
  over_paid: 'yellow',
  ready: 'yellow',
  queued: 'yellow',
  processing: 'yellow',
  bounced: 'yellow',
  // Brown
  not_interested: 'brown',
  defaulted: 'brown',
  // Purple
  organization: 'purple',
  listed: 'purple',
  manual: 'purple',
  rent: 'purple',
  landlord: 'purple',
  // info
  registered: 'info',
  // Primary
  very_low_risk: 'primary',
  // Violate
  unpaid: 'violate',
  opened: 'blue'
};

const getStatusTypeVariant = (type = 'closed') => {
  if (!type) return 'green';
  const formattedType = type?.replace(/\s+/g, '').toLowerCase();
  const selectedVariantFromList = statusTypeVariantList[formattedType];
  return selectedVariantFromList;
};

export default getStatusTypeVariant;
