import React from 'react';
import { CheckCircle, FilePlus, User } from 'react-feather';
import { LandLordKeyIcon } from '@/components/ui';
import Agent from '@/views/lease/components/Agent';
import { translateLanguage } from '@/core/utils';

const LeaseFinalProcess = ({ agent }) => (
  <div className="card card-with-shadow border-0 padding-30">
    <div className="row">
      <div className="col-md-8 col-xl-8 col-xxl-8 col-xxxl-9 padding-lr-40">
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="">
            <h6 className="montserrat-600 font-18 text-faded-black text-center margin-t-20">
              {translateLanguage('leases.assignment_signed')}
            </h6>
            <div className="d-flex justify-content-center margin-t-30">
              <div className="svg-icon-40 text-success">
                <CheckCircle />
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center stepProgress">
            <div className="stepProgress__container">
              <div className="stepProgress__bar" id="progress" style={{ width: `99%` }} />

              <div className="stepProgress__circle stepProgress__circle--active sv-icon-30">
                <FilePlus />
              </div>

              <div className="stepProgress__circle stepProgress__circle--active">
                <User />
              </div>

              <div className="stepProgress__circle svg-icon-25 stepProgress__circle--active">
                <LandLordKeyIcon />
              </div>

              <div className="stepProgress__text">
                <span className="montserrat-500 font-14 text-faded-black">
                  {translateLanguage('leases.signed_by_agent')}
                </span>
                <span className="montserrat-500 font-14 text-faded-black">
                  {translateLanguage('leases.signed_by_landlord')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Agent agent={agent} />
    </div>
  </div>
);

export default LeaseFinalProcess;
