import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Book,
  Calendar,
  Check,
  ChevronDown,
  ChevronUp,
  Clock,
  CheckCircle,
  FileText,
  Folder,
  Lock,
  MessageCircle,
  Plus,
  Tool,
  Users,
  PlusCircle,
  Slash,
  AlertTriangle
} from 'react-feather';
import { size } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { AppAvatar, AppBadge, AppButton, LandlordIcon, AppSpinner } from '@/components/ui';
import AllInvoicesModal from '@/views/lease/modals/AllInvoicesModal';
import PaymentHistoryModal from '@/views/lease/modals/PaymentHistoryModal';
import PayoutHistoryModal from '@/views/lease/modals/PayoutHistoryModal';
import AllFilesModal from '@/views/lease/modals/AllFilesModal';
import TenantDetailsModal from '@/views/lease/modals/TenantDetailsModal';
import NewIssueModal from '@/views/lease/modals/NewIssueModal';
import IssuesModal from '@/views/lease/modals/IssuesModal';
import LandlordDetailsModal from '@/views/lease/modals/LandlordDetailsModal';
import getLoggedUserType from '@/views/lease/helpers/leasesHelper';
import AppContext from '@/context/app-context/Context';
import LeaseDetailsContext from '@/context/LeaseDetailsContext';
import SecurityDetailsModal from '@/views/lease/modals/SecurityDetailsModal';
import useNewConversation from '@/views/inbox/hooks/useNewConversation';
import { GET_FILE_DOWNLOAD_URL } from '../graphql/getFileDownloadUrl.gql';
import { canAccessLeasePage, toastCall, bankAccount } from '@/core/utils';
import KroneInvert from '../../../components/ui/customSvg/kroneInvert';
import CalendarInvert from '../../../components/ui/customSvg/calendarInvert';
import LockInvert from '../../../components/ui/customSvg/LockInvert';

const LeaseOverview = ({ leaseInfo = {}, setBackButton, setSeeLeaseButton }) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const { createConversation } = useNewConversation();
  const createNewMessage = () => createConversation({ listingId: leaseInfo?.propertyId });
  const navigate = useNavigate();
  const { getConvertToCurrency } = useContext(LeaseDetailsContext);
  const { isLoading, userTypes } = appContext;

  const [previousLease, setPreviousLease] = useState(false);
  const [openModal, setOpenModal] = useState({
    payout: false,
    payment: false,
    files: false,
    invoice: false,
    landlordProfile: false,
    tenantProfile: false
  });
  const [laseDownloadUrl, setLaseDownloadUrl] = useState('');
  const [neededFileType, setNeededFileType] = useState();

  const [getFileDownloadUrl] = useLazyQuery(GET_FILE_DOWNLOAD_URL);
  const handleLeaseFileUrl = async (fileId) => {
    if (laseDownloadUrl) {
      window.open(laseDownloadUrl);
      return true;
    }

    const response = await getFileDownloadUrl({
      variables: {
        queryData: {
          fileId
        }
      }
    });

    if (size(response?.errors)) {
      toastCall('danger', t('errors.bad_request'));
      return false;
    }

    if (response?.data?.getFileDownloadUrl) {
      setLaseDownloadUrl(response?.data?.getFileDownloadUrl);
      window.open(response.data.getFileDownloadUrl);
      return true;
    }
    return true;
  };

  useEffect(() => {
    setBackButton(false);
    setSeeLeaseButton(false);
    if (!canAccessLeasePage(userTypes)) return navigate('/');
    return false;
  }, []);

  const getTextColor = () => {
    if (leaseInfo?.invoiceInfo?.invoiceStatus === 'paid') {
      return 'text-success';
    }
    if (
      ['overdue', 'eviction_notice', 'eviction_notice_due']?.includes(
        leaseInfo?.invoiceInfo?.invoiceStatus
      )
    ) {
      return 'text-danger';
    }
    if (leaseInfo?.invoiceInfo?.invoiceStatus === 'over_paid') {
      return 'text-blue';
    }
    return 'text-faded-black';
  };

  const getPayoutTextColor = () => {
    if (leaseInfo?.payoutInfo?.rentStatus === 'paid') {
      return 'text-success';
    }
    if (
      leaseInfo?.payoutInfo?.rentStatus === 'overdue' ||
      leaseInfo?.payoutInfo?.rentStatus === 'eviction_notice' ||
      leaseInfo?.payoutInfo?.rentStatus === 'eviction_notice_due'
    ) {
      return 'text-danger';
    }
    return 'text-faded-black';
  };

  const LoaderOrEmptyList = () => {
    if (isLoading) return <AppSpinner />;
    return t('label.nothing_found');
  };

  const handleLandLordTenantInvoiceModal = (context) => {
    if (context === 'landlord') {
      setNeededFileType(context);
      setOpenModal((prev) => ({ ...prev, invoice: true }));
    }
    if (context === 'tenant') {
      setNeededFileType(context);
      setOpenModal((prev) => ({ ...prev, invoice: true }));
    }
  };

  return (
    <>
      {!isLoading && size(leaseInfo) ? (
        <>
          <div className="row">
            <div className="col-9 col-md-8 col-xl-8 col-xxl-8 col-xxxl-9">
              <div className="padding-30 h-100 card card-with-shadow border-0">
                <div className="row">
                  <div className="col-5">
                    <div className="d-flex">
                      <h6 className="montserrat-700 font-18 text-faded-black">
                        {leaseInfo?.locationTitle}
                      </h6>

                      <div className="margin-l-40">
                        <AppBadge
                          customClass={`lease-${leaseInfo?.leaseStatus?.replace(
                            /_/g,
                            '-'
                          )}-badge montserrat-600  font-14`}
                          label={
                            leaseInfo?.leaseStatus
                              ? t(`leases.leases_status.${leaseInfo?.leaseStatus}`)
                              : ''
                          }
                          pill
                        />
                      </div>
                    </div>

                    <p className="margin-t-10 montserrat-500 font-14 text-gray">
                      {leaseInfo?.address}
                    </p>

                    <div className="margin-t-50">
                      <div className="d-flex align-items-center">
                        <div className="text-black">
                          <LandlordIcon />
                        </div>

                        <div className="margin-l-20">
                          <h6 className="montserrat-500 font-16 text-faded-black">
                            {leaseInfo?.partner}
                          </h6>
                        </div>
                      </div>

                      <div className="margin-t-15 d-flex align-items-center">
                        <div>
                          <Calendar />
                        </div>

                        <div className="margin-l-20">
                          <h6 className="montserrat-500 font-16 text-faded-black">
                            {leaseInfo?.date}
                          </h6>
                        </div>
                      </div>
                    </div>

                    {leaseInfo?.leaseStatus !== 'closed' && (
                      <div className="margin-t-50">
                        <div className="d-flex align-items-center">
                          {leaseInfo?.remaining?.year && (
                            <div className="d-flex align-items-center">
                              <h6 className="montserrat-600 font-16 text-faded-black">
                                {leaseInfo?.remaining?.year}
                              </h6>
                              <h6 className="montserrat-500 font-12 text-faded-black margin-l-5">
                                {t('leases.years')}
                              </h6>
                            </div>
                          )}

                          {leaseInfo?.remaining?.month && (
                            <div className="d-flex align-items-center margin-l-10">
                              <h6 className="montserrat-600 font-16 text-faded-black">
                                {leaseInfo?.remaining?.month}
                              </h6>
                              <h6 className="montserrat-500 font-12 text-faded-black margin-l-5">
                                {t('leases.months')}
                              </h6>
                            </div>
                          )}

                          {leaseInfo?.remaining?.days && (
                            <div className="d-flex align-items-center margin-l-10">
                              <h6 className="montserrat-600 font-16 text-faded-black">
                                {leaseInfo?.remaining?.days}
                              </h6>
                              <h6 className="montserrat-500 font-12 text-faded-black margin-l-5">
                                {t('leases.days')}
                              </h6>
                            </div>
                          )}
                          {!leaseInfo?.remaining?.year &&
                          !leaseInfo?.remaining?.month &&
                          !leaseInfo?.remaining?.days ? (
                            <div className="d-flex align-items-center margin-l-10">
                              <h6 className="montserrat-600 font-16 text-faded-black">0</h6>
                              <h6 className="montserrat-500 font-12 text-faded-black margin-l-5">
                                {t('leases.days')}
                              </h6>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className="margin-tb-20">
                          <div className="progress new-lease-active-progress   height-5">
                            <div
                              className=""
                              style={{ width: `${leaseInfo?.remainingPercentage}%` }}
                            >
                              <div className="progress-bar bg-base height-5" role="progressbar" />
                            </div>
                          </div>
                        </div>

                        <h6 className="montserrat-500 font-10 text-gray text-end margin-l-5">
                          {t('leases.remaining')}
                        </h6>
                      </div>
                    )}
                  </div>

                  <div className="col-7 d-flex flex-column justify-content-between">
                    {leaseInfo?.rentStatus === 'payout' ? (
                      ''
                    ) : (
                      <div className="text-end">
                        <h6 className="montserrat-500 font-14 text-faded-black margin-t-15">
                          {leaseInfo?.rentStatus
                            ? t(`leases.leases_status.${leaseInfo?.rentStatus}`)
                            : ''}
                        </h6>
                        {leaseInfo?.rentStatus !== 'paid' && (
                          <h6 className="margin-t-10 montserrat-600 font-18 text-faded-black">
                            {getConvertToCurrency(leaseInfo?.amount)}
                          </h6>
                        )}
                      </div>
                    )}

                    <div className="d-flex ms-auto  ">
                      {leaseInfo?.leaseFileId !== null && (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => handleLeaseFileUrl(leaseInfo?.leaseFileId)}
                          className="lease-card-document  cursor-pointer "
                        >
                          <div>
                            <div className="text-center">
                              <FileText />
                            </div>

                            <h6 className="montserrat-500 font-14 text-sub-primary margin-t-10">
                              {t('leases.lease')}
                            </h6>
                          </div>
                        </div>
                      )}

                      <div
                        role="button"
                        tabIndex="0"
                        className="lease-card-document cursor-pointer margin-l-15 "
                        data-bs-toggle="modal"
                        data-bs-target={`#all-files${leaseInfo?.id}`}
                        onClick={() => setOpenModal((prevData) => ({ ...prevData, files: true }))}
                      >
                        {' '}
                        <div>
                          <div className="text-center">
                            <Folder />
                          </div>

                          <h6
                            className="montserrat-500 font-14 text-sub-primary margin-t-10"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {t('leases.all_files')}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3 col-md-4 col-xl-4 col-xxl-4 col-xxxl-3">
              <div className="padding-30 card card-with-shadow border-0">
                <div className="padding-tb-20 padding-lr-30 bg-base ">
                  <h6 className="montserrat-400 font-14 text-faded-black">{t('label.agent')}</h6>
                  <div className="d-flex align-items-center margin-t-20">
                    <div>
                      <AppAvatar img={leaseInfo?.agent?.img} width={60} />
                    </div>

                    <div className="margin-l-20">
                      <h6 className="montserrat-500 font-16 text-faded-black line-height-22 ">
                        {leaseInfo?.agent?.name}
                      </h6>

                      <h6 className="montserrat-500 font-12 text-faded-black">
                        {leaseInfo?.partner}
                      </h6>
                    </div>
                  </div>

                  <div className="margin-l-5">
                    <h6 className="montserrat-500 font-12 text-gray margin-t-20">
                      T. {leaseInfo?.agent?.phone}
                    </h6>

                    <h6 className="montserrat-500 font-12 text-gray margin-t-10">
                      E. {leaseInfo?.agent?.email}
                    </h6>
                  </div>
                </div>

                <AppButton
                  btnClass="btn btn-sub-primary margin-tb-20"
                  icon={<MessageCircle />}
                  label={t('label.send_message')}
                  handleClick={createNewMessage}
                  block
                />

                {getLoggedUserType(userTypes) === 'landlord' ? (
                  <div
                    className=""
                    data-bs-toggle="modal"
                    data-bs-target={`#tenant-details-${leaseInfo?.id}`}
                  >
                    <AppButton
                      handleClick={() => setOpenModal({ ...openModal, tenantProfile: true })}
                      btnClass="btn btn-light"
                      icon={<Users />}
                      label={t('leases.tenant_details')}
                      block
                    />
                  </div>
                ) : (
                  <div
                    className=""
                    data-bs-toggle="modal"
                    data-bs-target={`#landlord-details-${leaseInfo?.id}`}
                  >
                    <AppButton
                      btnClass="btn btn-light"
                      icon={<Users />}
                      label={t('leases.your_details')}
                      handleClick={() => setOpenModal({ ...openModal, landlordProfile: true })}
                      block
                    />
                  </div>
                )}

                <LandlordDetailsModal
                  contractId={leaseInfo?.id}
                  openModal={openModal.landlordProfile}
                />
                <TenantDetailsModal
                  contractId={leaseInfo?.id}
                  openModal={openModal.tenantProfile}
                />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-9 col-md-8 col-xl-8 col-xxl-8 col-xxxl-9">
              {/* Start Tenant view */}
              {/* {leaseInfo.rentStatus === 'to_pay' && ( */}
              {/*  <div className="finance h-100 padding-30 card card-with-shadow border-0"> */}
              {/*    <h5 className="montserrat-600 font-18 text-faded-black">Finance</h5> */}

              {/*    <div className="row gy-3"> */}
              {/*      <div className="col-7 col-md-8 col-xl-8 col-xxl-8 col-xxxl-7"> */}
              {/*        <div className="row"> */}
              {/*          /!* Rent *!/ */}
              {/*          <div className="col-md-7 col-xl-7 xol-xxl-7 col-xxxl-6 rent"> */}
              {/*            <h6 className="montserrat-500 font-16 text-faded-black margin-t-30"> */}
              {/*              Rent */}
              {/*            </h6> */}

              {/*            <div className="margin-t-20 bg-base padding-20"> */}
              {/*              <div className="d-flex flex-wrap  align-items-center"> */}
              {/*                <div className="d-flex align-items-center"> */}
              {/*                  <div className="svg-icon-20"> */}
              {/*                    <KroneIcon /> */}
              {/*                  </div> */}

              {/*                  <h6 className="montserrat-500 font-14 text-faded-black margin-l-10"> */}
              {/*                    14 000 */}
              {/*                  </h6> */}
              {/*                </div> */}

              {/*                <div className="d-flex align-items-center ms-5 ms-xxl-1 ms-xl-0 ms-xxxl-5"> */}
              {/*                  <div className="svg-icon-18"> */}
              {/*                    <Calendar /> */}
              {/*                  </div> */}

              {/*                  <h6 className="montserrat-500 font-14 text-faded-black margin-l-10"> */}
              {/*                    1. each month */}
              {/*                  </h6> */}
              {/*                </div> */}
              {/*              </div> */}

              {/*              <div className="margin-t-20"> */}
              {/*                <div className="row g-0"> */}
              {/*                  <div className="col-4"> */}
              {/*                    <h6 className="montserrat-500 font-12 text-faded-black "> */}
              {/*                      Account */}
              {/*                    </h6> */}
              {/*                  </div> */}

              {/*                  <div className="col-6"> */}
              {/*                    <h6 className="montserrat-500 font-12 text-faded-black "> */}
              {/*                      9876 54 2354 */}
              {/*                    </h6> */}
              {/*                  </div> */}
              {/*                </div> */}

              {/*                <div className="row g-0 margin-t-10"> */}
              {/*                  <div className="col-4"> */}
              {/*                    <h6 className="montserrat-500 font-12 text-faded-black "> */}
              {/*                      Kid */}
              {/*                    </h6> */}
              {/*                  </div> */}

              {/*                  <div className="col-6"> */}
              {/*                    <h6 className="montserrat-500 font-12 text-faded-black "> */}
              {/*                      121236488 */}
              {/*                    </h6> */}
              {/*                  </div> */}
              {/*                </div> */}
              {/*              </div> */}
              {/*            </div> */}
              {/*          </div> */}
              {/*          /!* Security *!/ */}

              {/*          <div className="col-md-5 col-xl-5 col-xxl-5 col-xxxl-5 security"> */}
              {/*            <h6 className="montserrat-500 font-16 text-faded-black margin-t-30"> */}
              {/*              Security */}
              {/*            </h6> */}

              {/*            <div className="margin-t-20 bg-base padding-20"> */}
              {/*              <div className="d-flex align-items-center"> */}
              {/*                <div className="svg-icon-20  "> */}
              {/*                  <Lock /> */}
              {/*                </div> */}
              {/*                <h6 className="montserrat-500 font-14 text-faded-black margin-l-10"> */}
              {/*                  Deposit */}
              {/*                </h6> */}
              {/*              </div> */}

              {/*              <div className="margin-t-20"> */}
              {/*                <div className="row g-0"> */}
              {/*                  <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-4"> */}
              {/*                    <h6 className="montserrat-500 font-12 text-faded-black "> */}
              {/*                      Amount */}
              {/*                    </h6> */}
              {/*                  </div> */}

              {/*                  <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6"> */}
              {/*                    <h6 className="montserrat-500 font-12 text-faded-black "> */}
              {/*                      {leaseInfo.amount} */}
              {/*                    </h6> */}
              {/*                  </div> */}
              {/*                </div> */}

              {/*                <div className="row g-0 margin-t-10"> */}
              {/*                  <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-4"> */}
              {/*                    <h6 className="montserrat-500 font-12 text-faded-black "> */}
              {/*                      Status */}
              {/*                    </h6> */}
              {/*                  </div> */}

              {/*                  <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6"> */}
              {/*                    <h6 className="montserrat-500 font-12 text-faded-black "> */}
              {/*                      Paid */}
              {/*                    </h6> */}
              {/*                  </div> */}
              {/*                </div> */}
              {/*              </div> */}
              {/*            </div> */}
              {/*          </div> */}

              {/*          <div className="col-12"> */}
              {/*            <div className="d-flex align-items-center margin-t-30"> */}
              {/*              <div */}
              {/*                className="d-flex align-items-center btn border-sub-primary-1  bg-white " */}
              {/*                data-bs-toggle="modal" */}
              {/*                data-bs-target="#payment-history" */}
              {/*              > */}
              {/*                <div> */}
              {/*                  <Clock /> */}
              {/*                </div> */}

              {/*                <h6 className="montserrat-400 font-14 text-sub-primary  margin-l-10 "> */}
              {/*                  Full payment history */}
              {/*                </h6> */}
              {/*              </div> */}

              {/*              <PaymentHistoryModal id="payment-history" /> */}

              {/*              <div */}
              {/*                className="d-flex align-items-center btn border-sub-primary-1  bg-white margin-l-20" */}
              {/*                data-bs-toggle="modal" */}
              {/*                data-bs-target="#all-invoice" */}
              {/*              > */}
              {/*                <div> */}
              {/*                  <Folder /> */}
              {/*                </div> */}

              {/*                <h6 className="montserrat-400 font-14 text-sub-primary margin-l-10 "> */}
              {/*                  All invoices */}
              {/*                </h6> */}
              {/*              </div> */}

              {/*              <AllInvoicesModal id="all-invoice" data={leaseInfo.invoice} /> */}
              {/*            </div> */}
              {/*          </div> */}
              {/*        </div> */}
              {/*      </div> */}

              {/*      <div className="d-xxl-none d-xxxl-block  d-xl-none  col-xxl-0 col-xxxl-2" /> */}

              {/*      <div className="col-3 col-md-4 col-xl-4 col-xxl-4 col-xxxl-3"> */}
              {/*        <div className="padding-tb-10 padding-lr-20 invoice-status"> */}
              {/*          <h6 className="montserrat-400 font-14 text-gray">Invoice status</h6> */}

              {/*          <div className="margin-t-30 text-center"> */}
              {/*            <Mail /> */}
              {/*          </div> */}
              {/*          <div className="text-center "> */}
              {/*            <h6 className="montserrat-600 font-16 text-faded-black margin-t-15"> */}
              {/*              {leaseInfo.invoiceInfo.invoiceStatus} */}
              {/*            </h6> */}

              {/*            <h6 className="montserrat-700 font-18 text-faded-black margin-t-20"> */}
              {/*              {leaseInfo.invoiceInfo.amount} */}
              {/*            </h6> */}
              {/*          </div> */}

              {/*          <div className="d-flex justify-content-center margin-t-15"> */}
              {/*            <h6 className="montserrat-500 font-12 text-faded-black ">Due date</h6> */}
              {/*            <h6 className="montserrat-500 font-12 text-faded-black margin-l-15"> */}
              {/*              {leaseInfo.invoiceInfo.invoiceDueDate} */}
              {/*            </h6> */}
              {/*          </div> */}

              {/*          <div className="margin-t-30 d-flex justify-content-center"> */}
              {/*            <div className="d-flex align-items-center btn border-1 rounded-pill bg-white margin-b-5"> */}
              {/*              <div> */}
              {/*                <KroneIcon /> */}
              {/*              </div> */}

              {/*              <h6 className="montserrat-400 font-14 text-sub-primary "> */}
              {/*                Open invoice */}
              {/*              </h6> */}
              {/*            </div> */}
              {/*          </div> */}
              {/*        </div> */}
              {/*      </div> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* )} */}

              {leaseInfo?.tenantStatusEnum?.includes(leaseInfo?.rentStatus) && (
                <div className="finance h-100 padding-30 card card-with-shadow border-0">
                  <h5 className="montserrat-600 font-18 text-faded-black">{t('leases.finance')}</h5>

                  <div className="row gy-3">
                    <div className="col-7 col-md-8 col-xl-8 col-xxl-8 col-xxxl-7">
                      <div className="row">
                        {/* Rent */}

                        <div className="col-md-7 col-xl-7 xol-xxl-7 col-xxxl-6 rent">
                          <h6 className="montserrat-500 font-16 text-faded-black margin-t-30">
                            {t('leases.rent')}
                          </h6>

                          <div className="margin-t-20 bg-base padding-20 height-200 d-flex flex-column justify-content-between">
                            <div className="d-flex flex-wrap  align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <div className="svg-icon-18 text-dark">
                                  <KroneInvert />
                                </div>
                                <h6 className="montserrat-500 font-14 text-faded-black margin-l-10">
                                  {getConvertToCurrency(
                                    leaseInfo?.finance?.monthlyRentAmount,
                                    false
                                  )}
                                </h6>
                              </div>

                              <div className="d-flex align-items-center ms-5 ms-xxl-1 ms-xl-3 ms-xxxl-5">
                                <div className="svg-icon-18 text-dark">
                                  <CalendarInvert />
                                </div>
                                <div>
                                  <h6 className="montserrat-500 font-13 text-faded-black margin-l-10">
                                    {leaseInfo?.finance?.dueDate}. {t('leases.each_month')}
                                  </h6>
                                </div>
                              </div>
                            </div>

                            <div className="">
                              <div className="row g-0">
                                <div className="col-4">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {t('leases.account')}
                                  </h6>
                                </div>
                                <div className="col-8">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {leaseInfo?.finance?.invoiceAccountNumber}
                                  </h6>
                                </div>
                              </div>

                              <div className="row g-0 margin-t-10">
                                <div className="col-4">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {t('leases.kid')}
                                  </h6>
                                </div>

                                <div className="col-8">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {leaseInfo?.finance?.kidNumber}
                                  </h6>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex align-items-center ">
                              <div
                                role="button"
                                tabIndex="0"
                                onClick={() => setOpenModal({ ...openModal, payment: true })}
                              >
                                <div
                                  className="d-flex align-items-center padding-5 shadow cursor-pointer default-radius  bg-white "
                                  data-bs-toggle="modal"
                                  data-bs-target={`#payment-history-${leaseInfo?.id}`}
                                >
                                  <div className="svg-icon-18">
                                    <Clock />
                                  </div>

                                  <h6 className="montserrat-400 font-12 text-sub-primary  margin-l-10 ">
                                    {t('leases.full_payment_history')}
                                  </h6>
                                </div>
                              </div>

                              <PaymentHistoryModal
                                contractId={leaseInfo?.id}
                                propertyId={leaseInfo?.propertyId}
                                tenantId={leaseInfo?.agent?.tenantId}
                                openModal={openModal.payment}
                                userType={getLoggedUserType(userTypes)}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Security */}

                        {leaseInfo?.finance?.security !== '' && (
                          <div className="col-md-5 col-xl-5 col-xxl-5 col-xxxl-5 security">
                            <h6 className="montserrat-500 font-16 text-faded-black margin-t-30">
                              {t('leases.security')}
                            </h6>

                            <div className="margin-t-20 bg-base padding-20 height-200 d-flex flex-column justify-content-between">
                              <div className="d-flex align-items-center">
                                <div className="svg-icon-18  ">
                                  <LockInvert />
                                </div>
                                <h6 className="montserrat-500 font-13 text-faded-black margin-l-10">
                                  {leaseInfo?.finance?.security === 'deposit_insurance'
                                    ? t('leases.leases_status.deposit_guarantee')
                                    : t('leases.leases_status.deposit_account')}
                                </h6>
                              </div>

                              <div className="">
                                <div className="row g-0">
                                  <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-4">
                                    <h6 className="montserrat-500 font-12 text-faded-black ">
                                      {t('label.amount')}
                                    </h6>
                                  </div>

                                  <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6">
                                    <h6 className="montserrat-500 font-12 text-faded-black ">
                                      {getConvertToCurrency(
                                        leaseInfo?.finance?.securityAmount,
                                        false
                                      )}
                                      {leaseInfo?.securityDetails?.isPaid ? (
                                        <span className="ms-2">
                                          <Check color="#219B6F" size={16} />
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </h6>
                                  </div>
                                </div>

                                <div className="row g-0 margin-t-10">
                                  <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-4">
                                    <h6 className="montserrat-500 font-12 text-faded-black ">
                                      {t('user_profile.status')}
                                    </h6>
                                  </div>

                                  <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6">
                                    <h6 className="montserrat-500 font-12 text-faded-black ">
                                      {leaseInfo?.securityDetails?.status
                                        ? t(
                                            `leases.leases_status.${leaseInfo?.securityDetails?.status}`
                                          )
                                        : ''}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center padding-5 shadow default-radius bg-white  cursor-pointer"
                                data-bs-toggle="modal"
                                data-bs-target={`#security-details-${leaseInfo?.id}`}
                              >
                                <div className="svg-icon-18">
                                  <Book />
                                </div>

                                <h6 className="montserrat-400 font-12 text-sub-primary margin-l-5 ">
                                  {t('leases.more_details')}
                                </h6>
                              </div>

                              <SecurityDetailsModal
                                id={`security-details-${leaseInfo?.id}`}
                                leaseInfo={leaseInfo}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-xxl-none d-xxxl-block  d-xl-none  col-xxl-0 col-xxxl-2" />

                    <div className="col-3 col-md-4 col-xl-4 col-xxl-4 col-xxxl-3 ">
                      <div
                        className={`padding-tb-10 h-100 padding-lr-20 invoice-status d-flex flex-column justify-content-between lease-${
                          leaseInfo?.rentStatus === 'over_paid' ||
                          leaseInfo?.rentStatus === 'outstanding'
                            ? 'overpaid'
                            : leaseInfo?.rentStatus?.replace(/_/g, '-')
                        }`}
                      >
                        <h6 className="montserrat-400 font-14 text-gray">
                          {t('leases.invoice_status')}
                        </h6>

                        <div className="margin-t-30 text-center">
                          {leaseInfo?.invoiceInfo?.invoiceStatus === 'overdue' && (
                            <Clock className="text-danger" />
                          )}
                          {leaseInfo?.invoiceInfo?.invoiceStatus === 'paid' && (
                            <CheckCircle className="text-success" />
                          )}
                          {leaseInfo?.invoiceInfo?.invoiceStatus === 'to_pay' && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                              <polyline points="22,6 12,13 2,6" />
                            </svg>
                          )}
                          {leaseInfo?.invoiceInfo?.invoiceStatus === 'over_paid' && (
                            <PlusCircle className="text-blue" />
                          )}
                          {leaseInfo?.invoiceInfo?.invoiceStatus === 'eviction_notice_due' && (
                            <Slash className="text-danger" />
                          )}
                          {leaseInfo?.invoiceInfo?.invoiceStatus === 'eviction_notice' && (
                            <AlertTriangle className="text-danger" />
                          )}
                        </div>
                        <div className="text-center ">
                          <h6 className="montserrat-600 font-16 text-faded-black margin-t-15">
                            {leaseInfo?.invoiceInfo?.invoiceStatus
                              ? t(`leases.leases_status.${leaseInfo?.invoiceInfo?.invoiceStatus}`)
                              : ''}
                          </h6>

                          <h6 className={`montserrat-700 font-18  margin-t-30 ${getTextColor()}`}>
                            {leaseInfo?.invoiceInfo?.invoiceStatus === 'paid'
                              ? `${t('leases.leases_status.all_good')}!`
                              : getConvertToCurrency(leaseInfo?.invoiceInfo?.amount)}
                          </h6>
                        </div>

                        <div className="d-flex justify-content-center margin-t-20">
                          {leaseInfo?.invoiceInfo?.invoiceDate && (
                            <>
                              <h6 className="montserrat-500 font-12 text-faded-black">
                                {leaseInfo?.invoiceInfo?.invoiceLabel
                                  ? t(
                                      `leases.leases_status.${leaseInfo?.invoiceInfo?.invoiceLabel}`
                                    )
                                  : ''}
                              </h6>
                              <h6 className="montserrat-500 font-12 text-faded-black margin-l-15">
                                {leaseInfo?.invoiceInfo?.invoiceDate}
                              </h6>
                            </>
                          )}
                        </div>
                        {(() => {
                          if (
                            (leaseInfo?.evictionNoticeFileId || leaseInfo?.evictionLetterFileId) &&
                            leaseInfo?.invoiceInfo?.invoiceOpenLabel
                          ) {
                            return (
                              <div className="d-flex align-items-center justify-content-center">
                                <div
                                  className="cursor-pointer d-flex align-items-center justify-content-center py-1 px-2 shadow default-radius bg-white margin-t-30 margin-b-10"
                                  role="button"
                                  tabIndex={0}
                                  onClick={() =>
                                    handleLeaseFileUrl(
                                      leaseInfo?.evictionNoticeFileId ||
                                        leaseInfo?.evictionLetterFileId
                                    )
                                  }
                                >
                                  <div className="svg-icon-20">
                                    <Folder />
                                  </div>

                                  <h6 className="montserrat-500 font-14 text-sub-primary margin-l-10">
                                    {t(
                                      `leases.invoice_open_label.${leaseInfo?.invoiceInfo?.invoiceOpenLabel}`
                                    )}
                                  </h6>
                                </div>
                              </div>
                            );
                          }
                          if (leaseInfo?.invoiceInfo?.invoiceOpenLabel) {
                            return (
                              <div className="d-flex align-items-center justify-content-center">
                                <div
                                  className="cursor-pointer d-flex align-items-center justify-content-center py-1 px-2 shadow default-radius bg-white margin-t-30 margin-b-10"
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => setOpenModal({ ...openModal, invoice: true })}
                                  data-bs-toggle="modal"
                                  data-bs-target={`#all-lease-invoice-${leaseInfo?.id}`}
                                >
                                  <div className="svg-icon-20">
                                    <Folder />
                                  </div>

                                  <h6 className="montserrat-500 font-14 text-sub-primary margin-l-10">
                                    {t(
                                      `leases.invoice_open_label.${leaseInfo?.invoiceInfo?.invoiceOpenLabel}`
                                    )}
                                  </h6>
                                </div>
                              </div>
                            );
                          }
                          return false;
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* End Tenant view */}

              {/* Start Landlord view */}
              {leaseInfo?.rentStatus === 'payout' && (
                <div className="finance padding-30 card card-with-shadow border-0">
                  <div className="row">
                    <div className=" col-md-8 col-xl-8 col-xxl-8 col-xxxl-7">
                      <div className="row">
                        {/* Rent */}
                        <div className="col-6 rent">
                          <h6 className="montserrat-500 font-16 text-faded-black ">
                            {t('leases.details')}
                          </h6>

                          <div className="margin-t-20 bg-base padding-20 height-175">
                            <div className="">
                              <div className="row g-xxxl-0 g-xl-2">
                                <div className="col-5">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {t('leases.rent')}
                                  </h6>
                                </div>

                                <div className="col-7">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {getConvertToCurrency(leaseInfo?.payoutInfo?.monthlyRentAmount)}
                                  </h6>
                                </div>
                              </div>

                              <div className="row g-xxxl-0 g-xl-2 margin-t-10">
                                <div className="col-5">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {t('leases.due')}
                                  </h6>
                                </div>

                                <div className="col-7">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {`${leaseInfo?.payoutInfo?.dueDate}. ${t('leases.each_month')}`}
                                  </h6>
                                </div>
                              </div>

                              <div className="row g-xxxl-0 g-xl-2 margin-t-20">
                                <div className="col-5">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {t('leases.security')}
                                  </h6>
                                </div>

                                <div className="col-7 d-flex align-items-center">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {leaseInfo?.payoutInfo?.security
                                      ? t(`leases.leases_status.${leaseInfo?.payoutInfo?.security}`)
                                      : ''}
                                  </h6>
                                  {(() => {
                                    if (
                                      leaseInfo?.payoutInfo?.security &&
                                      leaseInfo?.securityDetails?.isPaid
                                    ) {
                                      return (
                                        <span className="ms-2">
                                          <Check color="#219B6F" size={16} />
                                        </span>
                                      );
                                    }
                                    return false;
                                  })()}
                                </div>
                              </div>

                              <div className="row g-xxxl-0 g-xl-2 margin-t-10 align-items-center">
                                <div className="col-5">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {t('label.amount')}
                                  </h6>
                                </div>

                                <div className="col-7 d-flex align-items-center ">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {getConvertToCurrency(
                                      leaseInfo?.payoutInfo?.securityAmount,
                                      true
                                    )}
                                  </h6>
                                  {leaseInfo?.securityDetails?.isPaid ? (
                                    <span className="ms-2">
                                      <Check color="#219B6F" size={16} />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Payout */}

                        <div className="col-6 security">
                          <h6 className="montserrat-500 font-16 text-faded-black ">
                            {t('leases.leases_status.payout')}
                          </h6>

                          <div className="margin-t-20 bg-base padding-20 height-175">
                            <div className="h-100">
                              <div className="row align-items-center g-0">
                                <div className="col-5 col-md-3">
                                  <div className="svg-icon-15">
                                    <KroneInvert />
                                  </div>
                                </div>

                                <div className="col-7 col-md-9">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {getConvertToCurrency(
                                      leaseInfo?.payoutInfo?.payoutAmount,
                                      false
                                    )}
                                  </h6>
                                </div>
                              </div>

                              <div className="row align-items-center  g-0 margin-t-10 ms-xl-0 ms-xxl-0 ">
                                <div className="col-5 col-md-3">
                                  <div className="svg-icon-15">
                                    <Calendar />
                                  </div>
                                </div>

                                <div className="col-7 col-md-9">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {`${leaseInfo?.payoutInfo?.monthlyPayoutDate}. ${t(
                                      'leases.each_month'
                                    )}`}
                                  </h6>
                                </div>
                              </div>

                              <div className="row align-items-center  g-xxxl-0 g-xl-2 margin-t-20 margin-b-15">
                                <div className="col-5 col-md-5 col-lg-3">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {t('leases.account')}
                                  </h6>
                                </div>

                                <div className="col-7 col-md-7 col-lg-9">
                                  <h6 className="montserrat-500 font-12 text-faded-black ">
                                    {bankAccount(leaseInfo?.payoutInfo?.payoutTo)}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          {/* Assignment info */}
                          <div className="d-flex align-items-center margin-t-30">
                            <div
                              className="d-inline-flex"
                              role="button"
                              tabIndex="0"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseExample2"
                              aria-expanded="false"
                              aria-controls="collapseExample2"
                              onClick={() => setPreviousLease(!previousLease)}
                            >
                              <h6 className="montserrat-500 font-16 text-faded-black">
                                {t('leases.assignment_info')}
                              </h6>
                              <div className="margin-l-5">
                                {previousLease ? <ChevronUp /> : <ChevronDown />}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          {/* Payout history modal */}
                          <div
                            role="button"
                            tabIndex={0}
                            onClick={() => setOpenModal({ ...openModal, payout: true })}
                          >
                            <div
                              className="d-flex align-items-center btn border-sub-primary-1 margin-l-100  bg-white margin-t-20 "
                              data-bs-toggle="modal"
                              data-bs-target={`#payout-history-${leaseInfo?.id}`}
                            >
                              <div>
                                <Clock />
                              </div>

                              <h6 className="montserrat-400 font-14 text-sub-primary  margin-l-5 ">
                                {t('leases.full_payout_history')}
                              </h6>
                            </div>
                          </div>

                          <PayoutHistoryModal
                            contractId={leaseInfo?.id}
                            propertyId={leaseInfo?.propertyId}
                            openModal={openModal.payout}
                          />
                        </div>

                        <div
                          className="col-md-12 col-xl-12 col-xxl-12 col-xxxl-11 collapse"
                          id="collapseExample2"
                        >
                          <div className="margin-t-20 bg-base padding-20">
                            <div className="row">
                              <div className="col-6">
                                <div className="row gx-0">
                                  <div className="col-4">
                                    <h6 className="montserrat-500 font-12 text-faded-black ">
                                      {t('leases.period')}
                                    </h6>
                                  </div>

                                  <div className="col-8">
                                    <h6 className="montserrat-500 font-12 text-faded-black ">
                                      {leaseInfo?.assignmentInfo?.period}
                                    </h6>
                                  </div>
                                </div>

                                <h6 className="montserrat-500 font-12 text-gray margin-t-20">
                                  {t('leases.commission')}
                                </h6>

                                <div className="row gx- margin-t-10">
                                  <div className="col-md-6 col-xl-6 col-xxl-6  col-xxxl-5">
                                    <h6 className="montserrat-500 font-12 text-faded-black ">
                                      {t('leases.brokering')}
                                    </h6>
                                  </div>

                                  <div className="col-md-6 col-xl-6 col-xxl-6  col-xxxl-7 text-center">
                                    <h6 className="montserrat-500 font-12 text-faded-black ">
                                      {leaseInfo?.assignmentInfo?.brokeringCommissionAmount}
                                    </h6>
                                  </div>
                                </div>

                                <div className="row g-0 margin-t-10">
                                  <div className="col-md-6 col-xl-6 col-xxl-6  col-xxxl-5">
                                    <h6 className="montserrat-500 font-12 text-faded-black ">
                                      {t('leases.management')}
                                    </h6>
                                  </div>

                                  <div className="col-md-6 col-xl-6 col-xxl-6  col-xxxl-7  text-center">
                                    <h6 className="montserrat-500 font-12 text-faded-black ">
                                      {leaseInfo?.assignmentInfo?.rentalManagementCommissionAmount}
                                    </h6>
                                  </div>
                                </div>
                              </div>

                              <div className="col-6">
                                <h6 className="montserrat-500 font-12 text-gray ">
                                  {t('leases.payout_guarantee')}
                                </h6>
                                <h6 className="montserrat-500 font-12 text-faded-black margin-t-5 ">
                                  {leaseInfo?.assignmentInfo?.guarantee}
                                </h6>
                                <h6 className="montserrat-500 font-12 text-gray  margin-t-25">
                                  {t('leases.addons')}
                                </h6>
                                <h6 className="montserrat-500 font-12 text-gray margin-t-10">
                                  {leaseInfo?.assignmentInfo?.addons}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-xxl-none d-xxxl-block d-xl-none col-xxxl-2" />

                    <div className="col-3 col-md-4 col-xl-4 col-xxl-4 col-xxxl-3">
                      <div
                        className={`d-flex flex-column justify-content-between padding-tb-10 padding-lr-20 height-320 invoice-status-${
                          leaseInfo?.payoutInfo?.rentStatus === 'over_paid' ||
                          leaseInfo?.payoutInfo?.rentStatus === 'outstanding'
                            ? 'overpaid'
                            : leaseInfo?.payoutInfo?.rentStatus.replace(/_/g, '-')
                        } `}
                      >
                        <h6 className="montserrat-400 font-14 text-gray">
                          {t('leases.next_payout')}
                        </h6>

                        <div>
                          <h6
                            className={`montserrat-700 font-18 text-center ${
                              leaseInfo?.invoicePaidAfterPayoutDate
                                ? 'text-danger'
                                : 'text-faded-black'
                            }`}
                          >
                            {getConvertToCurrency(leaseInfo?.payoutInfo?.nextPayoutAmount)}
                          </h6>

                          <h6 className="montserrat-500 font-12 text-faded-black text-center margin-t-10">
                            {leaseInfo?.invoicePaidAfterPayoutDate
                              ? t('leases.leases_status.when_rent_is_received')
                              : leaseInfo?.payoutInfo?.nextPayoutDate}
                          </h6>
                          <div className="d-flex align-items-center justify-content-center">
                            <div
                              className="cursor-pointer d-flex align-items-center justify-content-center py-1 px-3 default-radius bg-white margin-t-10"
                              role="button"
                              tabIndex={0}
                              onClick={() => handleLandLordTenantInvoiceModal('landlord')}
                              data-bs-toggle="modal"
                              data-bs-target={`#all-lease-invoice-${leaseInfo?.id}`}
                            >
                              <div className="svg-icon-20">
                                <Folder />
                              </div>

                              <h6 className="montserrat-400 font-14 text-sub-primary margin-l-10">
                                {t(`leases.invoice_open_label.landlord_invoices`)}
                              </h6>
                            </div>
                          </div>
                        </div>

                        <h6 className="montserrat-400 font-14 text-gray margin-t-20">
                          {t('leases.rent_status')}
                        </h6>
                        <div>
                          <h6
                            className={`montserrat-600 font-16 text-center ${getPayoutTextColor()}`}
                          >
                            {(() => {
                              if (leaseInfo?.payoutInfo?.rentStatus === 'over_paid') {
                                return t('leases.leases_status.outstanding');
                              }
                              if (leaseInfo?.payoutInfo?.rentStatus) {
                                return t(
                                  `leases.leases_status.${leaseInfo?.payoutInfo?.rentStatus}`
                                );
                              }
                              return '';
                            })()}
                          </h6>

                          <h6 className="montserrat-500 font-12 text-center text-faded-black margin-t-10">
                            {leaseInfo?.payoutInfo?.rentStatus !== 'paid' &&
                              getConvertToCurrency(leaseInfo?.payoutInfo?.invoiceAmount)}
                          </h6>
                          <div className="d-flex align-items-center justify-content-center">
                            <div
                              className="cursor-pointer d-flex align-items-center justify-content-center py-1 px-3 default-radius bg-white margin-t-10"
                              role="button"
                              tabIndex={0}
                              onClick={() => handleLandLordTenantInvoiceModal('tenant')}
                              data-bs-toggle="modal"
                              data-bs-target={`#all-lease-invoice-${leaseInfo?.id}`}
                            >
                              <div className="svg-icon-20">
                                <Folder />
                              </div>

                              <h6 className="montserrat-400 font-14 text-sub-primary margin-l-10">
                                {t(`leases.invoice_open_label.tenant_invoices`)}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* EndLandlord view */}

              <AllInvoicesModal
                id={`all-lease-invoice-${leaseInfo?.id}`}
                contractId={leaseInfo?.id}
                invoiceQuery={{
                  propertyId: leaseInfo?.propertyId,
                  neededFileType
                }}
                openModal={openModal?.invoice}
                setOpenModal={setOpenModal}
                setNeededFileType={setNeededFileType}
              />
            </div>

            {/* {leaseInfo?.issues ? (
              <div className="col-3 col-md-4 col-xl-4 col-xxl-4 col-xxxl-3 h-100">
                <div className="finance h-100 padding-30 card card-with-shadow border-0">
                  <h6 className="montserrat-600 font-16 text-faded-black margin-b-30">
                    {t('leases.issues')}
                  </h6>

                  <div className="d-flex justify-content-between align-items-center lease-issues-item ">
                    <h6 className="montserrat-500 font-16 text-faded-black">
                      {t('list_your_place.type_of_property.other')}
                    </h6>
                    <h6 className="montserrat-600 font-16 text-faded-black">
                      {t('leases.leases_status.new')}
                    </h6>
                  </div>

                  <div
                    className="d-flex justify-content-between align-items-center lease-issues-item cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#all-issues"
                  >
                    <h6 className="montserrat-500 font-16 text-faded-black">
                      {t('label.bathroom')}
                    </h6>
                    <h6 className="montserrat-600 font-16 text-blue">{t('label.open')}</h6>
                  </div>

                  <IssuesModal id="all-issues" />

                  <div className="d-flex justify-content-between align-items-center lease-issues-item ">
                    <h6 className="montserrat-500 font-16 text-faded-black">
                      {t('label.kitchen')}
                    </h6>
                    <h6 className="montserrat-500 font-16 text-gray">
                      {t('leases.leases_status.closed')}
                    </h6>
                  </div>

                  <div
                    className="d-flex align-items-center justify-content-center margin-t-50 margin-b-10"
                    data-bs-toggle="modal"
                    data-bs-target="#new-issue"
                  >
                    <div className="text-sub-primary svg-icon-18">
                      <Plus />
                    </div>
                    <h6 className="montserrat-700 font-14 text-sub-primary cursor-pointer">
                      {t('leases.new_issue')}
                    </h6>
                  </div>
                  <NewIssueModal id="new-issue" />
                </div>
              </div>
            ) : (
              <div className="col-3 col-md-4 col-xl-4 col-xxl-4 col-xxxl-3 ">
                <div className="finance h-100 padding-30 card card-with-shadow border-0">
                  <h6 className="montserrat-600 font-16 text-faded-black">{t('leases.issues')}</h6>

                  <div className="text-center text-muted margin-t-30">
                    <Tool />
                  </div>

                  <h6 className="montserrat-500 font-14 text-gray margin-t-25 text-center">
                    {t('leases.reported_issues_with_the_property_will_show_up_here')}
                  </h6>

                  <div
                    className="d-flex align-items-center justify-content-center margin-t-40 "
                    data-bs-toggle="modal"
                    data-bs-target="#new-issue"
                  >
                    <div className="text-sub-primary svg-icon-18">
                      <Plus />
                    </div>
                    <h6 className="montserrat-700 font-14 text-sub-primary cursor-pointer">
                      {t('leases.new_issue')}
                    </h6>
                  </div>
                  <NewIssueModal id="new-issue" />
                </div>
              </div>
            )} */}
          </div>
          <AllFilesModal
            id={`all-files${leaseInfo?.id}`}
            contractId={leaseInfo?.id}
            leaseInfo={leaseInfo}
            openModal={openModal?.files}
            setOpenModal={setOpenModal}
          />
        </>
      ) : (
        <div className="leaseCard padding-40 margin-t-30 card card-with-shadow border-0">
          <LoaderOrEmptyList />
        </div>
      )}
    </>
  );
};

export default LeaseOverview;
