import React from 'react';

const ULNewLogo = () => (
  <svg width="114" height="40" viewBox="0 0 114 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4459 34.4665C11.7841 34.4665 9.63061 32.3394 9.63061 29.7104V20.1982C9.63061 14.94 5.32359 10.686 0 10.686V29.7104C0 34.9685 4.30702 39.2226 9.63061 39.2226C14.9542 39.2226 19.1943 34.9024 19.2612 29.7104C19.1943 32.2866 17.0676 34.4665 14.4459 34.4665Z"
      fill="#2B8768"
    />
    <path
      d="M19.2607 10.686V29.7104C19.2607 34.9685 23.5678 39.2225 28.8914 39.2225V1.17377C23.5811 1.17377 19.2607 5.42783 19.2607 10.686Z"
      fill="#2B8768"
    />
    <path
      d="M32.8779 10.7652V1.23981H36.9709V10.7652C36.9709 13.6057 38.0945 15.0853 40.3684 15.0853C42.6423 15.0853 43.7659 13.6057 43.7659 10.7652V1.23981H47.8589V10.7652C47.8589 15.7855 45.1703 18.626 40.3684 18.626C35.5665 18.626 32.8779 15.7723 32.8779 10.7652Z"
      fill="#1C2E45"
    />
    <path
      d="M50.4805 1.23981H53.3161L61.462 6.76217V1.23981H65.555V18.3485H61.462V11.3597L54.5869 6.73574V18.3485H50.4938L50.4805 1.23981Z"
      fill="#1C2E45"
    />
    <path d="M68.3242 1.23981H72.4172V18.3485H68.3242V1.23981Z" fill="#1C2E45" />
    <path
      d="M78.4633 4.76725H73.7148V1.23981H87.4117V4.78046H82.6633V18.3485H78.4766L78.4633 4.76725Z"
      fill="#1C2E45"
    />
    <path
      d="M88.7217 1.23981H101.148V4.78046H92.8147V7.9644H100.559V11.3861H92.8147V14.8079H101.455V18.3485H88.7217V1.23981Z"
      fill="#1C2E45"
    />
    <path
      d="M102.633 5.62602C102.633 2.53455 104.626 0.5 107.97 0.5C111.314 0.5 113.307 2.54776 113.307 5.62602C113.307 8.70427 111.327 10.7917 107.97 10.7917C104.612 10.7917 102.633 8.67785 102.633 5.62602ZM112.304 5.62602C112.304 3.10264 110.712 1.41159 107.97 1.41159C105.228 1.41159 103.636 3.11585 103.636 5.62602C103.636 8.13618 105.241 9.88008 107.97 9.88008C110.698 9.88008 112.304 8.1626 112.304 5.62602ZM110.578 8.63821C109.294 8.63821 108.786 8.44004 108.398 7.64736L108.277 7.40955C108.17 7.19817 108.063 7.07927 107.863 7.07927H107.609V8.58537H105.856V2.78557H108.277C109.789 2.78557 110.484 3.77642 110.484 4.82012C110.484 5.66565 110.177 6.26016 109.642 6.56402C109.749 6.61687 109.802 6.6565 109.923 6.74898L109.976 6.78862C110.083 6.86789 110.404 6.97358 110.578 6.97358V8.63821ZM108.09 5.58638C108.478 5.58638 108.705 5.375 108.705 4.97866C108.705 4.55589 108.478 4.30488 108.104 4.30488H107.595V5.58638H108.09Z"
      fill="#1C2E45"
    />
    <path d="M33.0117 22.1138H37.1047V35.6819H44.622V39.2226H33.0117V22.1138Z" fill="#1C2E45" />
    <path d="M45.9463 22.1138H50.0393V39.2226H45.9463V22.1138Z" fill="#1C2E45" />
    <path
      d="M51.2158 22.1138H55.6165L58.3987 30.0142C59.0942 31.9827 59.5757 33.3831 59.9235 34.5721C60.3917 33.3171 60.8331 32.0355 61.542 29.9217L64.1369 22.1006H68.5375L61.542 39.2225H58.2649L51.2158 22.1138Z"
      fill="#1C2E45"
    />
    <path d="M69.7686 22.1138H73.8616V39.2226H69.7686V22.1138Z" fill="#1C2E45" />
    <path
      d="M76.5771 22.1138H79.4128L87.5587 27.6362V22.1138H91.6517V39.2226H87.5587V32.2337L80.6702 27.6098V39.2094H76.5771V22.1138Z"
      fill="#1C2E45"
    />
    <path
      d="M102.861 32.2602V29.3537H110.819V30.186C110.819 36.0386 107.77 39.5 102.459 39.5C96.8015 39.5 93.5244 35.9594 93.5244 30.6748C93.5244 25.3903 96.641 21.8496 102.58 21.8496C104.76 21.8496 107.195 22.5366 108.693 23.8842L107.021 26.7378C105.71 25.7866 104.559 25.377 102.807 25.377C99.4633 25.377 97.8047 27.5701 97.8047 30.6748C97.8047 33.7795 99.3563 35.9726 102.459 35.9726C104.733 35.9726 106.298 34.7703 106.606 32.2734L102.861 32.2602Z"
      fill="#1C2E45"
    />
  </svg>
);

export default ULNewLogo;
