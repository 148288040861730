import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DepositAmountCard from '@/views/lease/components/DepositAmountCard';
import RentAmountCard from '@/views/lease/components/RentAmountCard';
import Agent from '@/views/lease/components/Agent';
import LeaseDetailsContext from '@/context/LeaseDetailsContext';

const DepositPaidPayment = ({ deposit, firstInvoice, agent, fromLandlordPage, listingId }) => {
  const { getConvertToCurrency } = useContext(LeaseDetailsContext);
  const { t } = useTranslation();

  const paymentHeaderText = () => {
    if (
      (!firstInvoice?.status ||
        firstInvoice?.status === 'paid' ||
        firstInvoice?.status === 'overpaid') &&
      (!deposit?.securityStatus || deposit?.isPaid)
    ) {
      return 'payments_completed';
    }

    if (
      (firstInvoice?.status && firstInvoice?.status !== 'paid') ||
      (deposit?.securityStatus && !deposit?.isPaid)
    ) {
      return fromLandlordPage
        ? 'waiting_for_tenant_to_complete_the_payments'
        : 'please_complete_the_following_payments';
    }
    return '';
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-8 col-xl-8 col-xxl-8 col-xxxl-9 ">
          <div className="card card-with-shadow border-0 padding-30  h-100">
            <div className="  h-100">
              <h6 className="montserrat-600 font-18 text-faded-black text-center margin-t-20">
                {paymentHeaderText() ? t(`leases.${paymentHeaderText()}`) : ''}
              </h6>

              <div className="d-flex justify-content-center align-items-stretch margin-t-20">
                {deposit?.security !== '' && deposit?.securityAmount !== 0 && (
                  <DepositAmountCard
                    deposit={deposit}
                    getConvertToCurrency={getConvertToCurrency}
                  />
                )}
                {firstInvoice?.amount && firstInvoice?.status && (
                  <RentAmountCard
                    firstInvoice={firstInvoice}
                    getConvertToCurrency={getConvertToCurrency}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <Agent agent={agent} listingId={listingId} />
      </div>
    </div>
  );
};

export default DepositPaidPayment;
