import { toastCall, translateLanguage as t } from '@/core/utils';

const ErrorMessage = (errorCode) => {
  if (errorCode === 400) {
    toastCall('danger', t('errors.invalid_credentials'));
  }
  if (errorCode === 401) {
    toastCall('danger', t('errors.unauthorized'));
  }
  if (errorCode === 500) {
    toastCall('danger', t('errors.internal_server_error'));
  } else {
    toastCall('danger', t('errors.bad_request'), 'top-right');
  }
};

export default ErrorMessage;
