import Nouislider from 'nouislider-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'nouislider/distribute/nouislider.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { size } from 'lodash';
import AppContext from '@/context/app-context/Context';
import RangeHistogram from '@/components/ui/range/RangeHistogram';
// import { size } from 'lodash';

const AppRange2 = ({
  id,
  prepend,
  callback,
  histogram,
  histogramData,
  minimum = 3000,
  maximum = 17000,
  minRange = 1000,
  maxRange = 20000,
  step,
  filterBy = 'Filter name',
  clear = false
}) => {
  const appContext = useContext(AppContext);

  const sliderModelRaf = useRef('');

  const { isClear, setIsClear } = appContext;
  // state
  const [state, setState] = useState({
    minimum,
    maximum
  });

  const { t } = useTranslation();

  // change slider

  const onSlide = (value) => {
    setState((oldValue) => ({
      ...oldValue,
      minimum: Math.round(value[0]),
      maximum: Math.round(value[1])
    }));
  };

  // Apply value
  // const handleApply = () => {
  //   // eslint-disable-next-line no-unused-expressions
  //   callback && callback(state);
  // };

  // handle input value

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    // if (e.target.name === 'minimum') {
    //   sliderModelRaf.current.noUiSlider.set([e.target.value, state.maximum]);
    // }
    // if (e.target.name === 'maximum') {
    //   sliderModelRaf.current.noUiSlider.set([state.minimum, e.target.value]);
    // }
  };
  useEffect(() => {
    if (callback && size(state)) {
      callback(state);
    }
  }, [state]);

  useEffect(() => {
    if (isClear) {
      setState({
        minimum: minRange,
        maximum: maxRange
      });
      setIsClear(false);
    }
  }, [isClear]);

  useEffect(() => {
    if (size(localStorage.getItem('monthlyRent')) && id === 'monthly_rent') {
      setState(JSON.parse(localStorage.getItem('monthlyRent')));
    }
  }, [localStorage.getItem('monthlyRent')]);

  // reset component data
  useEffect(() => {
    if (clear) {
      setState({
        minimum: minRange,
        maximum: maxRange
      });
    }
  }, [clear]);

  return (
    <div className="filter-dropdown-range">
      <div className="row">
        <div className="col-12 filter-range-text">
          <h6 className="filter-range-text-title padding-t-20 padding-b-10">{filterBy} </h6>
          <p className="filter-range-text-subtitle padding-b-20">
            {t('common.slider_text1')}
            {t('common.slider_text2')}
          </p>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          {histogram && (
            <RangeHistogram
              data={histogramData}
              highlight={[state.minimum, state.maximum]}
              range={[minRange, maxRange]}
            />
          )}
          <Nouislider
            instanceRef={sliderModelRaf}
            behaviour="smooth-sanp"
            range={{ min: [minRange], max: [maxRange] }}
            start={[minimum, maximum]}
            onUpdate={onSlide}
            step={step}
            connect
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="rate-status mt-primary d-flex justify-content-center">
            <div className="rate-left-cols">
              <p className="text-center mb-2"> {t('common.minimum')}</p>
              <div className="rate-value model-rate-value d-flex align-items-center justify-content-evenly">
                <input
                  name="minimum"
                  type="number"
                  className=" model-rate-value-text-input"
                  value={state.minimum}
                  onChange={handleChange}
                />
                <span className=" model-rate-value-currency">{prepend}</span>
              </div>
            </div>
            <div className="rate-right-cols">
              <p className="text-center mb-2"> {t('common.maximum')}</p>
              <div className="rate-value model-rate-value d-flex align-items-center justify-content-evenly">
                <input
                  name="maximum"
                  type="number"
                  className="model-rate-value-text-input"
                  value={state.maximum}
                  onChange={handleChange}
                />
                {prepend ? <span className="model-rate-value-currency">{prepend}</span> : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppRange2;
