import axios from 'axios';
import { getApiBaseUrl } from '@/core/utils';

const BASE_API_URL = getApiBaseUrl();
const requestForLogin = (formData) =>
  axios({
    method: 'post',
    url: `${BASE_API_URL}/auth`,
    data: formData
  });

export const requestForRefreshToken = (existingTokens) =>
  axios({
    method: 'post',
    url: `${BASE_API_URL}/auth/refresh`,
    data: existingTokens
  });

export default requestForLogin;
