import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

const AppInput = (
  {
    maxLength,
    type = 'text',
    country = '',
    placeholder = '',
    id = '',
    onKeyDown,
    handleBlur,
    customClass = '',
    disabled,
    readOnly,
    onChange,
    callback,
    name,
    value,
    imageText,
    defaultStateMapLocation,
    setDefaultStateMapLocation,
    context,
    handleFocus,
    intType
  },
  ref
) => {
  const [state, setState] = useState(value || '');
  // phone number state
  const [reactTelInput, setReactTelInput] = useState(value || '');

  const handleChange = (e) => {
    if (imageText) {
      onChange(e.target, name);
      const validInput = e.target.value.replace(/[^a-z0-9-]/gi, '');
      const newId = id.split('-').includes('AddPartnersSubDomain') || false;
      if (newId) {
        setState(validInput);
      } else {
        setState(e.target.value);
      }
    }
    if (defaultStateMapLocation) {
      setDefaultStateMapLocation('');
      setState(e.target.value);
    } else {
      setState(e.target.value);
    }
  };

  const preventDecimalPoint = (e) => {
    if (e.key === '.' && intType) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (onChange && !imageText) {
      onChange(state, name);
    }
  }, [state]);

  useEffect(() => {
    if (value) {
      setState(value);
    }
  }, [value]);

  useEffect(() => {
    if (callback) {
      callback(reactTelInput);
    }
  }, [reactTelInput, callback]);

  return (
    <>
      {(() => {
        if (type === 'phoneNumber') {
          return (
            <PhoneInput
              country={country}
              value={reactTelInput.phone}
              onChange={(phone) => setReactTelInput({ phone })}
            />
          );
        }
        return (
          <input
            type={type}
            id={id}
            name={name}
            maxLength={maxLength}
            placeholder={placeholder}
            className={`form-control ${customClass}`}
            ref={ref}
            onChange={(e) => handleChange(e)}
            onKeyDown={onKeyDown}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={defaultStateMapLocation || state}
            disabled={disabled}
            readOnly={readOnly}
            onWheel={(e) => e.target.blur()}
            onKeyPress={preventDecimalPoint}
          />
        );
      })()}
    </>
  );
};

export default React.forwardRef(AppInput);
