import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Translations from '@/lang';

export const languages = Object.keys(Translations);
const locales = Object.assign(
  {},
  ...languages.map((index) => ({
    [index]: {
      translations: Translations[index]
    }
  }))
);

const detectionOptions = {
  // order and from where user language should be detected
  order: ['path', 'navigator', 'localStorage', 'cookie', 'htmlTag'],
  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'linaroResourcesHubLang',
  lookupLocalStorage: 'linaroResourcesHubLang',
  lookupSessionStorage: 'linaroResourcesHubLang',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // cookieDomain: 'cloudfront.net',
  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    resources: locales,
    ns: ['translations'],
    defaultNS: 'translations',
    supportedLngs: languages,
    returnObjects: true,
    debug: false,
    detection: detectionOptions,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
