import { times } from 'lodash';
import moment from 'moment';
import { Slide, toast } from 'react-toastify';

/* eslint-disable no-plusplus */
export const addClass = (element, className) => {
  element.classList.add(className);
};

export const removeClass = (element, className) => {
  element.classList.remove(className);
};

export const addRemoveClass = (element, addClassName, removeClassName) => {
  addClass(element, addClassName);
  removeClass(element, removeClassName);
};

// Checkbox check for datatable
export const handleDatatableCheckboxChanged = (event, item, state, setState) => {
  if (event.target.checked) {
    const copy = [...state, item];
    setState(copy);
  } else {
    const copy = [...state];
    const index = copy.indexOf(item);
    if (index > -1) {
      copy.splice(index, 1);
    }
    setState(copy);
  }
};

// Checkbox check all items for datatable
export const handleDatatableCheckboxCheckAll = (event, setState, checkboxData) => {
  const checkboxes = document.querySelectorAll('.datatable-checkbox-select');
  // Toggle all checkboxes
  for (let i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i] !== event.target) checkboxes[i].checked = event.target.checked;
  }
  // Select checkboxes value
  if (event.target.checked) {
    const copyItems = [];
    checkboxData.map((item) => copyItems.push(item.id));
    setState(copyItems);
  } else {
    const copyItems = [];
    setState(copyItems);
  }
};

// Data table Scroller
export const handleScroll = (className) => {
  const slider = document.querySelector(className);
  const preventClick = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
  };
  let isDown = false;
  let isDragged = false;
  let startX;
  let scrollLeft;

  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
    const elements = document.querySelectorAll('tr');
    if (isDragged) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', preventClick);
      }
    } else {
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('click', preventClick);
      }
    }
    slider.classList.remove('active');
    isDragged = false;
  });
  slider.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    isDragged = true;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2;
    slider.scrollLeft = scrollLeft - walk;
  });
};

// traverse node list items
// eslint-disable-next-line func-names
export const traverseNodeListItems = function (array, callback, scope) {
  for (let i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]);
  }
};

// remove dropdown show class
export const removeShowClass = (id) => {
  const getShowClassID = document.getElementById(id);
  getShowClassID.classList.remove('show');
};

// remove all show classes
// eslint-disable-next-line func-names
export const removeAllShowClass = function (array) {
  for (let i = 0; i < array.length; i++) {
    array[i].classList.remove('show');
  }
};

// first string uppercase
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// random id
export const uniqueId = () => `_${Math.random().toString(36).substr(2, 9)}`;

// toast call function
export function toastCall(type, toastBody, position) {
  if (type === 'info') {
    toast.info(toastBody, {
      position
    });
  } else if (type === 'success') {
    toast.success(toastBody, {
      position
    });
  } else if (type === 'warning') {
    toast.warn(toastBody, {
      position
    });
  } else if (type === 'danger') {
    toast.error(toastBody, {
      position
    });
  } else if (type === 'dark') {
    toast.dark(toastBody, {
      position
    });
  } else {
    toast(toastBody || 'Demo toast', {
      position
    });
  }
}

// toast config
export function toastConfig() {
  toast.configure({
    transition: Slide,
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true
  });
}

// copy to clipBoard
export const copyToClipBoard = async (copyMe) => {
  try {
    await navigator.clipboard.writeText(copyMe);
  } catch (err) {
    console.log(`Error: ${err}`);
  }
};

// this week, last week , this month, last month, this year, total

export const getThisDayHours = () =>
  Array.from({ length: 24 }, (_, i) => i).reduce((r, hour) => {
    r.push(moment({ hour, minute: 0 }).format('h A'));
    return r;
  }, []);

export function getCurrentWeek() {
  const currentDate = moment();
  const weekStart = currentDate.clone().startOf('week');

  const days = [];

  for (let i = 0; i <= 6; i += 1) {
    days.push(moment(weekStart).add(i, 'days').format('dd'));
  }
  return days;
}

export function getPrevWeek() {
  const currentDate = moment();
  const weekStart = currentDate.startOf('week').subtract(7, 'days');

  const days = [];

  for (let i = 0; i <= 6; i += 1) {
    days.push(moment(weekStart).add(i, 'days').format('dd'));
  }
  return days;
}

export function getCurrentMonth() {
  const daysInCurrentMonth = [];
  const curentMonthDates = moment().startOf('month');

  times(curentMonthDates.daysInMonth(), () => {
    daysInCurrentMonth.push(curentMonthDates.format('DD'));
    curentMonthDates.add(1, 'day');
  });

  return daysInCurrentMonth;
}

export function getPrevMonth() {
  const daysInPrevMonth = [];
  const prevMonthDates = moment().startOf('month').subtract(1, 'month');

  times(prevMonthDates.daysInMonth(), () => {
    daysInPrevMonth.push(prevMonthDates.format('DD'));
    prevMonthDates.add(1, 'day');
  });

  return daysInPrevMonth;
}

// Find all month
export const allMonthInYear = moment.monthsShort();
