/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppFilterButton } from '..';

const AppRadio = (props) => {
  const { t } = useTranslation();
  const {
    data,
    id,
    callBack,
    context,
    options: {
      customClass = '',
      filter = false,
      clearable,
      grid = 3,
      languageKey = '',
      btnProps = {}
    } = {}
  } = props;

  const radioRef = useRef(null);

  const [radio, setRadio] = useState();

  useEffect(() => {
    const checkActive = data?.find((item) => {
      if (item.checked) {
        return item;
      }
      return null;
    });
    setRadio(checkActive);
  }, []);

  // radio onchange value handler
  const handleChange = (e, item) => {
    data?.map((el) => {
      const elements = el;
      if (item.label === elements.label) {
        elements.checked = true;
      } else {
        elements.checked = false;
      }
      return elements;
    });

    setRadio(item);
    if (callBack) {
      callBack(item, context);
    }
  };

  // clear value
  const handleClear = () => {
    data?.map((el) => {
      const element = el;
      element.checked = false;
      return element;
    });

    setRadio('');
    callBack('clear', context);
  };

  return (
    <div className="appRadio">
      {!filter && (
        <div className={`d-flex flex-row flex-wrap align-items-center ${customClass}`}>
          {data &&
            data?.map((item) => (
              <div
                key={item.id}
                style={{ flex: `0 0 auto`, width: `calc(100% / ${grid}) ` }}
                className={`${item.disabled && 'disabled'}`}
              >
                <label
                  key={item.id}
                  htmlFor={`${id}-${item.id}`}
                  className={` form-check-label customized-radio radio-default ${
                    item.disabled ? 'disabled' : ''
                  }`}
                >
                  <input
                    disabled={item.disabled}
                    type="radio"
                    id={`${id}-${item.id}`}
                    name={`${id}Radio`}
                    checked={radio?.id === item?.id}
                    value={item.label}
                    onChange={(e) => handleChange(e, item)}
                  />
                  <span className="radio-circle" />
                  {languageKey === '' ? (
                    <span className="radio-label font-14 poppins-400 text-gray margin-l-5">
                      {item.label}
                    </span>
                  ) : (
                    <span className="radio-label font-14 poppins-400 text-gray margin-l-5">
                      {t(`${languageKey}.${item.label}`)}
                    </span>
                  )}
                </label>
              </div>
            ))}
        </div>
      )}

      {filter && (
        <div className="">
          <AppFilterButton {...btnProps} />
          <ul className="dropdown-menu p-0" aria-labelledby="dropdownMenuButton" ref={radioRef}>
            <div className="dropdown-wrapper">
              <div className="filter-dropdown-radio custom-scrollbar">
                <div className="row">
                  {radio && clearable && (
                    <div className="col-12 filter-radio-clear pb-2">
                      <span role="button" tabIndex="0" onClick={() => handleClear()}>
                        Clear filter
                      </span>
                    </div>
                  )}

                  <div className={`grid-${grid}`}>
                    {data?.map((item) => (
                      <div key={item.id} className="cursor-pointer">
                        <label
                          htmlFor={`${id}-${item.id}`}
                          className={`form-check-label customized-radio radio-default ${
                            item.isDisabled ? 'disabled' : ''
                          }`}
                        >
                          <input
                            disabled={item.disabled}
                            type="radio"
                            id={`${id}-${item.id}`}
                            name={`${id}Radio`}
                            checked={radio?.label === item.label}
                            value={item.label}
                            onChange={(e) => {
                              handleChange(e, item);
                            }}
                          />
                          <span className="radio-circle" />
                          <span className="poppins-400 text-gray text-capitalize margin-l-5">
                            {' '}
                            {t(`${languageKey}.${item.label}`)}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      )}
    </div>
  );
};

export default AppRadio;
