import arrayMove from 'array-move';
import React, { useEffect, useState } from 'react';
import { Menu } from 'react-feather';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { DataTableHeader } from '../../../data';

const SortableItem = SortableElement(({ value }) => (
  // const handleClick = () => {
  // };
  <div className="row py-2 bg-primary">
    <div className="col-12 d-flex justify-content-between">
      <div className="content-type">
        <Menu />
        {value.key}
      </div>
      <label htmlFor={`switch-${value.key}`} className="custom-control border-switch mb-0 mr-3">
        <input
          type="checkbox"
          id={`switch-${value.key}`}
          className="border-switch-control-input"
          style={{ display: 'none' }}
        />
        <span className="border-switch-control-indicator" />
      </label>
    </div>
  </div>
));

const SortableList = SortableContainer(({ items }) => (
  <div className="list-group" style={{ background: '#23262f' }}>
    {items.map((value, index) => (
      <SortableItem key={`item-${value.key}`} index={index} value={value} />
    ))}
  </div>
));

const AppDraggable = () => {
  const [items, setItems] = useState(DataTableHeader);

  useEffect(() => {
    // console.log(items);
  }, [items]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex));
  };
  return (
    <div>
      <SortableList items={items} onSortEnd={onSortEnd} />
    </div>
  );
};

export default AppDraggable;
