import React, { useContext, useEffect, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { size } from 'lodash';
import AppInput from '@/components/ui/input/AppInput';
import AppContext from '@/context/app-context/Context';
import getMapLocationForInput from '@/helpers/getMapLocationName';

const AppGoogleAddressInput = ({
  placeholderText,
  callback,
  value,
  setValue,
  id,
  onFocus,
  onBlur,
  addToListingQuery
}) => {
  const appContext = useContext(AppContext);
  const { mapSettings } = appContext;
  const { currentLocation } = mapSettings;

  const [defaultStateMapLocation, setDefaultStateMapLocation] = useState('');
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_BROWSER_KEY,
    onPlaceSelected: (place) => {
      if (!size(place.name)) {
        setDefaultStateMapLocation(place.formatted_address);
        setValue(place, id);
        callback(place);
      }
    }
  });

  useEffect(() => {
    if (size(currentLocation)) {
      const { city = '', country = '' } = currentLocation;
      if (city) {
        const location = `${city}, ${country}`;
        setDefaultStateMapLocation(location);
      }
    }
  }, [currentLocation]);

  return (
    <AppInput
      ref={ref}
      placeholder={placeholderText}
      defaultStateMapLocation={defaultStateMapLocation}
      setDefaultStateMapLocation={setDefaultStateMapLocation}
      value={value || defaultStateMapLocation}
      handleFocus={onFocus}
      handleBlur={onBlur}
    />
  );
};

export default AppGoogleAddressInput;
