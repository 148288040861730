import React from 'react';

const MaximizeIcon = () => (
  <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 9.375H15.625C13.9674 9.375 12.3777 10.0335 11.2056 11.2056C10.0335 12.3777 9.375 13.9674 9.375 15.625V25M65.625 25V15.625C65.625 13.9674 64.9665 12.3777 63.7944 11.2056C62.6223 10.0335 61.0326 9.375 59.375 9.375H50M50 65.625H59.375C61.0326 65.625 62.6223 64.9665 63.7944 63.7944C64.9665 62.6223 65.625 61.0326 65.625 59.375V50M9.375 50V59.375C9.375 61.0326 10.0335 62.6223 11.2056 63.7944C12.3777 64.9665 13.9674 65.625 15.625 65.625H25"
      stroke="currentColor"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MaximizeIcon;
