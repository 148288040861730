/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AppToggleButton = ({
  data,
  id,
  callBack,
  options: {
    customClass = '',
    variant = '',
    disabled,
    languageKey = '',
    passState = false,
    modalClear
  } = {}
}) => {
  const { t } = useTranslation();

  const activeItem = data?.find((element) => element.isActive);

  const [selectButton, setSelectButton] = useState(activeItem);

  const toggleButton = (item) => {
    if (callBack) {
      setSelectButton({ ...item, isActive: true });

      const newData = data?.map((el) => {
        if (el.id === item.id) {
          el.isActive = true;
        } else {
          el.isActive = false;
        }
        return el;
      });

      callBack({ selectedItem: item, updatedData: newData, id, data });
    }

    setSelectButton({ ...item, isActive: true });
  };

  const handleModalClear = () => {
    if (modalClear) {
      data?.map((el) => {
        el.isActive = false;

        return el;
      });
    }
  };

  handleModalClear();

  return (
    <div className="toggleButtons">
      <div
        className={`  btn-group btn-group__${variant} rounded-pill poppins-400 overflow-hidden font-12 ${customClass} `}
        role="group"
      >
        {data?.map((item) => {
          let addClass;
          let isToggled;
          if (passState) {
            isToggled = item?.id === activeItem?.id;
          } else {
            isToggled = item?.id === selectButton?.id;
          }

          if (isToggled) {
            addClass = `btn active text-white whitespace-nowrap`;
          } else {
            addClass = `btn whitespace-nowrap`;
          }
          return (
            <button
              id={`${id}`}
              type="button"
              key={item.id}
              // checked={activeItem}
              className={`${addClass} `}
              disabled={disabled}
              onClick={() => toggleButton(item)}
            >
              {languageKey === '' ? item?.label : t(`${languageKey}.${item.label}`)}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default AppToggleButton;
