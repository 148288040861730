import React, { useEffect, useState, useContext } from 'react';
import AppContext from '@/context/app-context/Context';

const AppToggleSwitch = ({
  id,
  check = false,
  onChange,
  customClass = '',
  disabled,
  status,
  callBack,
  dataId = '',
  fileName = '',
  partnerId = '',
  name,
  clear = false
}) => {
  const appContext = useContext(AppContext);
  const { isClear } = appContext;
  const [checked, setChecked] = useState({ [id]: check });

  const handleChange = (value, e) => {
    setChecked({ [e.target.id]: value });
    if (callBack) {
      const updatedData = {
        name: id,
        valueBoolean: value
      };

      if (callBack) callBack(dataId, value, id, updatedData, fileName, partnerId, name);
    }
  };

  // click handler
  const handleClick = (e) => {
    if (e.keyCode !== 32) return;
    e.preventDefault();
    handleChange(!checked);
  };

  useEffect(() => {
    if (isClear) {
      setChecked({ [id]: false });
    }
  }, [isClear]);

  useEffect(() => {
    if (onChange) {
      onChange(checked);
    }
  }, [checked, onChange]);

  // reset component data
  useEffect(() => {
    if (clear) {
      setChecked({ [id]: false });
    }
  }, [clear]);

  return (
    <div className="d-flex align-items-center borderSwitch">
      <label
        htmlFor={id}
        className={`m-0 p-0 ${customClass} borderSwitch__label ${disabled ? 'disabled' : ''}`}
      >
        <input
          type="checkbox"
          id={id}
          className="borderSwitch__input"
          checked={checked[id]}
          onChange={(e) => handleChange(e.target.checked, e)}
          onClick={(e) => handleClick(e)}
          // onKeyDown={(e) => enterKeyEvent(e)}
        />
        <span className="borderSwitch__indicator" />
      </label>
      {status && (
        <span className="ms-2 text-secondary text-capitalize borderSwitch__status">
          {checked[id] ? status[0] : status[1]}
        </span>
      )}
    </div>
  );
};

export default AppToggleSwitch;
