import React from 'react';
import { User } from 'react-feather';
import { AppAvatar } from '../avatar';

const AppLogoText = ({
  imgUrl,
  text = 'Default Logo text',
  customClass = ``,
  textColor = `text-primary`,
  icon = <User />
}) => (
  <div className={`logo-text d-inline-flex align-items-center ${customClass}`}>
    {imgUrl ? (
      <AppAvatar img={imgUrl} width="25" />
    ) : (
      <div className="logo-text-icon bg-primary text-white d-flex align-items-center justify-content-center">
        {icon}
      </div>
    )}

    <div className={`ms-2 ${textColor}`}>{text}</div>
  </div>
);

export default AppLogoText;
