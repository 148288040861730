import axios from 'axios';
import Cookies from 'js-cookie';
import { size } from 'lodash';
import { toastCall, translateLanguage, getApiBaseUrl } from '@/core/utils';
import apiRequestForSubdomain from './apiRequestForSubdomain';
import { setPartnerLogo, setPartnerName, setPartnerSiteURL } from '@/core/utils/localStorage';

const getSubDomainName = () => {
  const publicSiteUrl = process.env.REACT_APP_PUBLIC_SITE_URL;
  const { host } = window.location;
  const stages = ['www', 'dev', 'test', 'local', 'demo'];
  const hostnameArray = host.split('.');
  const subDomain = hostnameArray[1]; // demo-broker
  const isPartnerPublic = stages.includes(subDomain); // demo-broker.local.v2.uniteliving.com
  const isPublic = stages.includes(hostnameArray[0]); // local.v2.uniteliving.com

  // for development -if has stage
  if (isPublic) return null;
  if (isPartnerPublic) return hostnameArray[0];

  // for production
  if (publicSiteUrl && publicSiteUrl.split('//')[1] === host) return null;
  if (publicSiteUrl && host.includes(publicSiteUrl.split('//')[1])) return hostnameArray[0];
  return null;
};

const setPartnerId = (partnerId) => Cookies.set('partnerId', partnerId);

const setDefaultLocation = (defaultLocation) => {
  if (size(defaultLocation)) {
    localStorage.setItem('defaultLocation', JSON.stringify(defaultLocation));
  }
};

const getValidSubDomain = async (setPartnerData, setLoading) => {
  const BASE_API_URL = getApiBaseUrl();
  try {
    const url = `${BASE_API_URL}/graphql`;
    const accessToken = process.env.REACT_APP_API_KEY || process.env.REACT_APP_SYSTEM_API_KEY;
    const subDomainName = getSubDomainName();

    if (!subDomainName) {
      Cookies.remove('partnerId');
      setPartnerData({ isSubDomainExists: false, isPublic: true });
      setLoading(false);
      return;
    }

    // Get subdomain
    const response = await axios.post(
      url,
      apiRequestForSubdomain({ subDomain: subDomainName, partnerSiteType: 'public' }),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    if (response?.data?.data?.partnersSubDomain?.defaultFindHomeLocation) {
      setDefaultLocation(response?.data?.data?.partnersSubDomain?.defaultFindHomeLocation);
    }
    const subDomainValue = response?.data?.data?.partnersSubDomain;
    if (subDomainValue) {
      setPartnerName(subDomainName);
      setPartnerId(subDomainValue?.partnerId);
      setPartnerLogo(subDomainValue?.logo);
      setPartnerData(subDomainValue);
      setPartnerSiteURL(subDomainValue?.partnerSiteURL || '');
      setLoading(false);
      localStorage.setItem('formatSettingsData', JSON.stringify(subDomainValue?.currencySettings));
      return;
    }
    Cookies.remove('partnerId');
    setPartnerData({ isSubDomainExists: false });
    setLoading(false);
    toastCall('danger', translateLanguage('errors.internal_server_error'), 'top-right');
  } catch (err) {
    setLoading(false);
    toastCall('danger', translateLanguage('errors.internal_server_error'), 'top-right');
  }
};

export default getValidSubDomain;
