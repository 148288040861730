import { Shield } from 'react-feather';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppLogoText } from '@/components/ui';

const DepositAmountCard = ({ deposit, getConvertToCurrency }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        deposit?.isPaid
          ? 'border-2 border-success width-14 default-radius padding-20'
          : ` border-1 border-muted width-14 default-radius padding-20`
      }
    >
      <div>
        <AppLogoText
          icon={<Shield />}
          bgColor="bg-success"
          text={t(`leases.leases_status.${deposit?.security}`)}
          textClass="text-faded-black montserrat-500 font-16"
        />
      </div>
      <div className="margin-t-20">
        {deposit?.isPaid ? (
          <>
            <h6 className="montserrat-700 font-18 text-success margin-tb-15 text-center">
              {t(`leases.leases_status.${deposit?.securityStatus}`)}
            </h6>

            <div className="row g-0 margin-b-15">
              <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6">
                <h6 className="montserrat-500 font-14 text-faded-black ">{t('label.amount')}</h6>
              </div>

              <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6">
                <h6 className="montserrat-500 font-14 text-faded-black ">
                  {getConvertToCurrency(deposit?.securityAmount)}
                </h6>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row g-0">
              <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6">
                <h6 className="montserrat-500 font-12 text-faded-black ">{t('label.amount')}</h6>
              </div>

              <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6">
                <h6 className="montserrat-500 font-12 text-faded-black ">
                  {getConvertToCurrency(deposit?.securityAmount)}
                </h6>
              </div>
            </div>
            <div className="row g-0 margin-t-10">
              <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6">
                <h6 className="montserrat-500 font-12 text-faded-black ">{t('leases.account')}</h6>
              </div>

              <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6">
                <h6 className="montserrat-500 font-12 text-faded-black ">{deposit?.bankNumber}</h6>
              </div>
            </div>
            <div className="row g-0 margin-t-10">
              <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6">
                <h6 className="montserrat-500 font-12 text-faded-black ">{t('leases.kid')}</h6>
              </div>

              <div className="col-md-6 col-xl-6 col-xxl-6 col-xxxl-6">
                <h6 className="montserrat-500 font-12 text-faded-black ">
                  {deposit?.depositInsuranceKid}
                </h6>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default DepositAmountCard;
