const getSubDomainName = () => {
  const publicSiteUrl = process.env.REACT_APP_PUBLIC_SITE_URL;
  const { host } = window.location;
  const stages = ['dev', 'test', 'local', 'demo'];
  const hostnameArray = host.split('.');
  const subDomain = hostnameArray[1]; // demo-broker
  const isPartnerPublic = stages.includes(subDomain); // demo-broker.local.v2.uniteliving.com
  const isPublic = stages.includes(hostnameArray[0]); // local.v2.uniteliving.com

  // for development -if has stage
  if (isPublic) return null;
  if (isPartnerPublic) return hostnameArray[0];

  // for production
  if (publicSiteUrl && publicSiteUrl.split('//')[1] === host) return null;
  if (publicSiteUrl && host.includes(publicSiteUrl.split('//')[1])) return hostnameArray[0];
  return null;
};

export default getSubDomainName;
