import React from 'react';
import { useTranslation } from 'react-i18next';

const AppDropdownFilter = ({ children, handleClear, handleApply, state, disabled }, ref) => {
  const { t } = useTranslation();

  // Prevent bootstrap onclick behaviour
  const handleDefaultRadioDropdownBehaviour = (e) => {
    e.stopPropagation();
  };
  return (
    <ul
      className="dropdown-menu p-0"
      aria-labelledby="dropdownMenuButton"
      ref={ref}
      onClick={(e) => handleDefaultRadioDropdownBehaviour(e)}
      role="menu"
    >
      <div className="dropdown-wrapper">
        {children}

        <div className="filter-dropdown-footer">
          <div className="dropdown-divider" />
          <div className="padding-tb-20 padding-lr-30 d-flex align-items-center justify-content-between">
            <span className="btn-clear" onClick={handleClear} role="button" tabIndex="0">
              {t('common.clear')}
            </span>
            <button
              type="button"
              className={`btn btn-primary ${disabled ? 'disabled' : ''}`}
              onClick={handleApply}
            >
              {t('common.apply')}
            </button>
          </div>
        </div>
      </div>
    </ul>
  );
};

export default React.forwardRef(AppDropdownFilter);
