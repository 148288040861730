/* eslint-disable func-names */
export const addClass = (element, className) => {
  element.classList.add(className);
};

export const removeClass = (element, className) => {
  element.classList.remove(className);
};

export const addRemoveClass = (element, addClassName, removeClassName) => {
  addClass(element, addClassName);
  removeClass(element, removeClassName);
};

// Checkbox check for datatable
export const handleDatatableCheckboxChanged = (event, item, state, setState) => {
  if (event.target.checked) {
    const copy = [...state, item];
    setState(copy);
  } else {
    const copy = [...state];
    const index = copy.indexOf(item);
    if (index > -1) {
      copy.splice(index, 1);
    }
    setState(copy);
  }
};

// Checkbox check all items for datatable
export const handleDatatableCheckboxCheckAll = (event, setState, checkboxData) => {
  const checkboxes = document.querySelectorAll('.datatable-checkbox-select');
  // Toggle all checkboxes
  for (let i = 0; i < checkboxes.length; i += 1) {
    if (checkboxes[i] !== event.target) checkboxes[i].checked = event.target.checked;
  }
  // Select checkboxes value
  if (event.target.checked) {
    const copyItems = [];
    checkboxData.map((item) => copyItems.push(item.id));
    setState(copyItems);
  } else {
    const copyItems = [];
    setState(copyItems);
  }
};

// Data table Scroller
export const handleScroll = (className) => {
  const slider = document.querySelector(className);
  const preventClick = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
  };
  let isDown = false;
  let isDragged = false;
  let startX;
  let scrollLeft;

  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
    const elements = document.querySelectorAll('tr');
    if (isDragged) {
      for (let i = 0; i < elements.length; i += 1) {
        elements[i].addEventListener('click', preventClick);
      }
    } else {
      for (let i = 0; i < elements.length; i += 1) {
        elements[i].removeEventListener('click', preventClick);
      }
    }
    slider.classList.remove('active');
    isDragged = false;
  });
  slider.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    isDragged = true;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2;
    slider.scrollLeft = scrollLeft - walk;
  });
};

// traverse node list items
export const traverseNodeListItems = function (array, callback, scope) {
  for (let i = 0; i < array.length; i += 1) {
    callback.call(scope, i, array[i]);
  }
};

// remove dropdown show class
export const removeShowClass = (id) => {
  const getShowClassID = document.getElementById(id);
  getShowClassID.classList.remove('show');
};

// remove all show classes
// eslint-disable-next-line func-names
export const removeAllShowClass = function (array) {
  for (let i = 0; i < array.length; i += 1) {
    array[i].classList.remove('show');
  }
};

// first string uppercase
export function capitalizeFirstLetter(string) {
  const uppercaseFirst = string && string.charAt(0).toUpperCase() + string.slice(1);
  if (uppercaseFirst) return uppercaseFirst;
  return string;
}
