/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, User, X, XCircle } from 'react-feather';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { AppAvatar, Loader, AppFilterButton, AppButton } from '@/components/ui';
import { removeAllShowClass, delayCall } from '@/core/utils';

const AppSelect = (props) => {
  const {
    data,
    callback,
    context,
    id,
    options: {
      languageKey = '',
      searchable,
      areaExpandedCallback = true,
      customClass = '',
      clearable,
      multiSelect,
      type,
      btnProps,
      selectWithImage,
      scrollbarId,
      chipsIcon = <User />,
      loadMore,
      isLoading,
      dropdownStyle,
      selectedValue = null,
      modalClear,
      placeholder = 'Search & Select....',
      disabled = false
    } = {}
  } = props;

  const { t } = useTranslation();
  const myRef = useRef();
  const [selected, setSelected] = useState(selectedValue);
  const [multiSelected, setMultiSelected] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');
  const [multiSelectedItemFilter, setMultiSelectedItemFIlter] = useState([]);
  const [clickedOutside, setClickedOutside] = useState(false);

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  if (type && type === 'input' && clickedOutside && searchTerm) {
    setSearchTerm('');
  }

  const handleSelectOptionChange = (item) => {
    setSelected(item);
    setSearchTerm('');
    if (callback) callback(item, context, 'select_filter');
  };

  const handleTargetModalCallback = (item) => {
    if (callback) callback(item, 'target_modal', id);
  };

  const handleCallback = () => {
    const dropdownState = document.getElementById(`btn-${id}`).getAttribute('aria-expanded');
    if (callback && areaExpandedCallback)
      callback(selected, context, 'initial_fetch', `dropOpen-${dropdownState}`);
    if (callback && !areaExpandedCallback) callback(selected, context, 'initial_fetch');
  };

  const handleMultiSelectOptionChange = (itemData) => {
    // setSearchTerm('');
    const updateState = multiSelected.map((item) => {
      const items = item;
      if (itemData.id === items.id) {
        items.selected = true;
      }
      return items;
    });

    setMultiSelected(updateState);

    const checkedItem = updateState.filter((item) => item.selected === true);
    setMultiSelectedItemFIlter(checkedItem);
    if (callback) callback(updateState);
  };

  const handleClear = () => {
    setSelected('');
    if (callback) callback('', context, 'clear');
  };

  const handleMultiSelectClearAll = () => {
    const updateState = multiSelected.map((item) => {
      const items = item;
      items.selected = false;
      return items;
    });
    setMultiSelected(updateState);
    setMultiSelectedItemFIlter([]);
    if (callback) callback(updateState, context);
  };

  const handleModalClear = () => {
    if (modalClear && selected) {
      setSelected('');
    }
    if (modalClear && multiSelectedItemFilter.length) {
      const updateState = multiSelected.map((item) => {
        const items = item;
        items.selected = false;
        return items;
      });
      setMultiSelected(updateState);
      setMultiSelectedItemFIlter([]);
    }
  };

  handleModalClear();

  const handleMultiSelectClear = (itemData) => {
    const updateState = multiSelected.map((item) => {
      const items = item;
      if (itemData.id === items.id) {
        items.selected = false;
      }
      return items;
    });
    setMultiSelected(updateState);
    const checkedItem = updateState.filter((item) => item.selected === true);
    setMultiSelectedItemFIlter(checkedItem);
    if (callback) callback(updateState);
  };

  const handleChipsClear = () => {
    const allChipsClasses = document.querySelectorAll('.chips-select');
    removeAllShowClass(allChipsClasses);
  };

  const results = !searchTerm
    ? data
    : data.filter((item) =>
        t(`${languageKey}.${item.title}`).toLowerCase().includes(searchTerm.toLocaleLowerCase())
      );

  // const data = !searchTerm
  //   ? multiSelected
  //   : multiSelected.filter((item) =>
  //       item.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
  //     );

  const selectType = () => {
    if (type === 'dropdown' && !dropdownStyle) return 'dropdown-select';
    if (type === 'filter' && !dropdownStyle) return 'filter-select';
    if (type === 'chips') return 'filter-select chips-select';
    if (type === 'dropdown' && dropdownStyle === 'filter') return 'filter-select';
    if (type === 'filter' && dropdownStyle === 'dropdown') return 'dropdown-select';

    return 'filter-select';
  };

  const filterNoButtonBorerCLass =
    type === 'filter' &&
    btnProps.type === 'filter-no-button' &&
    btnProps.customClass === 'input-type-border';

  return (
    <div
      className={`appDropdownSelect ${disabled && 'disabled'} ${
        filterNoButtonBorerCLass && 'filter-no-button-with-border'
      } ${customClass} ${type === 'input' && 'dropdown-select-with-input'} ${
        type === 'chips' && 'dropdown-select-with-chips'
      }`}
      id={id}
      onClick={() => handleClickInside()}
      ref={myRef}
    >
      {type === 'dropdown' && (
        <AppButton
          id={`btn-${id}`}
          handleClick={() => handleCallback()}
          dropdown="true"
          {...btnProps}
        />
      )}
      {type === 'filter' &&
        (btnProps.type === 'filter-no-button' ? (
          <div
            id={`btn-${id}`}
            className={`btn-filter  ${btnProps.customClass || ''}`}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            role="button"
            tabIndex="0"
            onClick={(filterType) => handleCallback(filterType)}
          >
            <div
              className={`text-sub-primary cursor-pointer poppins-500 ${
                btnProps.noBtnIcon ? 'd-flex align-items-center' : ''
              }`}
            >
              {btnProps.noBtnIcon && (
                <AppAvatar bgColor="bg-tertiary" icon={<User />} width="25" customClass="me-2" />
              )}
              <span>{selected ? selected.title : 'No item selected'}</span>
            </div>
            {btnProps.customClass && (
              <div className="down-icon svg-icon-20 text-gray">
                <ChevronDown />
              </div>
            )}
          </div>
        ) : (
          <AppFilterButton
            id={`btn-${id}`}
            btnClick={(filterType) => handleCallback(filterType)}
            {...btnProps}
          />
        ))}

      {type === 'input' && (
        <div
          className="form-group"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          id={`btn-${id}`}
        >
          <div
            role="button"
            tabIndex="0"
            onClick={() => {
              handleCallback();
            }}
          >
            <input
              type="text"
              className={`form-control ${selected && selected.title ? 'placeholder-style' : ''} ${
                selectWithImage && selected && 'textIndent'
              }`}
              placeholder={
                selected && selected.title
                  ? languageKey === ''
                    ? selected.title
                    : t(`${languageKey}.${selected.title}`)
                  : placeholder
              }
              value={searchTerm}
              onChange={(e) => handleChange(e)}
              disabled={disabled}
            />
            <div className="select-down-icon svg-icon-20">
              <ChevronDown />
            </div>

            {(() => {
              if (selectWithImage && selected) {
                if (selected.img) {
                  return (
                    <img
                      src={selected.img ? selected.img : '/assets/images/tenant.svg'}
                      alt="img"
                      className="img-fluid"
                    />
                  );
                }
                return <img src="/assets/images/tenant.svg" alt="logo" className="img-fluid" />;
              }
              return '';
            })()}
          </div>
        </div>
      )}

      {type === 'chips' && (
        <div
          role="button"
          tabIndex="0"
          className="p-2 chips-select chips-container custom-scrollbar"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="d-flex align-items-center flex-wrap">
            {multiSelected.map((item) =>
              item.selected ? (
                <span className="chips d-flex align-items-center me-2 mb-2" key={item.id}>
                  <AppAvatar
                    icon={chipsIcon}
                    img={item.img}
                    width={25}
                    bgColor="bg-base"
                    customClass="text-sub-primary"
                  />

                  <span className="text-sub-primary poppins-400 ms-2">{item.title}</span>
                  <span
                    className="ms-2"
                    onClick={() => {
                      handleMultiSelectClear(item);
                      handleChipsClear();
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <AppAvatar
                      icon={<X />}
                      width={25}
                      bgColor="bg-base"
                      customClass="text-warning"
                    />
                  </span>
                </span>
              ) : (
                ''
              )
            )}
            <span className="add">+ add</span>
          </div>
        </div>
      )}

      <ul className={`dropdown-menu  ${selectType()}`} aria-labelledby="dropdownMenuButton">
        <div className="dropdown-wrapper ">
          {searchable && type !== 'input' && (
            <>
              <div className="dropdown-search">
                <input
                  type="text"
                  className="form-control"
                  placeholder={placeholder}
                  value={searchTerm || ''}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="dropdown-divider" />{' '}
            </>
          )}
          {clearable && multiSelectedItemFilter.length ? (
            <div className="clear-item">
              <span
                className="font-14 poppins-500"
                onClick={() => handleMultiSelectClearAll()}
                role="button"
                tabIndex="0"
              >
                Clear all
              </span>
            </div>
          ) : (
            ''
          )}

          <div
            id={`custom-scrollbar-${scrollbarId}`}
            className="dropdown-item-wrapper custom-scrollbar"
          >
            <InfiniteScroll
              dataLength={data.length}
              next={() => callback(context, 'load_more')}
              hasMore={loadMore}
              scrollableTarget={`custom-scrollbar-${scrollbarId}`}
              loader={
                loadMore ? (
                  <div className="dropdown-item">
                    <Loader width="36px" height="36px" />
                  </div>
                ) : (
                  ''
                )
              }
            >
              {(() => {
                if (searchable && multiSelect) {
                  return (
                    <MultiSelectComponent
                      languageKey={languageKey}
                      t={t}
                      clearable={clearable}
                      isLoading={isLoading}
                      data={data}
                      handleMultiSelectOptionChange={handleMultiSelectOptionChange}
                      handleMultiSelectClear={handleMultiSelectClear}
                    />
                  );
                }

                if (searchable && !multiSelect && !selectWithImage) {
                  return (
                    <SelectComponent
                      languageKey={languageKey}
                      t={t}
                      clearable={clearable}
                      handleClear={handleClear}
                      selected={selected}
                      isLoading={isLoading}
                      data={results}
                      handleSelectOptionChange={handleSelectOptionChange}
                      handleTargetModalCallback={handleTargetModalCallback}
                    />
                  );
                }

                if (searchable && !multiSelect && selectWithImage) {
                  return (
                    <SelectWithImageComponent
                      languageKey={languageKey}
                      t={t}
                      clearable={clearable}
                      handleClear={handleClear}
                      selected={selected}
                      isLoading={isLoading}
                      data={data}
                      handleSelectOptionChange={handleSelectOptionChange}
                    />
                  );
                }

                return (
                  <SelectComponent
                    languageKey={languageKey}
                    t={t}
                    clearable={clearable}
                    handleClear={handleClear}
                    selected={selected}
                    isLoading={isLoading}
                    data={results}
                    handleSelectOptionChange={handleSelectOptionChange}
                    handleTargetModalCallback={handleTargetModalCallback}
                  />
                );
              })()}
            </InfiniteScroll>
          </div>
        </div>
      </ul>
    </div>
  );
};

const SelectComponent = ({
  data,
  languageKey,
  t,
  handleSelectOptionChange,
  handleTargetModalCallback,
  isLoading,
  selected,
  clearable,
  handleClear
}) => (
  <>
    {(() => {
      if (data.length) {
        return data.map((item) => (
          <li key={item.id}>
            {(() => {
              if (item.targetModal)
                return (
                  <span
                    role="button"
                    tabIndex="0"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target={`#${item.targetModal}`}
                    onClick={() => handleTargetModalCallback(item)}
                  >
                    {languageKey === '' ? item.title : t(`${languageKey}.${item.title}`)}
                  </span>
                );

              return (
                <>
                  {selected && selected.id === item.id ? (
                    <div className="selected-item">
                      <span className="poppins-400 selected-item-title">
                        {languageKey === ''
                          ? selected.title
                          : t(`${languageKey}.${selected.title}`)}
                      </span>
                      <span
                        className={`selected-item-icon ${!clearable && 'd-none'}`}
                        role="button"
                        tabIndex="0"
                        onClick={() => handleClear()}
                      >
                        <XCircle />
                      </span>
                    </div>
                  ) : (
                    <span
                      role="button"
                      onClick={() => handleSelectOptionChange(item)}
                      tabIndex="0"
                      className="dropdown-item"
                    >
                      {languageKey === '' ? item.title : t(`${languageKey}.${item.title}`)}
                    </span>
                  )}
                </>
              );
            })()}
          </li>
        ));
      }
      if (isLoading) {
        return (
          <li className="dropdown-item">
            <Loader width="36px" height="36px" />
          </li>
        );
      }

      return <li className="dropdown-item">Nothing to show</li>;
    })()}
  </>
);

const MultiSelectComponent = ({
  languageKey,
  t,
  data,
  handleMultiSelectOptionChange,
  isLoading,
  clearable,
  handleMultiSelectClear
}) => (
  <>
    {(() => {
      if (data.length) {
        return data.map((item) => (
          <li key={item.id}>
            {(() => {
              if (item.selected)
                return (
                  <div className="selected-item">
                    <span className="poppins-400 selected-item-title">
                      {languageKey === '' ? item.title : t(`${languageKey}.${item.title}`)}
                    </span>
                    <span
                      className={`selected-item-icon ${!clearable && 'd-none'}`}
                      role="button"
                      tabIndex="0"
                      onClick={() => handleMultiSelectClear(item)}
                    >
                      <XCircle />
                    </span>
                  </div>
                );

              return (
                <span
                  role="button"
                  onClick={() => handleMultiSelectOptionChange(item)}
                  tabIndex="0"
                  className="dropdown-item"
                >
                  {languageKey === '' ? item.title : t(`${languageKey}.${item.title}`)}
                </span>
              );
            })()}
          </li>
        ));
      }

      if (isLoading) {
        return (
          <li className="dropdown-item">
            <Loader width="36px" height="36px" />
          </li>
        );
      }

      return <li className="dropdown-item">Nothing to show</li>;
    })()}
  </>
);
const SelectWithImageComponent = ({
  languageKey,
  t,
  data,
  handleSelectOptionChange,
  isLoading,
  selected,
  clearable,
  handleClear
}) => (
  <>
    {(() => {
      if (data.length) {
        return data.map((item) => (
          <React.Fragment key={item.id}>
            {selected && selected.id === item.id ? (
              <div className="selected-item">
                <div className="selected-item-with-image">
                  {selected.img ? (
                    <img src={selected.img} alt="logo" className="img-fluid" />
                  ) : (
                    <img src="/assets/images/tenant.svg" alt="logo" className="img-fluid" />
                  )}
                  <span className="poppins-400 selected-item-title">
                    {languageKey === '' ? selected.title : t(`${languageKey}.${selected.title}`)}
                  </span>
                </div>
                <span
                  className={`selected-item-icon ${!clearable && 'd-none'}`}
                  role="button"
                  tabIndex="0"
                  onClick={() => handleClear()}
                >
                  <XCircle />
                </span>
              </div>
            ) : (
              <li key={item.id}>
                <span
                  role="button"
                  onClick={() => handleSelectOptionChange(item)}
                  tabIndex="0"
                  className="dropdown-item"
                >
                  {item.img ? (
                    <img src={item.img} alt="logo" className="img-fluid" />
                  ) : (
                    <img src="/assets/images/tenant.svg" alt="logo" className="img-fluid" />
                  )}
                  <span className="ms-2">
                    {languageKey === '' ? item.title : t(`${languageKey}.${item.title}`)}
                  </span>
                </span>
              </li>
            )}
          </React.Fragment>
        ));
      }

      if (isLoading) {
        return (
          <li className="dropdown-item">
            <Loader width="36px" height="36px" />
          </li>
        );
      }

      return <li className="dropdown-item">Nothing to show</li>;
    })()}
  </>
);

export default AppSelect;
