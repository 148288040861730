import moment from 'moment';
import Cookies from 'js-cookie';

export const getMonthDateYear = (date) => {
  const fullDate = date ? moment(date) : '';
  return {
    month: fullDate ? fullDate.format('M') : '',
    date: fullDate ? fullDate.format('D') : '',
    year: fullDate ? fullDate.format('YYYY') : ''
  };
};

export const accessToken = () => (Cookies.get('accessToken') ? Cookies.get('accessToken') : '');
