import React, { useCallback, useEffect, useState } from 'react';
import { User, Edit3 } from 'react-feather';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { size } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { AppModal, AppAvatar, AppSpinner } from '@/components/ui';
import { GET_TENANT_DETAILS } from '../graphql/getTenantDetails.gql';
import { toastCall, delayCall } from '@/core/utils';

const LandlordDetailsModal = ({ contractId, openModal }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [tenantInfo, setTenantInfo] = useState({});

  const updateTenantInfo = useCallback((data) => {
    if (data) {
      const { getLeaseTenants } = data;
      if (size(getLeaseTenants?.data)) setTenantInfo(getLeaseTenants?.data);
      setIsLoading(false);
    }
  }, []);

  const [getTenants] = useLazyQuery(GET_TENANT_DETAILS, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      queryData: { contractId }
    },
    onCompleted: (data) => updateTenantInfo(data)
  });

  useEffect(() => {
    if (openModal) {
      setIsLoading(true);
      getTenants().then((response) => {
        if (response?.errors) {
          delayCall(() => {
            if (response?.errors[0].statusCode === 404)
              toastCall('danger', t('errors.unauthorized'));
            else toastCall('danger', t('errors.bad_request'));
          });
        }
      });
    }
  }, [openModal]);

  const LoaderOrEmptyList = () => {
    if (isLoading) return <AppSpinner />;
    return t('label.nothing_found');
  };

  return (
    <AppModal
      id={`landlord-details-${contractId}`}
      type="modal-lg"
      options={{
        title: t('leases.your_details'),
        modalHeader: true,
        modalFooter: true,
        btnClose: true,
        btnCancel: false,
        modalDialogClass: 'modal-dialog-centered'
      }}
    >
      <div className="padding-30 landlordDetailsModal ">
        {!isLoading && size(tenantInfo) ? (
          <>
            <Link to="/profile" data-bs-dismiss="modal">
              <div className="editIcon text-sub-primary padding-5">
                <Edit3 />
              </div>
            </Link>
            <p className="montserrat-400 text-gray font-12">{t('leases.tenant')} </p>

            <div className="d-flex justify-content-center align-items-center">
              {tenantInfo?.mainTenant?.user?.avatarKey ? (
                <AppAvatar img={tenantInfo?.mainTenant?.user?.avatarKey} bgColor="bg-tertiary" />
              ) : (
                <AppAvatar width={60} icon={<User />} bgColor="bg-tertiary" />
              )}
            </div>

            <h5 className="montserrat-400 text-faded-black text-center margin-tb-20">
              {tenantInfo?.mainTenant?.user?.name}
            </h5>

            <div className="w-50 m-auto margin-t-50 margin-b-60">
              {tenantInfo?.mainTenant?.user?.phoneNumber && (
                <h6 className="montserrat-500 text-gray">
                  T
                  <span className=" montserrat-600 text-faded-black margin-l-10">
                    {tenantInfo?.mainTenant?.user?.phoneNumber}
                  </span>
                </h6>
              )}

              <h6 className="montserrat-500 text-gray margin-t-20">
                E
                <span className=" montserrat-600 text-faded-black margin-l-10">
                  {tenantInfo?.mainTenant?.user?.email}
                </span>
              </h6>
            </div>

            <div className="dropdown-divider margin-b-50" />
          </>
        ) : (
          <span className="padding-40 margin-t-30 border-0 text-center">
            <LoaderOrEmptyList />
          </span>
        )}
      </div>
    </AppModal>
  );
};

export default LandlordDetailsModal;
