import ActionTypes from './ActionTypes';

const {
  LOADING,
  SET_LIMIT,
  SET_IS_DATA_FETCH,
  SET_CLEAR,
  HIDE_MAP,
  LISTING_STATE,
  DEFAULT_MAP_CENTER,
  SET_DASHBOARD_TYPE,
  SET_USER,
  SET_API_CALL,
  SET_FIND_HOME_INFO,
  SET_MAP_SETTINGS
} = ActionTypes;

export default (state, action) => {
  const { type, payload } = action;
  const { limit, findHomeInfo, mapSettings } = state;
  switch (type) {
    default:
      return state;

    case LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case SET_LIMIT:
      return {
        ...state,
        limit: limit + payload
      };
    case SET_IS_DATA_FETCH:
      return {
        ...state,
        isDataFetch: payload
      };
    case SET_CLEAR:
      return {
        ...state,
        isClear: payload
      };
    case HIDE_MAP:
      return {
        ...state,
        hideMap: payload
      };

    case LISTING_STATE:
      return {
        ...state,
        listingState: payload
      };

    case DEFAULT_MAP_CENTER:
      return {
        ...state,
        defaultMapCenter: payload
      };

    case SET_DASHBOARD_TYPE:
      return {
        ...state,
        userTypes: payload
      };
    case SET_USER:
      return {
        ...state,
        user: payload
      };
    case SET_API_CALL:
      return {
        ...state,
        apiCall: payload
      };
    case SET_FIND_HOME_INFO:
      return {
        ...state,
        findHomeInfo: { ...findHomeInfo, ...payload }
      };
    case SET_MAP_SETTINGS:
      return {
        ...state,
        mapSettings: { ...mapSettings, ...payload }
      };
  }
};
