import React from 'react';

const ChatIcon = () => (
  <svg width="57" height="55" viewBox="0 0 57 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.3645 0.5C14.6822 0.5 2.5 12.3412 2.5 27.5C2.5 31.8877 3.75228 36.0259 5.54315 39.7239C5.35838 42.1011 4.4203 47.3106 0 51.8743C0 51.8743 8.28629 52.9602 14.4264 50.2455C18.6904 53.2391 23.8355 55 29.3645 55C44.0467 55 56.5 42.6588 56.5 27.5C56.5 12.3412 44.0467 0.5 29.3645 0.5Z"
      fill="#2B8768"
    />
    <path
      d="M15.752 31.5503C17.685 31.5503 19.252 29.9833 19.252 28.0503C19.252 26.1173 17.685 24.5503 15.752 24.5503C13.819 24.5503 12.252 26.1173 12.252 28.0503C12.252 29.9833 13.819 31.5503 15.752 31.5503Z"
      fill="white"
    />
    <path
      d="M29.127 31.5503C31.0599 31.5503 32.627 29.9833 32.627 28.0503C32.627 26.1173 31.0599 24.5503 29.127 24.5503C27.194 24.5503 25.627 26.1173 25.627 28.0503C25.627 29.9833 27.194 31.5503 29.127 31.5503Z"
      fill="white"
    />
    <path
      d="M42.502 31.5503C44.435 31.5503 46.002 29.9833 46.002 28.0503C46.002 26.1173 44.435 24.5503 42.502 24.5503C40.569 24.5503 39.002 26.1173 39.002 28.0503C39.002 29.9833 40.569 31.5503 42.502 31.5503Z"
      fill="white"
    />
  </svg>
);

export default ChatIcon;
