import React from 'react';
import { Edit3, FilePlus, User } from 'react-feather';
import { AppButton, LandLordKeyIcon } from '@/components/ui';
import Agent from '@/views/lease/components/Agent';
import { translateLanguage } from '@/core/utils';

const LandlordSigning = ({ listingId, agent, signingUrl }) => (
  <div className="card card-with-shadow border-0 padding-30">
    <div className="row">
      <div className="col-md-8 col-xl-8 col-xxl-8 col-xxxl-9 padding-lr-40">
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="">
            <h6 className="montserrat-600 font-18 text-faded-black text-center margin-t-20">
              {translateLanguage('leases.assignment_ready_for_signing')}
            </h6>
            <div className="d-flex justify-content-center margin-t-30">
              <AppButton
                icon={<Edit3 />}
                handleClick={() => window.open(signingUrl)}
                btnClass="btn btn-primary"
                label="Read and sign here"
              />
            </div>
          </div>

          <div className="d-flex align-items-center stepProgress">
            <div className="stepProgress__container">
              <div className="stepProgress__bar" id="progress" style={{ width: `50%` }} />

              <div className="stepProgress__circle stepProgress__circle--active">
                <FilePlus />
              </div>

              <div className="stepProgress__circle stepProgress__circle--active">
                <User />
              </div>

              <div className="stepProgress__circle svg-icon-25">
                <LandLordKeyIcon />
              </div>

              <div className="stepProgress__text">
                <span className="montserrat-500 font-14 text-faded-black">
                  {translateLanguage('leases.signed_by_agent')}
                </span>
                <span className="montserrat-500 font-14 text-faded-black">
                  {translateLanguage('leases.landlord_e_signing')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Agent agent={agent} listingId={listingId} />
    </div>
  </div>
);

export default LandlordSigning;
