import React, { useContext, useEffect, useState } from 'react';
import { User } from 'react-feather';
import { useApolloClient } from '@apollo/client';
import { size } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AppAvatar, AppBadge, AppSpinner } from '@/components/ui';
import { GET_PAYOUT_DETAILS } from '../graphql/payoutDetails.gql';
import preparePayoutDetailsData from '@/views/lease/helpers/preparePayoutDetailsData';
import LeaseDetailsContext from '@/context/LeaseDetailsContext';
import {
  getUserInfo,
  getStatusTypeVariant,
  getFormattedDate,
  delayCall,
  toastCall,
  getDateFormat,
  updateUrlWithHeightAndWidth
} from '@/core/utils';

const PayoutHistoriesModal = ({ id, payoutId, isOpenModal, setIsOpenModal }) => {
  const client = useApolloClient();
  const { getConvertToCurrency } = useContext(LeaseDetailsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [payoutDetailsData, setPayoutDetailsData] = useState({});
  const { t } = useTranslation();

  const updatePayoutDetails = (data) => {
    if (size(data)) {
      const preparePayoutDetails = preparePayoutDetailsData(data);
      setPayoutDetailsData(preparePayoutDetails);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const payoutDetails = async () => {
    const { data, errors } = await client.query({
      query: GET_PAYOUT_DETAILS,
      variables: {
        queryData: {
          payoutId,
          requestFromPartnerPublic: true
        }
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    });

    if (size(data)) {
      updatePayoutDetails(data?.payoutDetails);
    }
    if (size(errors)) {
      delayCall(() => {
        if (errors[0]?.message) toastCall('danger', errors[0]?.message);
        else toastCall('danger', t('errors.bad_request'));
      });
    }
  };

  const LoaderOrEmptyList = () => {
    if (isLoading) return <AppSpinner />;
    return t('label.nothing_found');
  };

  const modal = document.getElementById(id);
  useEffect(() => {
    if (isOpenModal) {
      payoutDetails();
    }

    modal?.addEventListener('hidden.bs.offcanvas', () => {
      setPayoutDetailsData({});
      setIsOpenModal(false);
      setIsLoading(true);
    });
  }, [isOpenModal, payoutId]);

  const getTotalAmount = (invoiceSummaryData) => {
    const initialValue = 0;
    const amounts = invoiceSummaryData?.map((invoiceSummary) => invoiceSummary?.amount);
    const totalAmount = amounts?.reduce(
      (previousAmount, currentAmount) => previousAmount + currentAmount,
      initialValue
    );
    if (totalAmount.toFixed(2) > 0) {
      return totalAmount?.toFixed(2);
    }
    return 0;
  };

  const showInvoiceInfo = (title, serialId) => (
    <span>{`${t(`payout_details.${title}`)} (#${serialId})`}</span>
  );

  const getInvoiceTitle = (payoutMeta, serialId) => {
    if (payoutMeta?.type === 'rent_invoice') {
      return showInvoiceInfo('rent_amount', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'unpaid_earlier_payout') {
      return showInvoiceInfo('unpaid_earlier_payout', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'brokering_commission') {
      return showInvoiceInfo('brokering_commission', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'addon_commissions') {
      return showInvoiceInfo('addon_commissions', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'management_commission') {
      return showInvoiceInfo('management_commission', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'unpaid_expenses_and_commissions') {
      return showInvoiceInfo(
        'unpaid_expenses_and_commissions',
        payoutMeta?.serialId || serialId || ''
      );
    }
    if (payoutMeta?.type === 'moved_to_next_payout') {
      return showInvoiceInfo('moved_to_next_payout', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'credit_rent_invoice') {
      return showInvoiceInfo('credit_rent_invoice', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'credit_brokering_commission') {
      return showInvoiceInfo('credit_brokering_commission', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'credit_addon_commission') {
      return showInvoiceInfo('credit_addon_commission', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'credit_management_commission') {
      return showInvoiceInfo(
        'credit_management_commission',
        payoutMeta?.serialId || serialId || ''
      );
    }
    if (payoutMeta?.type === 'management_commission') {
      return showInvoiceInfo('management_commission', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'credit_addons') {
      return showInvoiceInfo('credit_addons', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'landlord_invoice') {
      return showInvoiceInfo('landlord_invoice', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'final_settlement_invoiced') {
      return showInvoiceInfo('final_settlement_invoiced', payoutMeta?.serialId || serialId || '');
    }
    if (payoutMeta?.type === 'final_settlement_invoiced_cancelled') {
      return showInvoiceInfo(
        'final_settlement_invoiced_cancelled',
        payoutMeta?.serialId || serialId || ''
      );
    }
    return '';
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-end width-55 p-0 card card-with-shadow border-0"
        tabIndex="-1"
        id={id}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header padding-30">
          <h5 id="offcanvasRightLabel">
            {t(`leases.leases_status.payout`)} #{payoutDetailsData?.serialId}
          </h5>
          <button
            type="button"
            className="btn-close text-reset margin-b-20 margin-r-10"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body bg-white padding-30">
          {(() => {
            if (size(payoutDetailsData) && !isLoading) {
              return (
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <span className="svg-icon-40 text-white bg-primary padding-lr-10 padding-tb-10 rounded-3 margin-r-10">
                          <User />
                        </span>
                        <div>
                          <h6>{getUserInfo()?.name}</h6>
                          <p>{payoutDetailsData?.location}</p>
                          <p>
                            {t('payout_details.estimated_payout')}:{' '}
                            {payoutDetailsData?.estimatedDate
                              ? getFormattedDate(payoutDetailsData?.estimatedDate, 'DD.MM.YYYY')
                              : '-'}
                          </p>
                        </div>
                      </div>

                      <div className="d-flex align-items-start flex-wrap">
                        {payoutDetailsData?.status && (
                          <AppBadge
                            pill
                            label={payoutDetailsData?.status}
                            variant={getStatusTypeVariant(payoutDetailsData?.status)}
                            labelType="label"
                          />
                        )}
                        {(() => {
                          if (
                            payoutDetailsData?.status !== 'completed' &&
                            payoutDetailsData?.numberOfFails
                          ) {
                            return (
                              <span className="ms-2">
                                {' '}
                                <AppBadge
                                  labelType
                                  label={payoutDetailsData?.numberOfFails}
                                  variant="danger"
                                />
                              </span>
                            );
                          }
                          return false;
                        })()}

                        {(() => {
                          if (
                            payoutDetailsData?.status === 'completed' &&
                            payoutDetailsData?.paymentStatus
                          ) {
                            return (
                              <div className="ms-2">
                                <AppBadge
                                  pill
                                  label={payoutDetailsData?.paymentStatus}
                                  variant={getStatusTypeVariant(payoutDetailsData?.paymentStatus)}
                                  labelType="label"
                                />
                              </div>
                            );
                          }
                          return false;
                        })()}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 margin-t-60">
                    <table className="table table-striped table-borderless margin-t-15">
                      <thead>
                        <tr>
                          <td className="montserrat-400 padding-l-10 text-gray font-14 padding-tb-10">
                            <p>{t('label.description')}</p>
                          </td>
                          <td className="montserrat-400 text-gray font-14  padding-tb-10 d-flex justify-content-end padding-r-20">
                            <p>{t('label.amount')}</p>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {(() => {
                          if (size(payoutDetailsData?.invoicesSummaryData)) {
                            return (
                              <>
                                {payoutDetailsData?.invoicesSummaryData?.map((invoiceSummary) => (
                                  <tr
                                    key={invoiceSummary?.serialId}
                                    className="poppins-400 font-14 text-sub-primary"
                                  >
                                    <td className="padding-tb-10 montserrat-500 text-faded-black font-14 padding-l-30">
                                      <p>
                                        {getInvoiceTitle(invoiceSummary, invoiceSummary?.serialId)}
                                      </p>
                                    </td>
                                    <td className="padding-tb-10 montserrat-500 text-faded-black font-14 d-flex justify-content-end padding-r-20">
                                      <p>{getConvertToCurrency(invoiceSummary?.amount)}</p>
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <td />
                                  <td className="padding-tb-10 montserrat-600 text-faded-black font-14 d-flex justify-content-between padding-r-20">
                                    <p>{t('label.total')}</p>
                                    <p>
                                      {getConvertToCurrency(
                                        getTotalAmount(payoutDetailsData?.invoicesSummaryData)
                                      )}
                                    </p>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                          return false;
                        })()}
                      </tbody>
                    </table>
                  </div>

                  <div className="col-12 margin-t-50 margin-b-30 mx-3">
                    <h6 className="padding-b-10 montserrat-400">{t('label.tenant')}</h6>
                    <div className="row">
                      {payoutDetailsData?.tenantsInfo?.map((tenantInfo) => (
                        <div key={tenantInfo?._id} className="col-3 d-flex align-items-center">
                          {tenantInfo?.avatarKey ? (
                            <AppAvatar
                              img={updateUrlWithHeightAndWidth(tenantInfo?.avatarKey)}
                              bgColor="bg-sub-primary"
                              width="25"
                            />
                          ) : (
                            <AppAvatar icon={<User />} bgColor="bg-sub-primary" width="25" />
                          )}
                          <p className="margin-l-10">{tenantInfo?.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="dropdown-divider margin-tb-20" />

                  <div className="col-12 margin-t-30 mx-3">
                    <div className="row">
                      <div className="col-3 pe-0">
                        <h6 className="padding-b-10 montserrat-400">{t('label.agent')}</h6>
                        <div className="d-flex">
                          <div className="d-flex align-items-center">
                            {payoutDetailsData?.agentInfo?.avatarKey ? (
                              <AppAvatar
                                img={updateUrlWithHeightAndWidth(
                                  payoutDetailsData?.agentInfo?.avatarKey
                                )}
                                bgColor="bg-sub-primary"
                                width="25"
                              />
                            ) : (
                              <AppAvatar icon={<User />} bgColor="bg-sub-primary" width="25" />
                            )}
                            <p className="margin-l-10">{payoutDetailsData?.agentInfo?.name}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-3">
                        <h6 className="padding-b-10 montserrat-400">{t('label.branch')}</h6>
                        <div className="d-flex">
                          <div className="d-flex align-items-center">
                            <p>{payoutDetailsData?.branchInfo?.name}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    {(() => {
                      if (size(payoutDetailsData?.events)) {
                        return (
                          <table className="table table-striped table-borderless margin-t-50">
                            <thead>
                              <tr>
                                <td className="montserrat-400 padding-l-10 text-gray font-14 padding-tb-10">
                                  <p>{t('payout_details.events')}</p>
                                </td>
                                <td className="montserrat-400 text-gray font-14  padding-tb-10 d-flex justify-content-end padding-r-20">
                                  <p>{t('label.date')}</p>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {payoutDetailsData?.events?.map((eventData) => (
                                <tr key={eventData?.date}>
                                  <td className="padding-tb-10 montserrat-500 text-faded-black font-14 padding-l-30">
                                    <p>{t(`payout_details.${eventData?.event}`)}</p>
                                  </td>
                                  <td className="padding-tb-10 montserrat-500 text-faded-black font-14 d-flex justify-content-end padding-r-20">
                                    <p>
                                      {eventData?.date
                                        ? moment(eventData?.date)?.format(getDateFormat())
                                        : ''}
                                    </p>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        );
                      }
                      return false;
                    })()}
                  </div>
                </div>
              );
            }
            return (
              <span className="d-flex justify-content-center align-items-center h-100 border-0 text-center">
                <LoaderOrEmptyList />
              </span>
            );
          })()}
        </div>
      </div>
    </>
  );
};

export default PayoutHistoriesModal;
