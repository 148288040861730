import { CheckCircle } from 'react-feather';
import React from 'react';
import { Loader } from '@/components/ui';

const LoaderAndUpdate = ({
  loader = false,
  t,
  icon = <CheckCircle />,
  label = 'save',
  loadingLabel = 'saving'
}) => (
  <>
    {loader ? (
      <div className="d-flex align-items-center justify-content-center">
        <span className="px-1">{t(`label.${loadingLabel}`)}</span>
        <span>
          <Loader variant="#fff" width="24px" height="24px" />
        </span>
      </div>
    ) : (
      t && (
        <>
          {icon || ''} {t(`label.${label}`)}
        </>
      )
    )}
  </>
);

export default LoaderAndUpdate;
