import React from 'react';
import { AppButton } from '../components/ui';

// eslint-disable-next-line import/prefer-default-export
export const ModalData = [
  {
    id: 0,
    title: 'Modal Default',
    label: 'modal-default',
    saveBtn: <AppButton btnClass="btn-primary me-2" label="Save" />,
    customStyle: '',
    description:
      'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.'
  },
  {
    id: 1,
    title: 'Modal Small',
    label: 'modal-sm',
    saveBtn: <AppButton btnClass="btn-primary me-2" label="Save" />,
    customStyle: '',
    description:
      'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.'
  },
  {
    id: 2,
    title: 'Modal Large',
    label: 'modal-lg',
    saveBtn: <AppButton btnClass="btn-primary me-2" label="Save" />,
    customStyle: '',
    description:
      'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.'
  },
  {
    id: 3,
    title: 'Modal Extra large',
    label: 'modal-xl',
    saveBtn: <AppButton btnClass="btn-primary me-2" label="Save" />,
    customStyle: '',
    description:
      'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.'
  },
  {
    id: 4,
    title: 'Modal Full Width',
    label: 'modal-fullwidth',
    saveBtn: <AppButton btnClass="btn-primary me-2" label="Save" />,
    customStyle: '',
    description:
      'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.'
  },
  {
    id: 4,
    title: 'Modal Full Screen',
    label: 'modal-fullscreen',
    saveBtn: <AppButton btnClass="btn-primary me-2" label="Save" />,
    customStyle: '',
    description:
      'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.'
  }
];
