import React from 'react';

const LandLordKeyIcon = () => (
  <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23 23.3516V21.3516C23 20.2907 22.5786 19.2733 21.8284 18.5231C21.0783 17.773 20.0609 17.3516 19 17.3516H13C11.9391 17.3516 10.9217 17.773 10.1716 18.5231C9.42143 19.2733 9 20.2907 9 21.3516V23.3516"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 13.3516C17.933 13.3516 19.5 11.7846 19.5 9.85156C19.5 7.91857 17.933 6.35156 16 6.35156C14.067 6.35156 12.5 7.91857 12.5 9.85156C12.5 11.7846 14.067 13.3516 16 13.3516Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.9103 0.849585L27.9062 2.80827M27.9062 2.80827L30.8439 2.76149L30.8369 6.18919L27.8992 6.23596M27.9062 2.80827L27.8992 6.23596M27.8909 10.2611C28.3932 10.2497 28.891 10.3378 29.3556 10.5203C29.8202 10.7028 30.2424 10.9761 30.598 11.3244C30.9536 11.6727 31.2356 12.0892 31.4276 12.5499C31.6196 13.0106 31.718 13.5064 31.7169 14.0087C31.7159 14.511 31.6155 15.0099 31.4216 15.4768C31.2277 15.9436 30.944 16.3691 30.5869 16.7288C30.2299 17.0884 29.8065 17.3752 29.3411 17.5725C28.8757 17.7698 28.3776 17.8737 27.8753 17.8784C26.877 17.8769 25.9261 17.4832 25.2277 16.7821C24.5292 16.0809 24.139 15.1285 24.141 14.1298C24.1431 13.1311 24.5372 12.1661 25.2386 11.4427C25.94 10.7193 26.8925 10.2953 27.8909 10.262L27.8909 10.2611ZM27.8909 10.2611L27.8992 6.23596"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LandLordKeyIcon;
