import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import { Link, Redirect, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { X } from 'react-feather';
import { Modal as Modals } from 'bootstrap/dist/js/bootstrap.bundle.js';
import { toast } from 'react-toastify';
import { Loader } from '@/components/ui/customSvg';
import { AppModal } from '@/components/ui';
import LoginWithFacebook from '@/views/login/LoginWithFacebook';
import setTokens, { validate, checkValidationError, handleLogin } from '@/views/login/login-helper';
import {
  toastCall,
  translateLanguage,
  getUserRedirectPath,
  setUserRedirectPath,
  isAuthenticated,
  getUserId
} from '@/core/utils';
import AppContext from '@/context/app-context/Context';
import RegisterModal from '@/views/register/modalSection/RegisterModal';
import useNewConversation from '@/views/inbox/hooks/useNewConversation';

const SignInModal = ({
  id,
  forgotPassPath = '/reset-password',
  createAccountPath,
  isFindHome,
  listingId,
  callback
}) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const { setUser, user: userContext } = appContext;
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  const { createConversation } = useNewConversation();
  const toastId = useRef(null);

  const [login, setLogin] = useState({
    fields: {
      email: '',
      password: ''
    },
    errors: {
      email: '',
      password: ''
    }
  });

  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    fields: { email, password }
  } = login;
  const emailError = login.errors.email;
  const passwordError = login.errors.password;

  const isEnabled = email && password && !emailError && !passwordError;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, [name]: validate(name, value) },
      fields: { ...prevState.fields, [name]: name === 'email' ? value.toLowerCase() : value }
    }));
  };

  const handleTokenResult = useCallback((tokenResult, userInfo) => {
    if (tokenResult?.msg === 'Invalid credentials') {
      setShowErrorMsg(true);
      setLoading(false);
      setTimeout(() => {
        setShowErrorMsg(false);
      }, 3000);
    } else if (tokenResult?.msg === 'User has no password set') {
      setLoading(false);
      toastCall('danger', translateLanguage('errors.user_has_no_password_set'), 'top-right');
    } else {
      document.getElementById('closeModal').click();
      setLoading(false);
      if (userInfo) setUser({ ...userContext, name: userInfo.name });

      setLogin({
        ...login
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    checkValidationError(login, setLogin);
    toast.dismiss(toastId?.current);
    handleLogin(email, password, setLoading, handleTokenResult, navigate);
  };

  useEffect(() => {
    const modal = document.getElementById(id);

    modal?.addEventListener('hidden.bs.modal', () => {
      setLogin({
        fields: {
          email: '',
          password: ''
        },
        errors: {
          email: '',
          password: ''
        }
      });
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated() && isFindHome) {
      createConversation({ listingId });

      if (callback) {
        callback(false);
      }
    }

    const sendListingMessage = async () => {
      if (!getUserId() || !listingId) return false;
      return createConversation({ listingId });
    };

    // console.log(sendListingMessage);
  }, [isAuthenticated()]);

  return (
    <>
      <AppModal
        id={id}
        type="modal-dialog-centered"
        options={{
          modalDialogClass: 'width-25'
        }}
      >
        <div className="savePropertyModal">
          <div
            className="float-end cursor-pointer padding-10"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModal"
          >
            <X id="closeModal" />
          </div>
          <div className="padding-30">
            <h5 className={`text-center `}>{translateLanguage('label.login')}</h5>
            <form className="margin-t-30" onSubmit={handleSubmit}>
              <div className="">
                <input
                  type="email"
                  name="email"
                  id="loginEmail"
                  autoComplete="off"
                  onChange={(event) => handleChange(event)}
                  value={email}
                  className="form-control"
                  placeholder={t('common.email_address')}
                />
                <p className="text-danger mt-1">{emailError}</p>
              </div>

              <div className="mt-3">
                <input
                  type="password"
                  name="password"
                  id="loginPassword"
                  autoComplete="off"
                  onChange={(event) => handleChange(event)}
                  value={password}
                  className="form-control"
                  placeholder={t('common.password')}
                />
                <p className="text-danger mt-1">{passwordError}</p>
              </div>

              <div className="mt-4">
                <div className="d-grid">
                  <button
                    type="submit"
                    disabled={loading || !isEnabled}
                    className="btn public-btn btn-primary"
                  >
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="px-1">{t('label.authenticating')} </span>
                        <span>
                          <Loader variant="#fff" width="24px" height="24px" />
                        </span>
                      </div>
                    ) : (
                      t('label.login')
                    )}
                  </button>
                </div>
              </div>
            </form>

            <Link to={forgotPassPath}>
              <div className="text-center my-4" data-bs-dismiss="modal">
                <span className="text-faded-black">{t('register.sign_in.forgot_password')} </span>
              </div>
            </Link>

            <div className="block-divider" />

            <div className="mt-4">
              <LoginWithFacebook
                handleTokenResult={handleTokenResult}
                setTokens={setTokens}
                fromUser={from?.pathname}
              />
            </div>

            <div className="d-flex align-items-center justify-content-center my-4">
              <p className="">{translateLanguage('register.sign_in.not_registered')}</p>

              <span
                className="text-primary font-14 font-weight-500 ms-2"
                role="button"
                data-bs-target="#register-modal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                {translateLanguage('label.create_an_account')}
              </span>
              {showErrorMsg &&
                toastCall(
                  'danger',
                  translateLanguage('errors.invalid_email_or_password'),
                  'top-right',
                  'user-login-error'
                )}
            </div>
          </div>
        </div>
      </AppModal>
      <RegisterModal id="register-modal" signInModalId={id} />
    </>
  );
};

export default SignInModal;
