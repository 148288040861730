import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { size } from 'lodash';
import { getUserId, toastCall, translateLanguage } from '@/core/utils';
import { getPartnerId } from '@/core/utils/AppHelpers';
import { ADD_CONVERSATION } from '../graphql/addConversations.gql';

const useNewConversation = () => {
  const [conversation, setConversation] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [addConversation] = useMutation(ADD_CONVERSATION, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only'
  });

  const createConversation = async ({ listingId, contractId }) => {
    setLoading(true);
    const inputData = {
      listingId,
      senderId: getUserId(),
      contractId
    };
    if (getPartnerId()) inputData.partnerId = getPartnerId();

    const result = await addConversation({
      variables: {
        inputData
      }
    });
    setLoading(false);
    const conversationData = result?.data.addConversations;
    if (size(conversationData)) {
      setConversation(conversationData[0]);
      if (size(conversationData[0])) {
        navigate(`/inbox/${conversationData[0]?._id}`);
      }
    } else {
      toastCall('danger', translateLanguage('errors.bad_request'));
    }
  };

  return { createConversation, loading };
};

export default useNewConversation;
