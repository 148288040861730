import React from 'react';

const Bathroom = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.56697 20.1262C2.53297 18.2771 1.2612 15.7363 1 12.9999L23 12.9999C22.7388 15.7363 21.467 18.2771 19.433 20.1262L4.56697 20.1262Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8787 3.87868C16.4413 3.31607 17.2044 3 18 3C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6L21 13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 3.8285C10.7502 3.07835 11.7676 2.65692 12.8284 2.65692C13.8893 2.65692 14.9067 3.07835 15.6569 3.8285C16.407 4.57864 16.8284 5.59606 16.8284 6.65692C16.8284 7.71779 16.407 8.73521 15.6569 9.48535"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 4L15.5 9.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default Bathroom;
