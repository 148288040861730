/* eslint-disable no-nested-ternary */
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { size } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { GET_SUB_DOMAIN } from './graphql/partnersSubDomain.gql';
import GET_LEASE_INFO from './graphql/getLease.gql';
import { AppSpinner, ContentWrapper } from '@/components/ui';
import LeaseDetailsContext from '@/context/LeaseDetailsContext';
import prepareSingleLease from './helpers/prepareSingleLease';
import LeaseOverview from './LeaseOverview/index';
import {
  getSubDomainName,
  canAccessLeasePage,
  toastCall,
  delayCall,
  getUserId,
  getPartnerId,
  convertToCurrency
} from '@/core/utils';
import TenantLeaseProcesss from '@/views/lease/TenantLeaseProcesss';
import LandlordLeaseProcess from '@/views/lease/LandlordLeaseProcess';
import AppContext from '@/context/app-context/Context';
import getLoggedUserType from '@/views/lease/helpers/leasesHelper';
import TenantLeaseProcess from './TenantLeaseProcesss';
import LeaseStatus from '@/views/lease/components/LeaseStatus';

const Lease = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const params = searchParams.getAll('signingStatus');
  const isStatusCancel = params?.includes('cancel');
  const isStatusError = params?.includes('error');

  const { isLoading, setIsLoading, userTypes } = appContext;
  const [currencySettings, setCurrencySettings] = useState({});
  const [leaseInfo, setLeaseInfo] = useState([]);
  const [seeLeaseButton, setSeeLeaseButton] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [backButton, setBackButton] = useState(true);
  const publicToken = process.env.REACT_APP_API_KEY;

  const updateLeaseInfo = useCallback((data) => {
    if (data) {
      const { getSingleLeaseForPublicSite } = data;
      if (size(getSingleLeaseForPublicSite)) {
        const leaseData = prepareSingleLease(getSingleLeaseForPublicSite);
        if (size(leaseData)) setLeaseInfo(leaseData);
      }
      setIsLoading(false);
    }
  }, []);

  const getConvertToCurrency = (number, showSymbol = true, options = {}, settings = {}) =>
    convertToCurrency(number, size(settings) ? settings : currencySettings, showSymbol, options);

  const [partnersSubDomain] = useLazyQuery(GET_SUB_DOMAIN, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: { queryData: { subDomain: getSubDomainName() } },
    onCompleted: (response) => {
      const currencySettingInfo = response?.partnersSubDomain?.currencySettings;
      if (size(currencySettingInfo)) {
        setCurrencySettings(currencySettingInfo);
      }
    }
  });

  const [getLease] = useLazyQuery(GET_LEASE_INFO, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    variables: {
      queryData: { personType: getLoggedUserType(userTypes), contractId: id }
    },
    onCompleted: (data) => updateLeaseInfo(data)
  });

  useEffect(() => {
    if (!getUserId() || !getPartnerId())
      return navigate({
        pathname: '/login/idfy-jwt',
        search: isStatusCancel
          ? '?signingStatus=cancel'
          : isStatusError
          ? '?signingStatus=error'
          : ''
      });
    if (!canAccessLeasePage(userTypes)) return navigate('/');
    setIsLoading(true);
    partnersSubDomain();
    getLease().then((response) => {
      if (response?.errors) {
        setIsLoading(false);
        delayCall(() => {
          if (response?.errors[0].statusCode === 404) toastCall('danger', t('errors.unauthorized'));
          else toastCall('danger', t('errors.bad_request'));
        });
      }
    });
    return false;
  }, []);

  const LoaderOrEmptyList = () => {
    if (isLoading) return <AppSpinner />;
    return t('common.nothing_found');
  };

  const LeaseDetails = () => {
    const userType = getLoggedUserType(userTypes);
    if (showDetails) {
      return (
        <LeaseOverview
          setSeeLeaseButton={setSeeLeaseButton}
          setBackButton={setBackButton}
          leaseInfo={leaseInfo}
        />
      );
    }

    // For Landlord sign
    if (
      userType === 'landlord' &&
      (leaseInfo?.leaseStatus === 'in_progress' ||
        leaseInfo?.leaseStatus === 'new' ||
        leaseInfo?.enabledLeaseEsigning ||
        leaseInfo?.enabledEsigning)
    ) {
      if (
        !leaseInfo?.enabledEsigning ||
        (leaseInfo?.agentAssignmentSigningStatus?.signed &&
          leaseInfo?.landlordAssignmentSigningStatus?.signed)
      ) {
        return (
          <TenantLeaseProcess
            listingId={leaseInfo?.propertyId}
            leaseInfo={leaseInfo}
            setSeeLeaseButton={setSeeLeaseButton}
            setShowDetails={setShowDetails}
            setBackButton={setBackButton}
            fromLandlordPage
          />
        );
      }

      return (
        <div className="margin-t-20">
          <LandlordLeaseProcess
            listingId={leaseInfo?.propertyId}
            leaseInfo={leaseInfo}
            setSeeLeaseButton={setSeeLeaseButton}
            setBackButton={setBackButton}
          />
        </div>
      );
    }
    // For Tenant sign
    if (
      userType === 'tenant' &&
      (leaseInfo?.leaseStatus === 'in_progress' || leaseInfo?.enabledLeaseEsigning)
    ) {
      return (
        <div>
          <TenantLeaseProcesss
            listingId={leaseInfo?.propertyId}
            leaseInfo={leaseInfo}
            setSeeLeaseButton={setSeeLeaseButton}
            setShowDetails={setShowDetails}
            setBackButton={setBackButton}
          />
        </div>
      );
    }
    setShowDetails(true);
    return (
      <LeaseOverview
        listingId={leaseInfo?.propertyId}
        setSeeLeaseButton={setSeeLeaseButton}
        setShowDetails={setShowDetails}
        setBackButton={setBackButton}
        leaseInfo={leaseInfo}
      />
    );
  };

  return (
    <LeaseDetailsContext.Provider value={{ getConvertToCurrency }}>
      <ContentWrapper>
        {backButton === true ? (
          <div className="">
            <h5 className="poppins-600 font-18 text-faded-black d-flex align-items-center">
              {t('leases.your_leases')}
            </h5>
          </div>
        ) : (
          <div
            className="d-inline-flex align-items-center"
            role="button"
            tabIndex="0"
            onClick={() => {
              navigate('/lease');
            }}
          >
            <div className="">
              <h5 className="poppins-500 d-flex ">
                <span className="me-1">
                  <ChevronLeft />
                </span>
                {t('leases.your_leases')}
              </h5>
            </div>
          </div>
        )}

        <div className="margin-t-30 leaseDetails">
          <div className="d-flex justify-content-between">
            {/* {!isLoading && !showDetails && (
            <div className="d-flex align-items-center">
              <LeaseStatus leaseStatus={leaseInfo?.leaseStatus} />
              <span className="montserrat-500 text-faded-black margin-l-30">
                {leaseInfo?.property?.name}
              </span>
            </div>
          )} */}
            {seeLeaseButton === true && (
              <div
                role="button"
                tabIndex="0"
                onClick={() => {
                  setSeeLeaseButton(false);
                  setShowDetails(true);
                }}
              >
                <h6 className="d-flex text-faded-black montserrat-600 align-items-center">
                  {t('leases.see_lease')}
                  <span className="me-1">
                    <ChevronRight />
                  </span>
                </h6>
              </div>
            )}
          </div>
          {!isLoading && size(leaseInfo) ? (
            <LeaseDetails />
          ) : (
            <div className="leaseCard padding-40 margin-t-30 card card-with-shadow border-0">
              <LoaderOrEmptyList />
            </div>
          )}
        </div>
      </ContentWrapper>
    </LeaseDetailsContext.Provider>
  );
};

export default Lease;
