import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getAdditionalSessionValue from '@/views/list-your-place/additional-features/helpers/getAdditionalSessionValue';

const AppRadios = ({
  data,
  id = 'default',
  onChange,
  activeValue,
  disabled,
  context,
  languageContext
}) => {
  // check active items
  const { t } = useTranslation();
  const partnerId = id.split('-').includes('AddPartnersRadios') || false;
  const checkActive = data.find((item) => {
    if (item.checked) {
      if (item.checked === true) {
        return item;
      }
    }
    return null;
  });

  const radioLabel = (label) => (languageContext ? t(`${languageContext}.${label}`) : label);

  // set initial active value
  const initialActiveStateValue = () => {
    if (checkActive) {
      return checkActive.label;
    }
    if (activeValue) return activeValue;
    return '';
  };

  const [radio, setRadio] = useState(initialActiveStateValue() || activeValue);

  // radio onchange value handler
  const handleChange = (e) => {
    setRadio(e.target.value);
  };
  // callback trigger when state change
  useEffect(() => {
    if (onChange) {
      onChange(radio, context);
    }
  }, [radio]);

  useEffect(() => {
    if (getAdditionalSessionValue() && getAdditionalSessionValue()?.propertyStatus) {
      setRadio(getAdditionalSessionValue()?.propertyStatus);
    } else {
      setRadio(initialActiveStateValue());
    }
  }, []);

  return (
    <>
      {data &&
        data.map((item) => (
          <label
            key={item.id}
            htmlFor={`${id}-${item.id}`}
            className={`me-3 form-check-label customized-radio radio-default ${
              item.disabled ? 'disabled' : ''
            }`}
          >
            <input
              disabled={activeValue || disabled}
              type="radio"
              id={`${id}-${item.id}`}
              name={`${id}Radio`}
              checked={radio === item.label || activeValue === 'broker'}
              value={item.label}
              onChange={(e) => handleChange(e)}
            />
            <span className="radio-circle" />
            <span className="radio-label">
              {id === 'dashboard-r' || partnerId
                ? radioLabel(item.label)
                : item.lang || radioLabel(item.label)}
            </span>
          </label>
        ))}
    </>
  );
};

export default AppRadios;
