import React from 'react';

const GoogleIcon = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M20.897 9.62343H20.1251V9.58366H11.5001V13.417H16.9161C16.126 15.6485 14.0028 17.2503 11.5001 17.2503C8.32464 17.2503 5.75008 14.6758 5.75008 11.5003C5.75008 8.32489 8.32464 5.75033 11.5001 5.75033C12.9659 5.75033 14.2994 6.30328 15.3147 7.20651L18.0254 4.49587C16.3138 2.90072 14.0243 1.91699 11.5001 1.91699C6.20769 1.91699 1.91675 6.20793 1.91675 11.5003C1.91675 16.7927 6.20769 21.0837 11.5001 21.0837C16.7925 21.0837 21.0834 16.7927 21.0834 11.5003C21.0834 10.8578 21.0173 10.2305 20.897 9.62343Z"
        fill="#FFC107"
      />
      <path
        d="M3.02173 7.03976L6.17033 9.34887C7.02229 7.23958 9.08558 5.75033 11.5001 5.75033C12.9659 5.75033 14.2994 6.30328 15.3147 7.20651L18.0254 4.49587C16.3138 2.90072 14.0244 1.91699 11.5001 1.91699C7.81914 1.91699 4.62694 3.99514 3.02173 7.03976Z"
        fill="#FF3D00"
      />
      <path
        d="M11.5001 21.0832C13.9754 21.0832 16.2246 20.1359 17.9252 18.5953L14.9592 16.0855C13.9647 16.8418 12.7495 17.2508 11.5001 17.2498C9.00744 17.2498 6.89096 15.6604 6.09363 13.4424L2.96851 15.8502C4.55455 18.9538 7.7755 21.0832 11.5001 21.0832Z"
        fill="#4CAF50"
      />
      <path
        d="M20.8969 9.62278H20.125V9.58301H11.5V13.4163H16.916C16.5381 14.4784 15.8572 15.4064 14.9577 16.0858L14.9591 16.0848L17.9251 18.5947C17.7153 18.7854 21.0833 16.2913 21.0833 11.4997C21.0833 10.8571 21.0172 10.2299 20.8969 9.62278Z"
        fill="#1976D2"
      />
    </g>
  </svg>
);

export default GoogleIcon;
