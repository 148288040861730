import React from 'react';
import { useTranslation } from 'react-i18next';

const AppBadge = ({
  textColor,
  pill,
  customClass,
  badgeBtn,
  variant,
  btnVariant,
  label,
  btnLabel,
  btnClass,
  iconLeft,
  iconRight,
  labelType = ''
}) => {
  const { t } = useTranslation();

  const badgeElement = (
    <span
      className={`badge badge-${variant} ${textColor ? `text-${textColor}` : ''} ${
        pill ? 'rounded-pill' : ''
      } ${customClass}`}
    >
      {iconLeft && iconLeft}
      {iconLeft && '\u00A0'}
      {labelType ? t(`label.${label}`) : label}
      {iconRight && iconRight}
    </span>
  );

  return (
    <>
      {badgeBtn ? (
        <button type="button" className={`btn btn-${btnVariant} ${btnClass}`}>
          {btnLabel} {badgeElement}
        </button>
      ) : (
        badgeElement
      )}
    </>
  );
};

export default AppBadge;
