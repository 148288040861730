import React, { useState, useEffect } from 'react';

const AppCheckboxWithoutLabel = ({
  id,
  disabled,
  customClass,
  label,
  labelClass,
  onChange,
  name,
  checkedChange
}) => {
  const [checkbox, setCheckbox] = useState(false);

  const handleChange = () => {
    setCheckbox(!checkbox);
    if (name === 'registerCheckbox') {
      checkedChange(!checkbox);
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(checkbox);
    }
  }, [checkbox]);

  return (
    <div
      className={`customized-checkbox checkbox-default ${
        disabled ? 'disabled' : ''
      } ${customClass}`}
    >
      <input id={`checkbox-${id}`} type="checkbox" checked={checkbox} onChange={handleChange} />
      <label htmlFor={`checkbox-${id}`} className={`${labelClass}`}>
        {label || ''}
      </label>
    </div>
  );
};

export default AppCheckboxWithoutLabel;
