export const DataTableHeader = [
  {
    id: 1,
    title: 'Tenant Profile',
    key: 'profile',
    selectValue: [
      { id: 1, title: 'Completed' },
      { id: 2, title: 'Sent' },
      { id: 3, title: 'Eviction Notice' },
      { id: 4, title: 'Lost' }
    ]
  },
  {
    id: 2,
    title: 'Status',
    key: 'status'
  },
  {
    id: 3,
    title: 'Organization',
    key: 'organization',
    selectValue: [
      { id: 1, title: 'Most Recent' },
      { id: 2, title: 'Ascending Order' },
      { id: 3, title: 'Descending Order' }
    ]
  },
  {
    id: 4,
    title: 'Agent',
    key: 'agent'
  },
  {
    id: 5,
    title: 'Quick Status',
    key: 'quickStatus'
  },
  {
    id: 6,
    title: 'Branch',
    key: 'branch'
  },
  {
    id: 7,
    title: 'Rent Invoice',
    key: 'rent',
    selectValue: [
      { id: 1, title: 'Most Recent' },
      { id: 2, title: 'Commissions Low-High' },
      { id: 3, title: 'Commissions High-Low' }
    ]
  },
  {
    id: 8,
    title: 'Fees',
    key: 'fees'
  },
  {
    id: 9,
    title: 'Payout',
    key: 'payout'
  },
  {
    id: 10,
    title: 'Activity',
    key: 'activity'
  },
  {
    id: 11,
    title: 'Due Date',
    key: 'dueDate',
    selectValue: [
      { id: 1, title: 'Most Recent' },
      { id: 2, title: 'Due Date New-Old' },
      { id: 3, title: 'Due Date Old-New' }
    ]
  },
  {
    id: 12,
    title: 'Action',
    key: 'action'
  }
];

export const DataTableColumn = [
  {
    id: 1,
    profile: {
      name: 'Tenant Name One',
      subName: 'Gulshan-1, Dhaka'
    },
    status: {
      name: 'Estimated'
    },
    organization: {
      name: 'Organization name One'
    },
    agent: {
      name: 'agent name one'
    },
    quickStatus: {
      name: 'Sent'
    },
    branch: {
      name: 'Main Branch one'
    },
    rent: {
      name: 'kr 2134'
    },
    fees: {
      name: 'kr 2134'
    },
    payout: {
      name: 'kr 2134'
    },
    activity: {
      name: 'kr 2134'
    },
    dueDate: {
      name: '12 jan 2021'
    },
    action: {
      name: 'kr 2134'
    }
  },
  {
    id: 2,
    profile: {
      name: 'Tenant Name Two',
      subName: 'Gulshan-2, Dhaka'
    },
    status: {
      name: 'In Progress'
    },
    organization: {
      name: 'Organization name two'
    },
    agent: {
      name: 'agent name two'
    },
    quickStatus: {
      name: 'Eviction Notice'
    },
    branch: {
      name: 'Main Branch two'
    },
    rent: {
      name: 'kr 2134'
    },
    fees: {
      name: 'kr 2134'
    },
    payout: {
      name: 'kr 2134'
    },
    activity: {
      name: 'kr 2134'
    },
    dueDate: {
      name: '12 jan 2022'
    },
    action: {
      name: 'kr 2134'
    }
  },
  {
    id: 3,
    profile: {
      name: 'Tenant Name Three',
      subName: 'Gulshan-3, Dhaka'
    },
    status: {
      name: 'failed'
    },
    organization: {
      name: 'Organization name three'
    },
    agent: {
      name: 'agent name three'
    },
    quickStatus: {
      name: 'Lost'
    },
    branch: {
      name: 'Main Branch three'
    },
    rent: {
      name: 'kr 2134'
    },
    fees: {
      name: 'kr 2134'
    },
    payout: {
      name: 'kr 2134'
    },
    activity: {
      name: 'kr 2134'
    },
    dueDate: {
      name: '12 jan 2023'
    },
    action: {
      name: 'kr 2134'
    }
  },
  {
    id: 4,
    profile: {
      name: 'Tenant Name Four',
      subName: 'Gulshan-3, Dhaka'
    },
    status: {
      name: 'completed'
    },
    organization: {
      name: 'Organization name four'
    },
    agent: {
      name: 'agent name four'
    },
    quickStatus: {
      name: 'Lost'
    },
    branch: {
      name: 'Main Branch four'
    },
    rent: {
      name: 'kr 2134'
    },
    fees: {
      name: 'kr 2134'
    },
    payout: {
      name: 'kr 2134'
    },
    activity: {
      name: 'kr 2134'
    },
    dueDate: {
      name: '12 jan 2024'
    },
    action: {
      name: 'kr 2134'
    }
  },
  {
    id: 5,
    profile: {
      name: 'Tenant Name five',
      subName: 'Gulshan-3, Dhaka'
    },
    status: {
      name: 'failed'
    },
    organization: {
      name: 'Organization name five'
    },
    agent: {
      name: 'agent name five'
    },
    quickStatus: {
      name: 'Sent'
    },
    branch: {
      name: 'Main Branch five'
    },
    rent: {
      name: 'kr 2134'
    },
    fees: {
      name: 'kr 2134'
    },
    payout: {
      name: 'kr 2134'
    },
    activity: {
      name: 'kr 2134'
    },
    dueDate: {
      name: '12 jan 2025'
    },
    action: {
      name: 'kr 2134'
    }
  },
  {
    id: 6,
    profile: {
      name: 'Tenant Name six',
      subName: 'Gulshan-3, Dhaka'
    },
    status: {
      name: 'estimated'
    },
    organization: {
      name: 'Organization name six'
    },
    agent: {
      name: 'agent name six'
    },
    quickStatus: {
      name: 'Completed'
    },
    branch: {
      name: 'Main Branch six'
    },
    rent: {
      name: 'kr 2134'
    },
    fees: {
      name: 'kr 2134'
    },
    payout: {
      name: 'kr 2134'
    },
    activity: {
      name: 'kr 2134'
    },
    dueDate: {
      name: '12 jan 2026'
    },
    action: {
      name: 'kr 2134'
    }
  },
  {
    id: 7,
    profile: {
      name: 'Tenant Name seven',
      subName: 'Gulshan-3, Dhaka'
    },
    status: {
      name: 'in progress'
    },
    organization: {
      name: 'Organization name seven'
    },
    agent: {
      name: 'agent name seven'
    },
    quickStatus: {
      name: 'Eviction Notice'
    },
    branch: {
      name: 'Main Branch seven'
    },
    rent: {
      name: 'kr 2134'
    },
    fees: {
      name: 'kr 2134'
    },
    payout: {
      name: 'kr 2134'
    },
    activity: {
      name: 'kr 2134'
    },
    dueDate: {
      name: '12 jan 2027'
    },
    action: {
      name: 'kr 2134'
    }
  },
  {
    id: 8,
    profile: {
      name: 'Tenant Name eight',
      subName: 'Gulshan-3, Dhaka'
    },
    status: {
      name: 'completed'
    },
    organization: {
      name: 'Organization name eight'
    },
    agent: {
      name: 'agent name eight'
    },
    quickStatus: {
      name: 'Lost'
    },
    branch: {
      name: 'Main Branch eight'
    },
    rent: {
      name: 'kr 2134'
    },
    fees: {
      name: 'kr 2134'
    },
    payout: {
      name: 'kr 2134'
    },
    activity: {
      name: 'kr 2134'
    },
    dueDate: {
      name: '12 jan 2028'
    },
    action: {
      name: 'kr 2134'
    }
  }
];
