import { size } from 'lodash';
import { getAddress } from '@/core/utils';

const preparePayoutDetailsData = (payoutDetailsData) => {
  if (size(payoutDetailsData)) {
    const {
      _id,
      amount,
      serialId,
      holdPayout,
      bankRef,
      status,
      paymentStatus,
      numberOfFails,
      propertyInfo,
      accountInfo,
      agentInfo,
      tenantsInfo,
      branchInfo,
      events,
      metaInfo
    } = payoutDetailsData;
    const rentInvoice = metaInfo?.filter((meta) => meta?.invoiceId) || [];
    const restsInvoices = metaInfo?.filter((meta) => !meta?.invoiceId) || [];
    const getEstimatedDate = events?.find((item) => item?.event === 'estimated') || {};
    const payoutDetailsObject = {
      id: _id,
      amount: amount || 0,
      holdPayout: !!holdPayout,
      serialId: serialId || '',
      bankRef: bankRef || '',
      status: status || '',
      paymentStatus: paymentStatus || '',
      numberOfFails: numberOfFails || 0,
      estimatedDate: getEstimatedDate?.date,
      accountInfo: {
        id: accountInfo?._id || '',
        name: accountInfo?.name || '',
        avatarKey: accountInfo?.avatarKey || ''
      },
      propertyId: propertyInfo?._id || '',
      location: size(propertyInfo?.location) ? getAddress(propertyInfo?.location) : '',
      apartmentId: propertyInfo?.apartmentId || '',
      branchInfo: {
        id: branchInfo?._id || '',
        name: branchInfo?.name || ''
      },
      agentInfo: {
        id: agentInfo?._id || '',
        name: agentInfo?.name || '',
        avatarKey: agentInfo?.avatarKey || ''
      },
      tenantsInfo: tenantsInfo || [],
      events: events || [],
      invoicesSummaryData: [...rentInvoice, ...restsInvoices]
    };
    return payoutDetailsObject;
  }
  return {};
};

export default preparePayoutDetailsData;
