import React, { useEffect, useState } from 'react';

const AppInputGroup = (
  {
    type = 'text',
    placeholder = '',
    id = '',
    customClass = '',
    disabled,
    readOnly,
    onChange,
    name,
    value,
    sideValue,
    context,
    onKeyDown,
    intType
  },
  ref
) => {
  const [state, setState] = useState(value || '');
  const [border, setBorder] = useState(false);

  const handleChange = (e) => {
    setState(e.target.value);
    // onChange(e.target.value, context);
  };

  const preventDecimalPoint = (e) => {
    if (e.key === '.' && intType) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(state, context);
    }
  }, [state]);

  return (
    <div className="input-group">
      <span className={`input-group-text border-end-0 ${border ? 'border-primary' : ''}`}>
        {sideValue}
      </span>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        className={`form-control border-start-0 ${customClass}`}
        ref={ref}
        onChange={(e) => handleChange(e)}
        onKeyDown={onKeyDown}
        value={state}
        disabled={disabled}
        readOnly={readOnly}
        onWheel={(e) => e.target.blur()}
        onFocus={() => setBorder(true)}
        onBlur={() => setBorder(false)}
        onKeyPress={preventDecimalPoint}
      />
    </div>
  );
};

export default React.forwardRef(AppInputGroup);
