import React from 'react';

const Bed = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPth="url(#clip0)">
      <path
        d="M0.833984 14.9998V4.1665"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.166 11.6665L19.166 14.9998"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1673 11.6665H0.833984"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00065 8.33333C5.92113 8.33333 6.66732 7.58714 6.66732 6.66667C6.66732 5.74619 5.92113 5 5.00065 5C4.08018 5 3.33398 5.74619 3.33398 6.66667C3.33398 7.58714 4.08018 8.33333 5.00065 8.33333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1673 8.3335C19.1673 7.41302 18.4211 5.8335 17.5007 5.8335H9.58398C10.5045 5.8335 11.2507 7.41302 11.2507 8.3335"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Bed;
