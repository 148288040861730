import React from 'react';
import { useLocation } from 'react-router-dom';
import SubDomainContext from '@/context/SubDomainContext';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const Layout = ({ children, subDomain }) => {
  const location = useLocation().pathname;

  return (
    <SubDomainContext.Provider
      value={{
        subDomain
      }}
    >
      <div>
        {(() => {
          if (location === '/list-your-place/preview-and-publish') {
            return '';
          }

          if (location.split('/').includes('edit-listing' && 'preview-and-publish')) {
            return '';
          }
          return <Navbar />;
        })()}

        {(() => {
          if (
            location === '/profile' ||
            location === '/my-listing' ||
            location === '/my-listing/draft' ||
            location.includes('lease') ||
            location.includes('inbox')
          ) {
            return <Sidebar subDomain={subDomain} />;
          }

          return '';
        })()}

        {children}
      </div>
    </SubDomainContext.Provider>
  );
};

export default Layout;
