import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { size, orderBy } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { XCircle } from 'react-feather';
import { AppModal, AppSpinner } from '@/components/ui';
import { GET_PAYMENT_HISTORY } from '../graphql/getPaymentHistory.gql';
import { delayCall, toastCall, getFormattedDate } from '@/core/utils';
import { getSelectedCurrency } from '@/core/utils/getPriceByCurrency';
import LeaseDetailsContext from '@/context/LeaseDetailsContext';

const PaymentHistoryModal = ({ contractId, propertyId, tenantId, openModal, userType }) => {
  const { t } = useTranslation();
  const { getConvertToCurrency } = useContext(LeaseDetailsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);

  const updatePaymentInfo = useCallback((data) => {
    if (data) {
      const { getAllInvoicePaymentForALease } = data;
      if (size(getAllInvoicePaymentForALease?.data))
        setPaymentList(orderBy(getAllInvoicePaymentForALease?.data, ['paymentDate'], ['desc']));
      setIsLoading(false);
    }
  }, []);

  const [getPayments] = useLazyQuery(GET_PAYMENT_HISTORY, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      queryData: { contractId, propertyId, tenantId }
    },
    onCompleted: (data) => updatePaymentInfo(data)
  });

  useEffect(() => {
    if (openModal) {
      setIsLoading(true);
      getPayments().then((response) => {
        if (response?.errors) {
          delayCall(() => {
            if (response?.errors[0].statusCode === 404)
              toastCall('danger', t('errors.unauthorized'));
            else toastCall('danger', t('errors.bad_request'));
          });
        }
      });
    }
  }, [openModal]);

  const LoaderOrEmptyList = () => {
    if (isLoading) return <AppSpinner />;
    return t('label.nothing_found');
  };

  const closeBtn = (
    <button type="button" className="btn btn-sub-primary ms-2" data-bs-dismiss="modal">
      <XCircle /> {t('common.close')}
    </button>
  );

  return (
    <AppModal
      id={`payment-history-${contractId}`}
      type="modal-lg"
      options={{
        title: t('leases.payment_history'),
        modalHeader: true,
        modalFooter: true,
        customBtn: closeBtn,
        btnClose: false,
        btnCancel: false
      }}
    >
      <div className="margin-tb-20 allInvoiceModal">
        <table className="table table-striped table-borderless ">
          {!isLoading && paymentList.length ? (
            <>
              <thead className="">
                <tr>
                  <td className="montserrat-500 text-gray font-14 padding-l-30 padding-tb-20">
                    {t('leases.date')}
                  </td>

                  <td className="montserrat-500 text-gray font-14 padding-tb-20">
                    {t('label.amount')}
                  </td>
                  <td className="montserrat-500 text-gray font-14 padding-tb-20">
                    {t('leases.added_to_invoice')}
                  </td>
                </tr>
              </thead>
              <tbody>
                {paymentList?.map((item) => (
                  <tr key={item?._id}>
                    <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                      {getFormattedDate(item?.paymentDate, 'D.M.YYYY')}
                    </td>
                    <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                      {getConvertToCurrency(item?.amount)}
                    </td>
                    <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                      {getSelectedCurrency()} {item?.invoices}
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <span className="padding-40 margin-t-30 border-0 text-center">
              <LoaderOrEmptyList />
            </span>
          )}
        </table>
      </div>
    </AppModal>
  );
};

export default PaymentHistoryModal;
