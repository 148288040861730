const apiRequestForSubdomain = (queryData) => {
  const body = {
    query: `
      query GET_SUB_DOMAIN($queryData: PartnerSubDomainInput) {
        partnersSubDomain(queryData: $queryData) {
            partnerId
            isSubDomainExists
            logo
            defaultFindHomeLocation{
              cityPlaceId
              defaultMapZoom
              defaultMapLocation
              lat
              lng
            }
            currencySettings {
              decimalSeparator
              thousandSeparator
              numberOfDecimal
              currencySymbol
              currencyPosition
            }
            partnerSiteURL
          }
        }`,
    variables: { queryData }
  };
  return body;
};
export default apiRequestForSubdomain;
