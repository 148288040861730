import React from 'react';

const RotateIcon = () => (
  <svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 14H5C3.89543 14 3 14.8954 3 16V30C3 31.1046 3.89543 32 5 32H19C20.1046 32 21 31.1046 21 30V16C21 14.8954 20.1046 14 19 14Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 0L20.41 1.41L17.83 4H23C24.8559 4.00212 26.6351 4.7403 27.9474 6.05259C29.2597 7.36489 29.9979 9.14413 30 11V16H28V11C27.9984 9.67441 27.4711 8.40356 26.5338 7.46622C25.5964 6.52888 24.3256 6.00159 23 6H17.83L20.41 8.59L19 10L14 5L19 0Z"
      fill="#F7F5F2"
    />
  </svg>
);

export default RotateIcon;
