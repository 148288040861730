/* eslint-disable react/jsx-props-no-spreading */
import Cookies from 'js-cookie';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = () => {
    const loggedIn = !!Cookies.get('accessToken');
    try {
      if (loggedIn) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  };

  return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
