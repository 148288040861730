import { Download, Image } from 'react-feather';
import { size } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AppModal, AppSpinner } from '@/components/ui';
import { isImageFile, getFileIcon, getFormattedDate } from '@/core/utils';

const InvoiceFileModal = ({ id, data, handleDownload }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const modal = document.getElementById(id);
  useEffect(() => {
    modal?.addEventListener('shown.bs.modal', () => {
      setLoading(false);
    });

    modal?.addEventListener('hidden.bs.modal', () => {
      setLoading(true);
    });
  }, [data]);

  return (
    <AppModal
      id={id}
      type="modal-lg"
      options={{
        btnApply: false,
        title: t('label.attachments'),
        modalHeader: true,
        modalFooter: true
      }}
    >
      <div className="padding-lr-30">
        <div className="row">
          {(() => {
            if (size(data?.attachments) && !loading) {
              return (
                <div className="col-12">
                  <div className="margin-tb-20" />
                  {data?.attachments?.map((file) => (
                    <div key={file?._id} className="row align-items-center">
                      <div className="col-11">
                        <div className="d-flex align-items-center">
                          <div className="file-upload-blocks d-flex align-items-center justify-content-center">
                            <div
                              className="d-flex align-items-center justify-content-center m-auto"
                              role="button"
                              tabIndex="0"
                              onClick={() => handleDownload(file?._id)}
                            >
                              {isImageFile(file?.name) ? (
                                <div className="bg-primary text-white padding-20 rounded-circle">
                                  <Image />
                                </div>
                              ) : (
                                <div className="bg-primary text-white padding-20 rounded-circle">
                                  <img
                                    style={{ width: '24px', height: '24px' }}
                                    src={getFileIcon(file?.name, 'me')?.icon}
                                    alt="file-logo"
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="margin-l-10">
                            <div
                              className="d-flex align-items-center justify-content-center m-auto"
                              role="button"
                              tabIndex="0"
                              onClick={() => handleDownload(file?._id)}
                            >
                              <h6 className="poppins-500 font-14 text-primary">
                                {file?.title || ''}
                              </h6>
                            </div>
                            <div className="d-flex align-items-center margin-t-5">
                              <p className="poppins-400 font-13 text-gray">
                                {getFormattedDate(file?.createdAt, 'DD.MM.YYYY')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-1">
                        <div
                          className="icon-hover text-grey svg-icon-25"
                          role="button"
                          tabIndex="0"
                          onClick={() => handleDownload(file?._id)}
                        >
                          <Download />
                        </div>
                      </div>

                      <div className="dropdown-divider margin-tb-10" />
                    </div>
                  ))}
                </div>
              );
            }
            if (loading) {
              return (
                <div className="mt-3 mb-3">
                  <AppSpinner />
                </div>
              );
            }
            return false;
          })()}
        </div>
      </div>
    </AppModal>
  );
};

export default InvoiceFileModal;
