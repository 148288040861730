import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { size } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { XCircle } from 'react-feather';
import { AppModal, AppSpinner } from '@/components/ui';
import GET_PAYOUT_HISTORY from '../graphql/getPayoutHistory.gql';
import { delayCall, getFormattedDate, getStatusTypeVariant, toastCall } from '@/core/utils';
import LeaseDetailsContext from '@/context/LeaseDetailsContext';
import PayoutHistoriesModal from './PayoutHistoriesModal';

const PayoutHistoryModal = ({ contractId, propertyId, openModal }) => {
  const { t } = useTranslation();
  const { getConvertToCurrency } = useContext(LeaseDetailsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [payoutsList, setPayoutsList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [payoutId, setPayoutId] = useState('');

  const updateLeaseFilesInfo = useCallback((data) => {
    if (data) {
      const { getFullPayoutHistory } = data;
      if (size(getFullPayoutHistory?.data)) setPayoutsList(getFullPayoutHistory?.data);
      setIsLoading(false);
    }
  }, []);

  const [getFiles] = useLazyQuery(GET_PAYOUT_HISTORY, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      queryData: { contractId, propertyId }
    },
    onCompleted: (data) => updateLeaseFilesInfo(data)
  });

  useEffect(() => {
    if (openModal) {
      setIsLoading(true);
      getFiles().then((response) => {
        if (response?.errors) {
          delayCall(() => {
            if (response?.errors[0].statusCode === 404)
              toastCall('danger', t('errors.unauthorized'));
            else toastCall('danger', t('errors.bad_request'));
          });
        }
      });
    }
  }, [openModal]);

  const LoaderOrEmptyList = () => {
    if (isLoading) return <AppSpinner />;
    return t('label.nothing_found');
  };

  const handlePayoutModal = (_id) => {
    setPayoutId(_id);
    setIsOpenModal(true);
  };

  const closeBtn = (
    <button type="button" className="btn btn-sub-primary ms-2" data-bs-dismiss="modal">
      <XCircle /> {t('common.close')}
    </button>
  );

  return (
    <AppModal
      id={`payout-history-${contractId}`}
      type="modal-lg"
      options={{
        title: t('leases.payout_history'),
        modalHeader: true,
        modalFooter: true,
        customBtn: closeBtn,
        btnClose: false,
        btnCancel: false
      }}
    >
      <div className="margin-tb-30 allInvoiceModal">
        <table className="table table-striped table-borderless ">
          {!isLoading && payoutsList.length ? (
            <>
              <thead className="">
                <tr>
                  <td className="montserrat-500 text-gray font-14 padding-l-30 padding-tb-20">
                    {t('leases.reference')}
                  </td>
                  <td className="montserrat-500 text-gray font-14  padding-tb-20">
                    {t('label.amount')}
                  </td>
                  <td className="montserrat-500 text-gray font-14 padding-tb-20">
                    {t('user_profile.status')}
                  </td>
                  <td className="montserrat-500 text-gray font-14 padding-tb-20">
                    {t('leases.date')}
                  </td>
                  <td className="montserrat-500 text-gray font-14 padding-tb-20" />
                </tr>
              </thead>

              <tbody>
                {payoutsList?.map((payout) => (
                  <tr key={payout._id}>
                    <td className="padding-tb-20 montserrat-500 text-faded-black font-14 padding-l-30">
                      {`#${payout?.referanse}`}
                    </td>
                    <td className="padding-tb-20 montserrat-500 text-faded-black font-14">
                      {getConvertToCurrency(payout?.amount)}
                    </td>
                    <td className="padding-tb-20 montserrat-500 text-faded-black font-14 ">
                      {payout?.status && (
                        <span className={`text-${getStatusTypeVariant(payout?.status)}`}>
                          {t(`label.${payout?.status}`)}
                        </span>
                      )}
                    </td>
                    <td className="padding-tb-20 montserrat-500 text-faded-black font-14 ">
                      {payout?.payoutDate ? getFormattedDate(payout?.payoutDate, 'D.M.YYYY') : ''}
                    </td>
                    <div
                      role="button"
                      tabIndex="0"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      onClick={() => handlePayoutModal(payout?._id)}
                    >
                      <td className="padding-b-20 padding-t-10 montserrat-500 text-sub-primary font-14 ">
                        <p>{t('payout_details.view')}</p>
                      </td>
                    </div>
                  </tr>
                ))}

                <PayoutHistoriesModal
                  id="offcanvasRight"
                  payoutId={payoutId}
                  isOpenModal={isOpenModal}
                  setIsOpenModal={setIsOpenModal}
                />
              </tbody>
            </>
          ) : (
            <span className="padding-40 margin-t-30 border-0 text-center">
              <LoaderOrEmptyList />
            </span>
          )}
        </table>
      </div>
    </AppModal>
  );
};

export default PayoutHistoryModal;
