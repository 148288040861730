import React, { useEffect, useState } from 'react';

const AppTextArea = (
  { id, placeholder, onChange, value = '', customClass = '', rows = '5' },
  ref
) => {
  const [state, setState] = useState(value);

  const handleChange = (e) => {
    setState(e.target.value);
  };

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state, onChange]);

  return (
    <textarea
      id={id}
      value={state}
      placeholder={placeholder}
      className={`form-control ${customClass}`}
      ref={ref}
      onChange={(e) => handleChange(e)}
      rows={rows}
    />
  );
};

export default React.forwardRef(AppTextArea);
