import { pickBy, size } from 'lodash';
import ActionTypes from './ActionTypes';

const {
  ADD_TO_LISTING_QUERY,
  REMOVE_FROM_LISTING_QUERY,
  ADD_TO_EMAIL_SMS_QUERY,
  REMOVE_FROM_EMAIL_SMS_QUERY,
  ADD_TO_USER_QUERY,
  REMOVE_FROM_USER_QUERY,
  ADD_TO_USER_ROLE_QUERY,
  REMOVE_FROM_USER_ROLE_QUERY,
  ADD_TO_PARTNER_QUERY,
  REMOVE_FROM_PARTNER_QUERY,
  ADD_TO_PHONE_NUMBER_QUERY,
  REMOVE_FROM_PHONE_NUMBER_QUERY,
  ADD_TO_INCOMING_PAYMENTS,
  REMOVE_INCOMING_PAYMENTS,
  ADD_TO_PAYOUTS_QUERY,
  REMOVE_FROM_PAYOUTS_QUERY,
  ADD_TO_APP_HEALTH_QUERY,
  REMOVE_FROM_APP_HEALTH_QUERY,
  ADD_TO_TEMPLATE_QUERY,
  REMOVE_FROM_TEMPLATE_QUERY,
  PARTNER_USAGE_QUERY_DATA_TYPE,
  ADD_TO_CHARTS_FILTER_QUERY,
  REMOVE_FROM_CHARTS_FILTER_QUERY
} = ActionTypes;

export default (state, action) => {
  const { type, payload } = action;
  const { reSave = false } = payload || {};
  const {
    listingQueryData,
    userQueryData,
    userRoleQueryData,
    partnerQueryData,
    notificationLogsQueryData,
    phoneNumberQueryData,
    NetReceivedFileDataType,
    payoutsQueryData,
    appHealthQueryData,
    templateQueryData,
    partnerUsageQueryDataType,
    chartsFilterQueryData
  } = state;
  switch (type) {
    default:
      return state;

    case ADD_TO_LISTING_QUERY:
      return {
        ...state,
        listingQueryData: reSave ? { ...payload } : { ...listingQueryData, ...payload }
      };

    case REMOVE_FROM_LISTING_QUERY:
      return {
        ...state,
        listingQueryData:
          size(listingQueryData) === 1
            ? {}
            : pickBy(listingQueryData, (value, key) => key !== payload)
      };
    case ADD_TO_EMAIL_SMS_QUERY:
      return {
        ...state,
        notificationLogsQueryData: { ...notificationLogsQueryData, ...payload }
      };

    case REMOVE_FROM_EMAIL_SMS_QUERY:
      return {
        ...state,
        notificationLogsQueryData:
          size(notificationLogsQueryData) === 1
            ? {}
            : pickBy(notificationLogsQueryData, (value, key) => key !== payload)
      };
    case ADD_TO_USER_QUERY:
      return {
        ...state,
        userQueryData: { ...userQueryData, ...payload }
      };

    case REMOVE_FROM_USER_QUERY:
      return {
        ...state,
        userQueryData:
          size(userQueryData) === 1 ? {} : pickBy(userQueryData, (value, key) => key !== payload)
      };

    case ADD_TO_USER_ROLE_QUERY:
      return {
        ...state,
        userRoleQueryData: { ...userRoleQueryData, ...payload }
      };

    case REMOVE_FROM_USER_ROLE_QUERY:
      return {
        ...state,
        userRoleQueryData:
          size(userRoleQueryData) === 1
            ? {}
            : pickBy(userRoleQueryData, (value, key) => key !== payload)
      };

    case ADD_TO_PARTNER_QUERY:
      return {
        ...state,
        partnerQueryData: { ...partnerQueryData, ...payload }
      };

    case REMOVE_FROM_PARTNER_QUERY:
      return {
        ...state,
        partnerQueryData:
          size(partnerQueryData) === 1
            ? {}
            : pickBy(partnerQueryData, (value, key) => key !== payload)
      };

    case ADD_TO_PHONE_NUMBER_QUERY:
      return {
        ...state,
        phoneNumberQueryData: { ...phoneNumberQueryData, ...payload }
      };

    case REMOVE_FROM_PHONE_NUMBER_QUERY:
      return {
        ...state,
        phoneNumberQueryData:
          size(phoneNumberQueryData) === 1
            ? {}
            : pickBy(phoneNumberQueryData, (value, key) => key !== payload)
      };

    case ADD_TO_INCOMING_PAYMENTS:
      return {
        ...state,
        NetReceivedFileDataType: { ...NetReceivedFileDataType, ...payload }
      };

    case REMOVE_INCOMING_PAYMENTS:
      return {
        ...state,
        NetReceivedFileDataType:
          size(NetReceivedFileDataType) === 1
            ? {}
            : pickBy(NetReceivedFileDataType, (value, key) => key !== payload)
      };
    case ADD_TO_PAYOUTS_QUERY:
      return {
        ...state,
        payoutsQueryData: { ...payoutsQueryData, ...payload }
      };

    case REMOVE_FROM_PAYOUTS_QUERY:
      return {
        ...state,
        payoutsQueryData:
          size(payoutsQueryData) === 1
            ? {}
            : pickBy(payoutsQueryData, (value, key) => key !== payload)
      };
    case ADD_TO_APP_HEALTH_QUERY:
      return {
        ...state,
        appHealthQueryData: { ...appHealthQueryData, ...payload }
      };

    case REMOVE_FROM_APP_HEALTH_QUERY:
      return {
        ...state,
        appHealthQueryData:
          size(appHealthQueryData) === 1
            ? {}
            : pickBy(appHealthQueryData, (value, key) => key !== payload)
      };

    case ADD_TO_TEMPLATE_QUERY:
      return {
        ...state,
        templateQueryData: { ...templateQueryData, ...payload }
      };

    case REMOVE_FROM_TEMPLATE_QUERY:
      return {
        ...state,
        templateQueryData:
          size(templateQueryData) === 1
            ? {}
            : pickBy(templateQueryData, (value, key) => key !== payload)
      };

    case PARTNER_USAGE_QUERY_DATA_TYPE: {
      return {
        ...state,
        partnerUsageQueryDataType: { ...partnerUsageQueryDataType, ...payload }
      };
    }

    case ADD_TO_CHARTS_FILTER_QUERY: {
      return {
        ...state,
        chartsFilterQueryData: { ...chartsFilterQueryData, ...payload }
      };
    }

    case REMOVE_FROM_CHARTS_FILTER_QUERY:
      return {
        ...state,
        chartsFilterQueryData:
          size(chartsFilterQueryData) === 1
            ? {}
            : pickBy(chartsFilterQueryData, (value, key) => key !== payload)
      };
  }
};
