import Cookies from 'js-cookie';

const isAuthenticated = () => {
  try {
    return !!Cookies.get('accessToken');
  } catch (error) {
    return false;
  }
};
export default isAuthenticated;
