/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useCallback, useEffect, useState, useMemo } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { useDropzone } from 'react-dropzone';
import { Image, XCircle, Menu } from 'react-feather';
import { AppButton, AppInput, ReplaceImageIcon, RotateIcon } from '..';

const AppDropzoneWithSidePreview = forwardRef(
  ({ accept, maxFiles, disabled, maxSize, multiple, minSize, onChange }, forwardedRef) => {
    const [files, setFiles] = useState([]);
    // const [acceptFile, setAcceptFile] = useState(false);
    // const [reject, setReject] = useState(false);
    const onDrop = useCallback(
      (acceptedFiles) => {
        const newFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
        setFiles([...files, ...newFiles]);
        // setAcceptFile(false);
      },
      [files]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
      onDrop,
      accept,
      maxFiles,
      disabled,
      maxSize,
      multiple,
      minSize
    });

    const handleClick = (index) => {
      const temp = [...files];
      temp.splice(index, 1);
      setFiles(temp);
    };

    const handleDelete = (index) => {
      const temp = [...files];
      temp.splice(index, 1);
      setFiles(temp);
    };

    const thumbs = files.map((file, index) => (
      <div className="img-preview" key={file.name}>
        <div className="img-preview-inner">
          <img src={file.preview} className="img-fluid my-auto mx-auto" alt="not-found" />
        </div>
        <div className="dz-img-overlay">
          <p>{file.name}</p>
          <p>{file.size} bytes</p>
          <span
            className="text-primary"
            role="button"
            tabIndex="0"
            onClick={() => handleDelete(index)}
          >
            Remove
          </span>
        </div>
      </div>
    ));

    useEffect(() => {
      if (onChange) {
        onChange(files);
      }
    }, [files, onChange]);
    return (
      <div
        ref={forwardedRef}
        className="d-flex flex-wrap align-items-center justify-content-start appDropzoneWithSidePreview"
      >
        <div className="custom-image-upload-wrapper d-flex justify-content-start flex-wrap align-items-center ">
          {files?.map((file, index) => (
            <>
              <div className=" margin-r-20 mb-2">
                <div className="img-preview " style={{ width: '18rem', height: '175px' }}>
                  <div
                    className="img-preview-inner default-radius"
                    style={{ width: '18rem', height: '175px' }}
                  >
                    <img className="w-100" src={file.preview} alt="" />
                  </div>
                  <div
                    className="bg-white dropzone-hover-icon text-warning  "
                    role="button"
                    tabIndex="0"
                    onClick={() => handleClick(index)}
                  >
                    <XCircle />
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
        <div
          className={`dropzone dropzone2 height-175 width-18 mb-2 ${
            isDragAccept ? 'acceptDropzone2' : 'dropzone2'
          } ${isDragReject ? 'rejectDropzone2' : 'dropzone2'}`}
          {...getRootProps(isDragActive, isDragAccept, isDragReject)}
        >
          <div className="dz-default dz-message">
            <input {...getInputProps()} />
            <AppButton btnClass="btn-light" label="Choose from file" icon={<Image />} />
            <h6 className="text-center text-gray mt-3">Or drag & drop here</h6>
          </div>
        </div>
      </div>
    );
  }
);

export default AppDropzoneWithSidePreview;
