import React from 'react';

const LandlordIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 22V2H5H9V13.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 5L5.5 5M4.5 8.5L5.5 8.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 13L5.5 13"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 17L5.5 17"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 22V20C23 18.9391 22.5786 17.9217 21.8284 17.1716C21.0783 16.4214 20.0609 16 19 16H13C11.9391 16 10.9217 16.4214 10.1716 17.1716C9.42143 17.9217 9 18.9391 9 20V22"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 12C17.933 12 19.5 10.433 19.5 8.5C19.5 6.567 17.933 5 16 5C14.067 5 12.5 6.567 12.5 8.5C12.5 10.433 14.067 12 16 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LandlordIcon;
