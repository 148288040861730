import React from 'react';

const KroneInvert = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5179_40073)">
      <path
        d="M13.4532 1.33331H2.54415C1.87472 1.33331 1.33203 2.07951 1.33203 2.99998V13C1.33203 13.9205 1.87472 14.6666 2.54415 14.6666H13.4532C14.1227 14.6666 14.6654 13.9205 14.6654 13V2.99998C14.6654 2.07951 14.1227 1.33331 13.4532 1.33331Z"
        fill="#currentColor"
        stroke="#currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8.66669C10 7.56212 10.597 6.66669 11.3333 6.66669"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66797 10.6667L4.66797 4.66669"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.6667L10 6.66669"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.6667L5.33333 8.33335L8 6.66669"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5179_40073">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default KroneInvert;
