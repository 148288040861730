import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AgentSigning from './AgentSigning';
import LandlordSigning from './LandlordSigning';
import LeaseFinalProcess from './LeaseFinalProcess';
import { canAccessLeasePage } from '../../../core/utils';
import AppContext from '@/context/app-context/Context';
import LeaseStatus from '@/views/lease/components/LeaseStatus';
import LeaseOverview from '../LeaseOverview';

const LandlordLeaseProcess = ({ leaseInfo = {}, setBackButton, setSeeLeaseButton }) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { userTypes } = appContext;

  useEffect(() => {
    if (!canAccessLeasePage(userTypes)) return navigate('/');
    return true;
  }, []);

  const LandlordLeaseProcessStep = () => {
    let currentStep = '';
    if (!leaseInfo?.agentAssignmentSigningStatus?.signed) {
      currentStep = (
        <div>
          <AgentSigning agent={leaseInfo?.agent} />
        </div>
      );
    }
    if (leaseInfo?.agentAssignmentSigningStatus?.signed) {
      currentStep = (
        <div>
          <LandlordSigning
            agent={leaseInfo?.agent}
            signingUrl={leaseInfo?.landlordAssignmentSigningStatus?.signingUrl}
          />
        </div>
      );
    }
    if (
      leaseInfo?.agentAssignmentSigningStatus?.signed &&
      leaseInfo?.landlordAssignmentSigningStatus?.signed
    ) {
      currentStep = (
        <div>
          <LeaseFinalProcess agent={leaseInfo?.agent} />
        </div>
      );
    }

    if (!currentStep)
      return (
        <LeaseOverview
          setSeeLeaseButton={setSeeLeaseButton}
          setBackButton={setBackButton}
          leaseInfo={leaseInfo}
        />
      );
    return currentStep;
  };

  return (
    <div className="landlordLeaseProcess">
      <div className="margin-b-15 d-flex align-items-center">
        <LeaseStatus leaseStatus={leaseInfo?.leaseStatus} />
        <h6 className="montserrat-500 font-16 text-faded-black margin-l-30">
          {leaseInfo?.property?.name}
        </h6>
      </div>
      <LandlordLeaseProcessStep />
    </div>
  );
};

export default LandlordLeaseProcess;
