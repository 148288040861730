import React from 'react';
import { MessageCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { AppAvatar, AppButton } from '@/components/ui';
import useNewConversation from '@/views/inbox/hooks/useNewConversation';
import { translateLanguage } from '@/core/utils';

const Agent = ({
  agent,
  listingId,
  cardClass = 'card card-with-shadow border-0 padding-20 h-100'
}) => {
  const { t } = useTranslation();
  const { createConversation, loading } = useNewConversation();
  const createNewMessage = () => createConversation({ listingId });

  return (
    <div className="col-md-4 col-xl-4 col-xxl-4 col-xxxl-3">
      <div className={cardClass}>
        <div className=" padding-30 bg-base h-100">
          <h6 className="montserrat-400 font-14 text-faded-black">{t('label.agent')}</h6>
          <div className="d-flex align-items-center margin-t-20">
            <div>
              <AppAvatar img={agent?.img || ''} width={60} />
            </div>

            <div className="">
              <h6 className="montserrat-500 font-16 text-faded-black line-height-22 margin-l-10">
                {agent?.name}
              </h6>
              <h6 className="montserrat-500 font-12 text-faded-black margin-l-10">
                {agent?.partner}
              </h6>
            </div>
          </div>

          {/* <div className="d-flex justify-content-center margin-t-30">
            <button
              id="create-message-and-redirect"
              type="button"
              className="btn btn-sub-primary"
              onClick={createNewMessage}
              disabled={loading}
            >
              <MessageCircle /> Send Message
            </button>
          </div> */}
        </div>
        <AppButton
          btnClass="btn btn-sub-primary margin-tb-20"
          icon={<MessageCircle />}
          label={t('label.send_message')}
          handleClick={createNewMessage}
          disabled={loading}
          block
        />
      </div>
    </div>
  );
};
export default Agent;
