import { Home, Inbox, Star, Edit3 } from 'react-feather';

const PublicSiteSidebarData = [
  {
    key: 'leases',
    title: 'leases',
    path: '/lease',
    icon: <Home />
  },
  {
    key: 'inbox',
    title: 'inbox',
    path: '/inbox',
    icon: <Inbox />
  },

  {
    key: 'myListing',
    title: 'my_listings',
    path: '/my-listing',
    icon: <Star />
  },

  {
    key: 'profile',
    title: 'profile',
    path: '/profile',
    icon: <Edit3 />
  }
];

export default PublicSiteSidebarData;
