import React from 'react';

const LeavingRoom = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 6.66683L2.5 5.00016C2.5 4.07969 3.24619 3.3335 4.16667 3.3335L8.33333 3.3335C9.25381 3.3335 10 4.07969 10 5.00016L10 9.16683"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 5.00016C10 4.07969 10.7462 3.3335 11.6667 3.3335L15.8333 3.3335C16.7538 3.3335 17.5 4.07969 17.5 5.00016L17.5 6.66683"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1673 10.8332C19.1673 9.9127 18.4211 9.1665 17.5006 9.1665H16.6673L15.834 11.6665H4.16732L3.33398 9.1665H2.50065C1.58018 9.1665 0.833984 9.9127 0.833984 10.8332V13.3332C0.833984 14.2536 1.58018 14.9998 2.50065 14.9998H17.5006C18.4211 14.9998 19.1673 14.2536 19.1673 13.3332V10.8332Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.3418 16.6668L3.34181 15.8335"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 16.6668L17.5 15.8335"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LeavingRoom;
