import React, { useEffect, useState } from 'react';
import { File, Plus, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { size } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { AppModal, AppSpinner } from '@/components/ui';
import { GET_LEASE_FILES } from '../graphql/getLeaseFile.gql';
import { delayCall, toastCall } from '@/core/utils';
import AddFilesModal from '@/views/lease/modals/AddFilesModal.js';
import openFileUsingFileId from '@/views/lease/helpers/openFileUsingFileId';

const AllFilesModal = ({ id, contractId, leaseInfo, openModal, setOpenModal }) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(true);
  const [leaseFilesList, setLeaseFilesList] = useState([]);
  const [openAddFileModal, setOpenAddFileModal] = useState(false); // for clear file data

  const updateLeaseFilesInfo = (data) => {
    if (size(data?.getFilesForPublicApp)) {
      const { getFilesForPublicApp } = data;
      if (size(getFilesForPublicApp?.data)) setLeaseFilesList(getFilesForPublicApp?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getFiles = async () => {
    const { data, errors } = await client.query({
      query: GET_LEASE_FILES,
      variables: {
        queryData: { contractId }
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    });

    if (size(data)) {
      updateLeaseFilesInfo(data);
    }
    if (errors) {
      delayCall(() => {
        if (errors[0].statusCode === 404) toastCall('danger', t('errors.unauthorized'));
        else toastCall('danger', t('errors.bad_request'));
      });
    }
  };

  const handleNewFiles = (files) => {
    setLeaseFilesList((oldFiles) => [...files, ...oldFiles]);
  };

  const handleOpenLeaseFile = async (fileId) => openFileUsingFileId(fileId);

  const modalId = document.getElementById(id);
  useEffect(() => {
    if (openModal) {
      getFiles();
    }
    modalId?.addEventListener('hidden.bs.modal', () => {
      setOpenModal((prevData) => ({ ...prevData, files: false }));
      setIsLoading(true);
      setLeaseFilesList([]);
    });
  }, [openModal, contractId]);

  const LoaderOrEmptyList = () => {
    if (isLoading) return <AppSpinner />;
    return t('label.nothing_found');
  };

  const closeBtn = (
    <button type="button" className="btn btn-sub-primary ms-2" data-bs-dismiss="modal">
      <XCircle /> {t('common.close')}
    </button>
  );

  return (
    <>
      <AppModal
        id={id}
        type="modal-lg"
        options={{
          title: t('leases.documents'),
          modalHeader: true,
          modalFooter: true,
          customBtn: closeBtn,
          btnClose: false,
          btnCancel: false
        }}
      >
        <div className="allFilesModal">
          <div className="d-flex align-items-center justify-content-end margin-t-30 padding-r-30  ">
            <div
              className="add-file"
              role="button"
              tabIndex="0"
              onClick={() => setOpenAddFileModal(true)}
              data-bs-toggle="modal"
              data-bs-target={`#add-lease-file-${leaseInfo?.id}`}
            >
              <div className="d-flex align-items-center  cursor-pointer">
                <div className="svg-icon-20 text-sub-primary">
                  <Plus />
                </div>
                <h6 className="montserrat-700 font-14 text-sub-primary text-end">
                  {t('leases.add_file')}
                </h6>
              </div>
            </div>
          </div>
          <div className="margin-t-10 margin-b-30 ">
            <table className="table table-striped table-borderless ">
              {!isLoading && leaseFilesList.length ? (
                <>
                  <thead className="">
                    <tr>
                      <td className="montserrat-500 text-gray font-14 padding-l-30 padding-tb-20">
                        {t('my_listings.name')}
                      </td>
                      <td className="montserrat-500 text-gray text-end font-14 padding-tb-20 padding-r-30">
                        {t('leases.uploaded')}
                      </td>
                    </tr>
                  </thead>

                  <tbody className="">
                    {leaseFilesList.map((file) => (
                      <tr className="" key={file._id ? file._id : file.name}>
                        <td className="padding-tb-20 montserrat-500 text-gray font-14 padding-l-30">
                          <div
                            onClick={() => handleOpenLeaseFile(file._id ? file._id : file.name)}
                            role="button"
                            tabIndex={0}
                            className="d-flex align-items-center"
                          >
                            <div className="margin-r-10 svg-icon-17">
                              <File />
                            </div>
                            <h6 className="file-name">{file.title}</h6>
                          </div>
                        </td>
                        <td className="padding-tb-20 montserrat-500 text-faded-black text-end font-14 padding-r-30">
                          {file.uploadedAt}
                          {/* {getFormattedDate(file.uploadedAt, 'D.M.YYYY')} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              ) : (
                <span className="padding-40 margin-t-30 border-0 text-center">
                  <LoaderOrEmptyList />
                </span>
              )}
            </table>
          </div>
        </div>
      </AppModal>
      <AddFilesModal
        id={`add-lease-file-${leaseInfo?.id}`}
        leaseInfo={leaseInfo}
        handleNewFiles={handleNewFiles}
        openAddFileModal={openAddFileModal}
        setOpenAddFileModal={setOpenAddFileModal}
      />
    </>
  );
};

export default AllFilesModal;
