/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { size } from 'lodash';
import AppContext from '@/context/app-context/Context';

const AppCheckbox = ({ data, callback, id, context, customClass = '' }) => {
  const appContext = useContext(AppContext);
  const [isChecked, setIsChecked] = useState(
    localStorage.getItem('searchAsIMove') ? JSON.parse(localStorage.getItem('searchAsIMove')) : true
  );
  const { isClear } = appContext;
  const { t } = useTranslation();
  // state
  const [checkbox, setCheckbox] = useState(data);
  // Change Checkbox State
  const handleChange = (label) => {
    setIsChecked(!isChecked);
    const copy = [...checkbox];
    const updateState = copy.map((item) => {
      if (label === item.label) {
        // eslint-disable-next-line no-param-reassign
        item.checked = !item.checked;
      }

      return item;
    });

    setCheckbox(updateState);
  };
  useEffect(() => {
    if (isClear) {
      const copy = [...checkbox];
      const clearCheckItems = copy.map((item) => {
        const items = item;
        items.checked = false;
        return items;
      });
      setCheckbox(clearCheckItems);
    }
  }, [isClear]);

  // callback
  useEffect(() => {
    const checkedItem = checkbox.filter((item) => item.checked === isChecked);
    localStorage.setItem('searchAsIMove', JSON.stringify(isChecked));
    if (callback) return callback(isChecked);
  }, [isChecked]);

  return (
    <>
      <div>
        {checkbox &&
          checkbox.map((item, index) => (
            <div key={item.id}>
              <div>
                <CheckBox
                  item={item}
                  context={context}
                  handleChange={handleChange}
                  id={id}
                  t={t}
                  key={index + 1}
                  disabledClass={`${item.disabled ? 'disabled' : ''}`}
                  customClass={customClass}
                  isChecked={isChecked}
                />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

// checkbox
const CheckBox = ({
  item,
  handleChange,
  context,
  t,
  id,
  disabledClass,
  customClass,
  isChecked
}) => (
  <div className={`customized-checkbox checkbox-default ${disabledClass} ${customClass}`}>
    <input
      id={`${id}-checkbox-${item.id}`}
      type="checkbox"
      checked={isChecked}
      onChange={() => handleChange(item.label)}
    />
    <label htmlFor={`${id}-checkbox-${item.id}`} className="form-check-label black-text-color">
      {context ? t(`${context}.${item.label}`) : item.label}
    </label>
  </div>
);

export default AppCheckbox;
