import React from 'react';

const WatchIcon = () => (
  <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7697_47909)">
      <path
        d="M33.9375 29.4375V38.8125L38.625 43.5"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1562 15.3438L23.25 7.0625C23.375 6 24.0937 5 25.25 4.25C26.4062 3.53125 27.9062 3.125 29.4687 3.125H43.0625C44.625 3.125 46.125 3.53125 47.2812 4.25C48.4375 4.96875 49.1562 5.96875 49.3125 7.0625L50.4062 15.3438"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.4062 59.5L49.3125 67.6875C49.1562 68.75 48.4375 69.75 47.2812 70.4687C46.125 71.1875 44.625 71.5937 43.0625 71.5937H29.4687C27.9062 71.5937 26.4062 71.1875 25.25 70.4687C24.0937 69.75 23.375 68.75 23.25 67.6875L22.1562 59.5"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.8125 15.3438H18.7188C15.2842 15.3438 12.5 18.128 12.5 21.5625V53.2813C12.5 56.7158 15.2842 59.5 18.7188 59.5H53.8125C57.247 59.5 60.0312 56.7158 60.0312 53.2813V21.5625C60.0312 18.128 57.247 15.3438 53.8125 15.3438Z"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7697_47909">
        <rect width="53.4375" height="75" fill="white" transform="translate(9.375)" />
      </clipPath>
    </defs>
  </svg>
);

export default WatchIcon;
