import { isOnlyPartnerTenant, isOnlyPartnerLandlord } from '@/helpers/permissions';

const canAccessLeasePage = (roles) => {
  let dashboardType = '';
  const isOnlyLandlord = isOnlyPartnerLandlord(roles);
  const isOnlyTenant = isOnlyPartnerTenant(roles);

  if (!dashboardType && isOnlyLandlord) dashboardType = 'landlord';
  else if (!dashboardType && isOnlyTenant) dashboardType = 'tenant';

  if (dashboardType) return true;
  return false;
};
export default canAccessLeasePage;
