import { size } from 'lodash';
import format from 'accounting-js';

export const getCurrencyOptions = (partnerSettings, showSymbol, options, number) => {
  let symbolFormat = '';
  let precision = 0;

  if (partnerSettings && partnerSettings.currencySettings) {
    if (partnerSettings.currencySettings.currencyPosition === 'left') symbolFormat = '%s%v';
    else if (partnerSettings.currencySettings.currencyPosition === 'right') symbolFormat = '%v%s';
    else if (partnerSettings.currencySettings.currencyPosition === 'left_with_space')
      symbolFormat = '%s %v';
    else if (partnerSettings.currencySettings.currencyPosition === 'right_with_space')
      symbolFormat = '%v %s';
    else symbolFormat = '%s%v';

    let thousandOptions =
      partnerSettings.currencySettings.thousandSeparator === 'space'
        ? ' '
        : partnerSettings.currencySettings.thousandSeparator;

    // If is not thousand separator needed
    if (options && options.isNotThousand) thousandOptions = '';

    // for input related values, we'll show the decimals as inputed by user
    if (number && options && (options.inputMode === true || options.inputValue === true)) {
      precision = (number.toString().split('.')[1] || []).length;

      // if there are no decimal inputed by user, then follow the settings
      if (options.inputValue && !precision) {
        precision = partnerSettings.currencySettings.numberOfDecimal;
      }
    } else {
      precision = partnerSettings.currencySettings.numberOfDecimal;
    }

    if (options && options.isInvoice) {
      precision = 2; // show 2 decimal for invoice
      // eslint-disable-next-line no-param-reassign
      showSymbol = false;
    }

    return {
      symbol: showSymbol ? partnerSettings.currencySettings.currencySymbol : '',
      precision,
      thousand: thousandOptions,
      decimal: partnerSettings.currencySettings.decimalSeparator,
      format: symbolFormat
    };
  }
  return null;
};

export const convertToCurrency = (number, settings = {}, showSymbol = true, options = {}) => {
  const partnerSettings = size(settings) ? { currencySettings: settings } : {};

  const currencyOptions = getCurrencyOptions(partnerSettings, showSymbol, options, number);

  if (number && currencyOptions) {
    if (currencyOptions.precision) {
      return format.formatMoney(number, currencyOptions);
    }
    // don't show '-0', when settings precision is 0
    if (number < 1 && number > -1) {
      currencyOptions.precision = 2;
    }
    return format.formatMoney(number, currencyOptions);
  }
  if (number) {
    return Math.round(number);
  }
  return 0;
};
