/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/plugins/char_counter.min.css';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/js/third_party/image_tui.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

function getConfig() {
  return {
    theme: 'custom',
    // charCounterCount: true,
    // charCounterMax: 20,
    fileAllowedTypes: ['image/png', 'image/gif', 'image/jpeg'],
    fileInsertButtons: [],
    fileUpload: false,
    fontFamilySelection: false,
    fontSize: [
      '6',
      '8',
      '9',
      '10',
      '11',
      '12',
      '14',
      '18',
      '21',
      '24',
      '30',
      '36',
      '48',
      '60',
      '72',
      '96'
    ],
    fontSizeDefaultSelection: '16',
    fontSizeSelection: true,
    imagePaste: true,
    editorClass: 'froala-wrapper',
    fullPage: false,
    htmlAllowComments: false,
    htmlAllowedAttrs: [
      'accept',
      'accept-charset',
      'accesskey',
      'action',
      'align',
      'allowfullscreen',
      'allowtransparency',
      'alt',
      'async',
      'autocomplete',
      'autofocus',
      'background',
      'bgcolor',
      'border',
      'charset',
      'cellpadding',
      'cellspacing',
      'checked',
      'cite',
      'class',
      'color',
      'cols',
      'colspan',
      'content',
      'contextmenu',
      'controls',
      'coords',
      'data',
      'data-.*',
      'datetime',
      'default',
      'defer',
      'dir',
      'dirname',
      'disabled',
      'download',
      'enctype',
      'for',
      'form',
      'formaction',
      'frameborder',
      'headers',
      'height',
      'hidden',
      'high',
      'href',
      'hreflang',
      'icon',
      'ismap',
      'itemprop',
      'keytype',
      'kind',
      'label',
      'lang',
      'language',
      'list',
      'loop',
      'low',
      'max',
      'maxlength',
      'media',
      'method',
      'min',
      'mozallowfullscreen',
      'multiple',
      'muted',
      'name',
      'novalidate',
      'open',
      'optimum',
      'pattern',
      'ping',
      'placeholder',
      'playsinline',
      'poster',
      'preload',
      'pubdate',
      'radiogroup',
      'readonly',
      'rel',
      'required',
      'reversed',
      'rows',
      'rowspan',
      'sandbox',
      'scope',
      'scoped',
      'scrolling',
      'seamless',
      'selected',
      'shape',
      'size',
      'sizes',
      'span',
      'start',
      'src',
      'step',
      'summary',
      'spellcheck',
      'style',
      'tabindex',
      'target',
      'title',
      'type',
      'translate',
      'usemap',
      'value',
      'valign',
      'webkitallowfullscreen',
      'width',
      'wrap'
    ],
    htmlAllowedTags: [
      'a',
      'abbr',
      'address',
      'area',
      'article',
      'aside',
      'b',
      'base',
      'bdi',
      'bdo',
      'blockquote',
      'br',
      'caption',
      'cite',
      'code',
      'col',
      'colgroup',
      'datalist',
      'dd',
      'del',
      'details',
      'dfn',
      'div',
      'dl',
      'dt',
      'em',
      'fieldset',
      'footer',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'header',
      'hgroup',
      'hr',
      'i',
      'ins',
      'kbd',
      'label',
      'legend',
      'li',
      'main',
      'map',
      'mark',
      'menu',
      'menuitem',
      'meter',
      'nav',
      'ol',
      'output',
      'p',
      'param',
      'pre',
      'progress',
      'queue',
      'rp',
      'rt',
      's',
      'samp',
      'section',
      'small',
      'span',
      'strike',
      'strong',
      'sub',
      'summary',
      'sup',
      'table',
      'tbody',
      'td',
      'tfoot',
      'th',
      'thead',
      'time',
      'title',
      'tr',
      'track',
      'u',
      'ul',
      'var',
      'wbr',
      'img'
    ],
    htmlExecuteScripts: false,
    placeholderText: 'Write something here…',
    shortcutsEnabled: [
      'show',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'outdent',
      'undo',
      'redo',
      'createLink'
    ],
    linkAttributes: {},
    linkList: [],
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    linkInsertButtons: [],
    linkStyles: {},
    linkMultipleStyles: false,
    linkText: true,
    tableCellMultipleStyles: false,
    tableCellStyles: null,
    tableColors: [],
    tableEditButtons: [
      'tableHeader',
      'tableRemove',
      '|',
      'tableRows',
      'tableColumns',
      '-',
      'tableCells',
      'tableCellVerticalAlign',
      'tableCellHorizontalAlign'
    ],
    paragraphFormat: {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      PRE: 'Preformatted'
    },
    listAdvancedTypes: false,
    attribution: false,
    pluginsEnabled: [
      'align',
      'fontSize',
      'link',
      'lists',
      'image',
      'paragraphFormat',
      'quote',
      'table',
      'url',
      'fullscreen',
      'colors',
      'charCounter'
    ],
    toolbarButtons: {
      moreText: {
        buttons: [
          'paragraphFormat',
          // "bold",
          // "italic",
          'underline',
          'fontSize',
          'clearFormatting',
          'strikeThrough',
          'subscript',
          'superscript',
          'textColor',
          'backgroundColor'
        ],
        buttonsVisible: 6
      },
      moreParagraph: {
        buttons: [
          'alignLeft',
          'alignCenter',
          'alignRight',
          'alignJustify',
          'formatOL',
          'formatUL',
          'indent',
          'outdent',
          'quote'
        ],
        buttonsVisible: 11
      },
      moreRich: { buttons: ['insertLink', 'insertImage', 'insertTable'] },
      moreMisc: { buttons: ['undo', 'redo', 'fullscreen'] },
      showMoreButtons: true
    },
    height: 420,
    key: 'uXD2lB6D5B4B4iAa1Tb1YZNYAh1CUKUEQOHFVANUqD1G1F4I4B1C8C7D2C4B4=='
  };
}

const AppTextEditor = () => (
  <>
    <FroalaEditor
      model={`<p>fasdfsadfasdfasdfasdf</p><p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>`}
      onModelChange={console.log}
      config={getConfig()}
    />
  </>
);

export default AppTextEditor;
