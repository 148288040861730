import React from 'react';
import { Paperclip } from 'react-feather';
import { size } from 'lodash';
import { AppBadge } from '@/components/ui';

const FileList = ({
  callBack,
  number = 0,
  modalId = '',
  targetId,
  customClass,
  dynamicData,
  icon = <Paperclip />,
  handleDownload
}) => (
  <div className="d-flex align-items-center margin-t-5}">
    {dynamicData?.slice(0, number)?.map((item) => (
      <div key={item?._id}>
        <div className={`d-flex align-items-center me-2 padding-tb-5 ${customClass}`}>
          <div className="icon-hover text-sub-primary app-hover-tooltip-modal align-items-center me-1">
            <div className="tooltip-msg-modal font-14">{item?.title}</div>
            <span
              role="button"
              tabIndex="0"
              onClick={() => handleDownload(item?._id)}
              className="text-sub-primary"
            >
              {icon}
            </span>
          </div>
        </div>
      </div>
    ))}

    {size(dynamicData) > number ? (
      <div
        className="cursor-pointer"
        data-bs-dismiss={modalId}
        data-bs-toggle="modal"
        data-bs-target={targetId}
        onClick={callBack}
        data-bs-backdrop="true"
        role="button"
        tabIndex={0}
      >
        <AppBadge
          customClass="icon-hover text-primary font-14 font-weight-500"
          label={` +${dynamicData?.length - number} `}
          labelType
        />
      </div>
    ) : (
      ''
    )}
  </div>
);

export default FileList;
