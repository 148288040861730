import moment from 'moment';

export const dateFormat = (date) => {
  const formatingType = sessionStorage.getItem('formatingType') || 'DD.MM.YYYY';
  return moment(date).format(formatingType);
};

export const timeFormat = (time) => {
  const timeFormatingType = sessionStorage.getItem('timeFormatingType') || 'HH:mm';
  const updatedConvertedTime = moment(time).format(timeFormatingType);
  return updatedConvertedTime;
};
