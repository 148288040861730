import React from 'react';
import { Edit3, FilePlus, Send, User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { AppButton, LandLordKeyIcon } from '@/components/ui';
import Agent from '@/views/lease/components/Agent';

const LandlordESigning = ({ signingUrl, agent, fromLandlordPage }) => {
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="row">
        <div className="col-md-8 col-xl-8 col-xxl-8 col-xxxl-9 ">
          <div className="card card-with-shadow border-0 padding-30 h-100">
            <div className="d-flex flex-column justify-content-between h-100">
              {fromLandlordPage ? (
                <div className="">
                  <h6 className="montserrat-600 font-18 text-faded-black text-center margin-t-20">
                    {t('leases.lease_and_deposit_contract_ready_for_signing')}
                  </h6>
                  <div className="d-flex justify-content-center margin-t-30">
                    <AppButton
                      icon={<Edit3 />}
                      handleClick={() => window.open(signingUrl)}
                      btnClass="btn btn-primary"
                      label="Read and sign here"
                    />
                  </div>
                </div>
              ) : (
                <div className="">
                  <h6 className="montserrat-600 font-18 text-faded-black text-center margin-t-20">
                    {t('leases.waiting_for_the_landlord_to_sign_the_lease')}
                  </h6>
                  <div className="d-flex justify-content-center margin-t-30">
                    <div className="menu-dot-10 bg-muted margin-r-10" />
                    <div className="menu-dot-10 bg-muted margin-r-10" />
                    <div className="menu-dot-10 bg-muted" />
                  </div>
                </div>
              )}

              <div className="d-flex align-items-center stepProgress">
                <div className="stepProgress__container">
                  <div className="stepProgress__bar" id="progress" style={{ width: `66%` }} />

                  <div className="stepProgress__circle stepProgress__circle--active">
                    <FilePlus />
                  </div>

                  <div className="stepProgress__circle stepProgress__circle--active">
                    <Send />
                  </div>

                  <div className="stepProgress__circle stepProgress__circle--active">
                    <User />
                  </div>

                  <div className="stepProgress__circle svg-icon-25">
                    <LandLordKeyIcon />
                  </div>

                  <div className="stepProgress__text">
                    <span className="montserrat-500 font-14 text-faded-black">
                      {t('leases.documentation')}
                    </span>
                    <span className="montserrat-500 font-14 text-faded-black">
                      {t('leases.signed_by_tenant')}
                    </span>
                    <span className="montserrat-500 font-14 text-faded-black">
                      {t('leases.landlord_e_signing')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Agent agent={agent} />
      </div>
    </div>
  );
};

export default LandlordESigning;
