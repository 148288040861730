import React from 'react';
import Pusher from 'pusher-js';
import PusherContext from './pusherContext';
import { getApiBaseUrl } from '@/core/utils';
import { accessToken } from '@/views/profile/helpers/profile-helper';

const apiKey = process.env.REACT_APP_PUSHER_API_KEY;
const cluster = process.env.REACT_APP_PUSHER_CLUSTER;
const BASE_API_URL = getApiBaseUrl();
const url = `${BASE_API_URL}/auth/pusher`;

const pusher = new Pusher(apiKey, {
  cluster,
  authEndpoint: url,
  auth: {
    headers: { Authorization: `Bearer ${accessToken()}` }
  }
  // encrypted: true
  // forceTLS: true
});

export const PusherProvider = ({ children }) => (
  <PusherContext.Provider value={{ pusher }}>{children}</PusherContext.Provider>
);

export const usePusher = () => {
  const context = React.useContext(PusherContext);
  if (!context) {
    throw new Error('usePusher must be used within a InboxProvider');
  }
  return context;
};
