import React from 'react';
import { CheckCircle, FilePlus, Send, User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { LandLordKeyIcon } from '@/components/ui';
import Agent from '@/views/lease/components/Agent';

const LeaseFinalProcess = ({ agent, listingId }) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="row">
        <div className="col-md-8 col-xl-8 col-xxl-8 col-xxxl-9 ">
          <div className="card card-with-shadow border-0 padding-30 h-100">
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="">
                <h6 className="montserrat-600 font-18 text-faded-black text-center margin-t-20">
                  {t('leases.tenancy_agreement_signed')}
                </h6>
                <div className="d-flex justify-content-center margin-t-30">
                  <div className="svg-icon-40 text-success">
                    <CheckCircle />
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center stepProgress">
                <div className="stepProgress__container">
                  <div className="stepProgress__bar" id="progress" style={{ width: `99%` }} />

                  <div className="stepProgress__circle stepProgress__circle--active sv-icon-30">
                    <FilePlus />
                  </div>

                  <div className="stepProgress__circle stepProgress__circle--active">
                    <Send />
                  </div>

                  <div className="stepProgress__circle stepProgress__circle--active">
                    <User />
                  </div>

                  <div className="stepProgress__circle svg-icon-25 stepProgress__circle--active">
                    <LandLordKeyIcon />
                  </div>

                  <div className="stepProgress__text">
                    <span className="montserrat-500 font-14 text-faded-black">
                      {t('leases.documentation')}
                    </span>
                    <span className="montserrat-500 font-14 text-faded-black">
                      {t('leases.signed_by_tenant')}
                    </span>
                    <span className="montserrat-500 font-14 text-faded-black">
                      {t('leases.signed_by_landlord')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Agent agent={agent} listingId={listingId} />
      </div>
    </div>
  );
};

export default LeaseFinalProcess;
