import React, { useEffect, useState } from 'react';

const AppTextAreaTwo = (
  {
    id,
    placeholder,
    context,
    onChange,
    value = '',
    customClass = 'form-control',
    rows = '5',
    onBlur
  },
  ref
) => {
  const [state, setState] = useState(value);

  const handleChange = (e) => {
    setState(e.target.value);
  };

  useEffect(() => {
    if (onChange) {
      onChange(state, context);
    }
  }, [state]);

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <textarea
      id={id}
      value={state}
      placeholder={placeholder}
      className={`${customClass}`}
      ref={ref}
      onChange={(e) => handleChange(e)}
      rows={rows}
      onBlur={onBlur}
    />
  );
};

export default React.forwardRef(AppTextAreaTwo);
