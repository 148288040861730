/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
export const handleSetCity = (addresses, name) => {
  let city = '';
  for (const address of addresses) {
    for (const addressType of address?.types) {
      if (addressType === 'locality') {
        if (name === 'long-name') {
          city = address?.long_name;
          if (!address?.long_name.includes(' ')) {
            return address?.long_name;
          }
        } else {
          city = address?.short_name;
          if (!address?.short_name.includes(' ')) {
            return address?.short_name;
          }
        }
      }
      if (addressType === 'administrative_area_level_1') {
        if (name === 'long-name') {
          city = address?.long_name;
          if (!address?.long_name.includes(' ')) {
            return address?.long_name;
          }
        } else {
          city = address?.short_name;
          if (!address?.short_name.includes(' ')) {
            return address?.short_name;
          }
        }
      }
      if (addressType === 'administrative_area_level_2') {
        if (name === 'long-name') {
          city = address?.long_name;
          if (!address?.long_name.includes(' ')) {
            return address?.long_name;
          }
        } else {
          city = address?.short_name;
          if (!address?.short_name.includes(' ')) {
            return address?.short_name;
          }
        }
      }
    }
  }
  return city;
};

export const handleSetCountry = (addresses, name) => {
  let country = '';
  addresses.map((address) => {
    if (country !== '') {
      return country;
    }
    address?.types.map((addressType) => {
      if (addressType === 'country') {
        if (name === 'long-name') {
          country = address?.long_name;
        } else {
          country = address?.short_name;
        }
      }
    });
  });
  return country;
};

export const handleSetStreetNumber = (addresses) => {
  let streetNumber = '';
  addresses.map((address) => {
    if (streetNumber !== '') {
      return streetNumber;
    }
    address?.types.map((addressType) => {
      if (addressType === 'street_number') {
        streetNumber = address?.long_name;
      }
    });
  });
  return streetNumber;
};

export const handleSetSublocality = (addresses) => {
  let sublocality = '';
  addresses.map((address) => {
    if (sublocality !== '') {
      return sublocality;
    }
    address?.types.map((addressType) => {
      if (addressType === 'sublocality') {
        sublocality = address?.long_name;
      }
    });
  });
  return sublocality;
};

export const handleSetPostalCode = (addresses) => {
  let postalCode = '';
  addresses.map((address) => {
    if (postalCode !== '') {
      return postalCode;
    }
    address?.types.map((addressType) => {
      if (addressType === 'postal_code') {
        postalCode = address?.long_name;
      }
    });
  });
  return postalCode;
};
