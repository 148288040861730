import React, { useEffect, useState } from 'react';
import { Check } from 'react-feather';

const AppFilterSingleItemSearchSelect = ({ selectValues, onChange }) => {
  const [state, setState] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // change state value
  const handleSelectOptionChange = (e, item) => {
    setState(item);
    setSearchTerm('');
  };

  // change search term
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // callback trigger when state change
  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state, onChange]);

  // clear state value
  const handleClear = () => {
    setState([]);
    setSearchTerm('');
  };

  // search result
  const results = !searchTerm
    ? selectValues
    : selectValues.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      );

  return (
    <ul className="dropdown-menu dropdown-search-select" aria-labelledby="dropdownMenuButton">
      <div className="dropdown-wrapper">
        <div className="form-group form-group-with-search">
          <input
            type="text"
            className="form-control"
            placeholder="Search & Select...."
            value={searchTerm}
            onChange={handleChange}
          />
        </div>

        <div className="dropdown-divider my-0" />

        <div className="dropdown-search-result-wrapper custom-scrollbar">
          {state.length ? (
            <li>
              <span
                onClick={() => handleClear()}
                role="button"
                tabIndex="0"
                className="dropdown-item"
              >
                Clear
              </span>
            </li>
          ) : (
            ''
          )}

          {results.map((item) => (
            <li key={item.id}>
              <span
                role="button"
                onClick={(e) => handleSelectOptionChange(e, item)}
                tabIndex="0"
                className={`dropdown-item text-capitalize ${
                  Object.values(state).includes(item.title) ? 'disabled selected' : ''
                }`}
              >
                {item.title}
                <span className="check-sign float-end">
                  <Check />
                </span>
              </span>
            </li>
          ))}
        </div>
      </div>
    </ul>
  );
};

export default AppFilterSingleItemSearchSelect;
