import { isOnlyPartnerTenant, isOnlyPartnerLandlord } from '@/helpers/permissions';

const getLoggedUserType = (roles) => {
  let userType = '';
  const isOnlyLandlord = isOnlyPartnerLandlord(roles);
  const isOnlyTenant = isOnlyPartnerTenant(roles);

  if (!userType && isOnlyLandlord) userType = 'landlord';
  else if (!userType && isOnlyTenant) userType = 'tenant';

  if (userType) return userType;
  return '';
};
export default getLoggedUserType;
