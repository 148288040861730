import React, { createContext, useState } from 'react';

export const layoutContext = createContext();

const LayoutContext = ({ children }) => {
  const [sidebarOffCanvas, setSidebarOffCanvas] = useState(false);
  const handleSidebarOffCanvas = () => setSidebarOffCanvas(!sidebarOffCanvas);
  return (
    <layoutContext.Provider value={{ sidebarOffCanvas, handleSidebarOffCanvas }}>
      {children}
    </layoutContext.Provider>
  );
};

export default LayoutContext;
