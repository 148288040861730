const defaultMapState = {
  center: [59.9071759, 10.77532260000001],
  bounds: [60.325132160343145, 29.13415407031249, 59.546382183279206, 31.54015992968749],
  marginBounds: [60.2843135300829, 29.21655153124999, 59.58811868963835, 31.45776246874999],
  zoom: 12,
  defaultZoom: 12,
  defaultCenter: { lng: 10.77532260000001, lat: 59.9071759 },
  mapPosition: {}
};
export default defaultMapState;
