/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { ChevronRight, Settings } from 'react-feather';
import { NavLink, useLocation } from 'react-router-dom';
import { uniqueId } from '@/core/utils';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AppTabs = ({
  vertical,
  tabHeaders,
  content,
  events,
  handleTabContent,
  tabContent,
  headerComponent
}) => {
  const [activeContentHeader, setActiveContentHeader] = useState('Home');

  // location
  const location = useLocation().pathname;

  const query = useQuery();
  const { onClick } = !!events && events;

  const getActiveTabClass = (item) => {
    if (!!query.get('tab') && item.name === query.get('tab')) return 'active';
    return '';
  };

  const tabHeaderHandler = (e, item) => {
    setActiveContentHeader(item.name);
    !!onClick && onClick(e, item);
  };

  return (
    <>
      {!vertical && (
        <>
          <div className="horizontal-tab">
            <div className="card card-with-shadow border-0">
              <nav>
                <div className="nav nav-tabs padding-30">
                  {tabHeaders.map((item) => (
                    <NavLink
                      to={`${location}?tab=${item.key}`}
                      onClick={(e) => tabHeaderHandler(e, item)}
                      key={item.key}
                      isActive={(match) => {
                        if (!match) {
                          return false;
                        }
                        return '';
                      }}
                      className={`nav-item me-4 text-capitalize ${getActiveTabClass(item)}`}
                    >
                      <span className="me-2">{item.icon}</span>
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </nav>

              <div className="tab-content  padding-30">{content}</div>
            </div>
          </div>
        </>
      )}

      {vertical && (
        <>
          <div className="vertical-tab">
            <div className="row no-gutters">
              {/* Left */}
              <div className="col-md-3 pr-md-3 tab-menu">
                <div className="card card-with-shadow border-0">
                  {/* Icon */}
                  <div className="header-icon">
                    <div className="icon-position d-flex justify-content-center">
                      <div className="tab-icon d-flex justify-content-center align-items-center">
                        <Settings />
                      </div>
                    </div>
                  </div>

                  {/* Tab Menu */}

                  <div className="px-primary py-primary tab-menu-wrapper">
                    <div className="nav flex-column nav-pills">
                      {tabHeaders.map((item) => (
                        <NavLink
                          to={`${location}?tab=${item.name}`}
                          isActive={(match) => {
                            if (!match) {
                              return false;
                            }
                            return '';
                          }}
                          key={uniqueId()}
                          onClick={(e) => handleTabContent(item)}
                          className={`tab-item-link my-2 my-sm-3 d-flex justify-content-between ${getActiveTabClass(
                            item
                          )}`}
                        >
                          <h5 className="me-2 tab-item">{item.name}</h5>
                          <span className="active-icon">
                            <ChevronRight />
                          </span>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                  {/*   menu end */}
                </div>
              </div>

              {/* Right */}
              <div className="col-md-9 pl-md-3 pt-md-0 pt-sm-4 pt-4">
                <div className="card card-with-shadow border-0">
                  <div className="tab-content px-primary" id="v-pills-tabContent">
                    <div className="tab-content-header d-flex align-items-center justify-content-between mb-0">
                      <h5 className="text-capitalize text-secondary title ">{query.get('tab')}</h5>
                      {headerComponent && headerComponent}
                    </div>

                    <hr />

                    <div className="content py-primary">{tabContent}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default AppTabs;
