import { size } from 'lodash';

/* eslint-disable no-param-reassign */
const getUpdatedArrayOfObject = (items1, items2, uniqueId) => {
  const newItems1 = Object.assign(items1);
  if (size(items2)) {
    newItems1.forEach((item1) => {
      items2?.forEach((item2) => {
        if (item1[uniqueId] === item2[uniqueId]) {
          item1.checked = item2.checked;
        }
      });
    });
    return newItems1;
  }
  return items1;
};

export default getUpdatedArrayOfObject;
