import React, { useEffect, useCallback } from 'react';
import { Facebook } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AppButton } from '@/components/ui';
import requestForLogin from '@/views/login/apiRequest';
import { getFBRedirectUrl, handleLoginResponse } from './login-helper';
import { toastCall, setUserRedirectPath, delayCall } from '@/core/utils';
import { getPartnerName } from '@/core/utils/localStorage';

const LoginWithFacebook = ({ handleTokenResult, setTokens, fromUser = '/' }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const code =
    location.search.substr(6) &&
    (location?.search?.split('=')?.includes('code') ||
      location?.search?.split('=')?.includes('?code'))
      ? location.search.substr(6)
      : '';
  const partnerId = Cookies.get('partnerId');

  const handleFbLogin = useCallback(async () => {
    try {
      const response = await requestForLogin({
        provider: 'facebook',
        partnerId,
        code,
        ...(partnerId && getPartnerName && { partnerSubDomain: getPartnerName }),
        isPartnerPublic: true
      });
      const { data = '' } = response || {};
      console.log('data: ', data);

      if (!data || response?.data?.error) {
        delayCall(() => {
          toastCall('danger', t('register.sign_in.log_in_with_facebook_field'), 'top-right');
        });
        return false;
      }

      await handleLoginResponse(data, true);
      const tokenResult = await setTokens(data);
      handleTokenResult(tokenResult, data?.userInfo);
      return false;
    } catch (error) {
      toastCall('danger', t('errors.internal_server_error'), 'top-right');
    }
    return false;
  }, [code, handleTokenResult]);

  const fbBtnClick = async () => {
    await setUserRedirectPath(fromUser);
    window.location.href = getFBRedirectUrl();
  };

  useEffect(() => {
    if (code) {
      delayCall(() => handleFbLogin());
    }
  }, [code]);

  return (
    <AppButton
      btnClass="btn-facebook public-btn-block"
      label={
        location.pathname?.includes('create-account')
          ? t('register.sign_in.sign_up_with_facebook')
          : t('register.sign_in.log_in_with_facebook')
      }
      icon={<Facebook />}
      handleClick={fbBtnClick}
      block
    />
  );
};

export default LoginWithFacebook;
