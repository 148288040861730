import React from 'react';

const Icon360 = () => (
  <svg width="24" height="24" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.1665 9.70833C19.1665 9.94923 19.2622 10.1803 19.4326 10.3506C19.6029 10.521 19.834 10.6167 20.0749 10.6167H29.1915V1.50833C29.1915 1.26632 29.0954 1.03422 28.9243 0.86309C28.7531 0.691963 28.521 0.595825 28.279 0.595825C28.037 0.595825 27.8049 0.691963 27.6338 0.86309C27.4627 1.03422 27.3665 1.26632 27.3665 1.50833V7.45833C25.6578 4.95601 23.1663 3.0921 20.2833 2.15928C17.4004 1.22645 14.2893 1.27756 11.4385 2.30456C8.5878 3.33156 6.15888 5.27629 4.5332 7.83337C2.90753 10.3904 2.17717 13.415 2.45682 16.4322C2.73647 19.4494 4.01028 22.2882 6.07824 24.5029C8.1462 26.7177 10.8912 28.1829 13.8821 28.6684C16.873 29.154 19.9405 28.6324 22.6029 27.1856C25.2653 25.7388 27.3718 23.4487 28.5915 20.675C28.6818 20.4501 28.6811 20.1988 28.5895 19.9744C28.4979 19.75 28.3225 19.5701 28.1006 19.4726C27.8787 19.3752 27.6275 19.3679 27.4003 19.4524C27.1731 19.5368 26.9877 19.7063 26.8832 19.925C25.8246 22.3359 23.9911 24.3242 21.6738 25.5743C19.3564 26.8243 16.6879 27.2647 14.0917 26.8253C11.4956 26.3859 9.12057 25.092 7.34361 23.149C5.56664 21.206 4.48953 18.7251 4.28323 16.1001C4.07692 13.4752 4.75324 10.8565 6.20485 8.65973C7.65646 6.46296 9.80019 4.81394 12.2958 3.97439C14.7914 3.13484 17.496 3.15286 19.9802 4.02558C22.4644 4.89831 24.586 6.57574 26.0082 8.79166H20.0832C19.9625 8.79055 19.8428 8.8135 19.7311 8.85917C19.6194 8.90484 19.5179 8.97232 19.4325 9.05766C19.3472 9.14301 19.2797 9.2445 19.234 9.35622C19.1884 9.46794 19.1654 9.58764 19.1665 9.70833Z"
      fill="white"
    />
    <g clipPath="url(#clip0_298_27940)">
      <path
        d="M11.3359 13.3333L16.3359 9.44446L21.3359 13.3333V19.4445C21.3359 19.7391 21.2189 20.0218 21.0105 20.2301C20.8021 20.4385 20.5195 20.5556 20.2248 20.5556H12.447C12.1524 20.5556 11.8697 20.4385 11.6614 20.2301C11.453 20.0218 11.3359 19.7391 11.3359 19.4445V13.3333Z"
        fill="white"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.668 20.5556V15H18.0013V20.5556"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_298_27940">
        <rect
          width="13.3333"
          height="13.3333"
          fill="white"
          transform="translate(9.66797 8.33337)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Icon360;
