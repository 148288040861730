import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import DEMO_TOKEN from '@/apollo/helpers/demoToken';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get('accessToken');
  const publicToken = process.env.REACT_APP_API_KEY;
  const accessToken = token || publicToken;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    }
  };
});

export default authLink;
