import React, { useEffect, useState } from 'react';
import { Check, Loader } from 'react-feather';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { removeAllShowClass, uniqueId } from '@/core/utils';

const AppInputSingleItemSearchSelect2 = ({
  selectedItem,
  selectValues,
  onChange,
  callBack,
  placeholder = 'Find and select accounts',
  context,
  loadMore,
  id,
  disabled,
  clear,
  isLoading,
  searchPhoneNumber,
  settingsLoading,
  readOnly
}) => {
  const [state, setState] = useState({
    title: selectedItem || ''
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState({});
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  // change state value
  const handleSelectOptionChange = (e, item) => {
    setSelected(item);
    if (item.typeName === 'PartnerUsageCount') {
      const modifiedItem = item.title.replace('_', ' ');
      const newModifiedItem = modifiedItem.split(' ');
      const modified = newModifiedItem.map((ns) => ns[0].toUpperCase() + ns.substring(1));
      let newValue = modified.join(' ');
      if (newValue === 'Esign') {
        newValue = 'E-Sign';
      }
      setValue(newValue);
    } else {
      setState(item);
    }
    setSearchTerm('');
    // remove show class
    const allFilterClasses = document.querySelectorAll('.single-item-search');
    removeAllShowClass(allFilterClasses);
  };
  // change search term
  const handleChange = (e) => {
    if (!readOnly) {
      setSearchTerm(e.target.value);
      if (searchPhoneNumber) {
        searchPhoneNumber(e.target.value);
      }
      setState({
        title: ''
      });
    }
  };
  const handleCallback = () => {
    const dropdownState = document.getElementById(`${id}`).getAttribute('aria-expanded');
    if (callBack) callBack(context, dropdownState);
  };
  // clear state value
  const handleClear = () => {
    setState({
      title: ''
    });
    setSearchTerm('');
    setValue('');
    setSelected({});
    if (clear) {
      clear();
    }
    // remove show class
    const allFilterClasses = document.querySelectorAll('.single-item-search');
    removeAllShowClass(allFilterClasses);
  };
  useEffect(() => {
    if (onChange && selected) {
      onChange(selected);
    }
  }, [selected]);
  // values after select
  const finalValue = () =>
    searchTerm || (context && state.title) ? t(`${context}.${state.title}`) : state.title || '';

  return (
    <div className="app-dropdown app-input-dropdown">
      <div
        disabled={disabled}
        id={`${id}`}
        className="form-group single-item-search"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <input
          disabled={disabled}
          type="text"
          className="form-control text-capitalize"
          placeholder={placeholder}
          value={finalValue() || value}
          onChange={handleChange}
          onClick={handleCallback}
        />
      </div>
      <ul
        className="dropdown-menu dropdown-search-select single-item-search"
        aria-labelledby="dropdownMenuButton"
      >
        <div style={{ minHeight: '100px' }} className="dropdown-wrapper">
          <div className="dropdown-divider my-0" />
          <div
            id={`custom-scrollbar-${id}`}
            className="dropdown-search-result-wrapper custom-scrollbar"
          >
            {state.title || value ? (
              <li>
                <span
                  onClick={() => handleClear()}
                  role="button"
                  tabIndex="0"
                  className="dropdown-item"
                >
                  {t('common.clear')}
                </span>
              </li>
            ) : (
              ''
            )}
            <InfiniteScroll
              dataLength={selectValues.length} // This is important field to render the next data
              next={handleCallback}
              hasMore={loadMore}
              scrollableTarget={`custom-scrollbar-${id}`}
              loader={
                !searchTerm && (
                  <li className="dropdown-item">
                    <Loader width="36px" height="36px" />
                  </li>
                )
              }
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {selectValues && selectValues.length ? (
                selectValues.map((item) => (
                  <li key={uniqueId()}>
                    <span
                      role="button"
                      onClick={(e) => handleSelectOptionChange(e, item)}
                      tabIndex="0"
                      className={`dropdown-item text-wrap text-capitalize ${
                        (state.title && state.title === item.title) ||
                        (selected.type && selected.type === item.type)
                          ? 'disabled selected'
                          : ''
                      }`}
                    >
                      {context ? t(`${context}.${item.title}`) : item.title}
                      <span className="check-sign float-end">
                        <Check />
                      </span>
                    </span>
                  </li>
                ))
              ) : (selectValues && !selectValues.length && searchTerm) ||
                (selectValues && !selectValues.length && isLoading) ||
                (selectValues && !selectValues.length && settingsLoading) ? (
                <li className="dropdown-item">Nothing to show!</li>
              ) : (
                selectValues &&
                !selectValues.length &&
                !searchTerm && (
                  <li className="dropdown-item">
                    <Loader width="36px" height="36px" />
                  </li>
                )
              )}
            </InfiniteScroll>
          </div>
        </div>
      </ul>
    </div>
  );
};
export default AppInputSingleItemSearchSelect2;
