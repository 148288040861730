import React from 'react';

const SearchPin = () => (
  <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2015 23.5068C21.5708 23.5068 25.9235 19.1541 25.9235 13.7847C25.9235 8.41543 21.5708 4.06274 16.2015 4.06274C10.8322 4.06274 6.47949 8.41543 6.47949 13.7847C6.47949 19.1541 10.8322 23.5068 16.2015 23.5068Z"
      stroke="#77828F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.3547 25.9373L23.0684 20.6509"
      stroke="#77828F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_2348_6023)">
      <path
        d="M11.4365 19.8893C11.5493 24.2238 6.6477 28.0692 6.6477 28.0692C6.6477 28.0692 1.55272 24.4839 1.43993 20.1494C1.40147 18.6713 1.89119 17.2401 2.80136 16.1706C3.71153 15.1011 4.9676 14.4809 6.29323 14.4464C7.61886 14.4119 8.90548 14.9659 9.87004 15.9867C10.8346 17.0074 11.3981 18.4112 11.4365 19.8893Z"
        fill="white"
        stroke="#77828F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.48445 21.7773C7.72667 21.745 8.70749 20.7117 8.67517 19.4695C8.64284 18.2273 7.60962 17.2465 6.3674 17.2788C5.12518 17.3111 4.14437 18.3443 4.17669 19.5866C4.20901 20.8288 5.24223 21.8096 6.48445 21.7773Z"
        stroke="#77828F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2348_6023">
        <rect
          width="12.4997"
          height="15.6247"
          fill="white"
          transform="translate(0.269531 13.6028) rotate(-1.4905)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SearchPin;
