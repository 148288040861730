import { size } from 'lodash';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toastCall, getApiBaseUrl, translateLanguage } from '@/core/utils';

const apiRequestForDownloadUrl = (queryData) => {
  const body = {
    query: `
      query GET_FILE_DOWNLOAD_URL($queryData: FileDownloadUrlDataType) {
  getFileDownloadUrl(queryData: $queryData)
  }
`,
    variables: { queryData }
  };
  return body;
};

const openFileUsingFileId = async (fileId, oldUrl) => {
  try {
    const BASE_API_URL = getApiBaseUrl();
    const url = `${BASE_API_URL}/graphql`;
    const accessToken = Cookies.get('accessToken');

    if (!fileId) {
      toastCall('danger', translateLanguage('errors.bad_request'));
      return { error: true };
    }

    if (oldUrl) {
      window.open(oldUrl);
      return oldUrl;
    }

    const response = await axios.post(url, apiRequestForDownloadUrl({ fileId }), {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (size(response?.data?.errors)) {
      if (response?.data?.errors[0]?.statusCode === 500) {
        toastCall('danger', translateLanguage('errors.unauthorized'));
      } else {
        toastCall('danger', response?.data?.errors[0]?.message);
      }
      return { error: true };
    }

    if (response?.data?.data?.getFileDownloadUrl) {
      window.open(response?.data?.data?.getFileDownloadUrl);
      return response?.data?.data?.getFileDownloadUrl;
    }
    return { error: true };
  } catch (e) {
    toastCall('danger', translateLanguage('errors.bad_request'));
    return { error: true };
  }
};
export default openFileUsingFileId;
