import React from 'react';

const AppButton = ({
  id,
  btnClass,
  disabled,
  handleClick,
  label,
  icon,
  block,
  dropdown,
  modal,
  modalTarget
}) => {
  const btnElement = (() => {
    if (dropdown) {
      return (
        <button
          id={id}
          type="button"
          className={`btn  ${btnClass || 'btn-primary'} ${disabled ? 'disabled' : ''}`}
          onClick={handleClick}
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {icon || ''} {label || ''}
        </button>
      );
    }

    if (modal) {
      return (
        <button
          id={id}
          type="button"
          className={`btn  ${btnClass || 'btn-primary'} ${disabled ? 'disabled' : ''}`}
          onClick={handleClick}
          data-bs-toggle="modal"
          data-bs-target={`#${modalTarget}`}
        >
          {icon || ''} {label || ''}
        </button>
      );
    }

    return (
      <button
        id={id}
        type="button"
        className={`btn  ${btnClass || 'btn-primary'} ${disabled ? 'disabled' : ''}`}
        onClick={handleClick}
      >
        {icon || ''} {label || ''}
      </button>
    );
  })();

  return <>{block ? <div className="d-grid">{btnElement}</div> : btnElement}</>;
};

export default AppButton;
