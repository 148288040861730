import React, { useReducer } from 'react';
import FilterContext from './FilterContext';
import FilterReducer from './FilterReducer';
import ActionTypes from './ActionTypes';

const {
  ADD_TO_LISTING_QUERY,
  REMOVE_FROM_LISTING_QUERY,
  ADD_TO_EMAIL_SMS_QUERY,
  REMOVE_FROM_EMAIL_SMS_QUERY,
  ADD_TO_USER_QUERY,
  REMOVE_FROM_USER_QUERY,
  ADD_TO_USER_ROLE_QUERY,
  REMOVE_FROM_USER_ROLE_QUERY,
  ADD_TO_PARTNER_QUERY,
  REMOVE_FROM_PARTNER_QUERY,
  ADD_TO_PHONE_NUMBER_QUERY,
  REMOVE_FROM_PHONE_NUMBER_QUERY,
  ADD_TO_INCOMING_PAYMENTS,
  REMOVE_INCOMING_PAYMENTS,
  ADD_TO_PAYOUTS_QUERY,
  REMOVE_FROM_PAYOUTS_QUERY,
  ADD_TO_APP_HEALTH_QUERY,
  REMOVE_FROM_APP_HEALTH_QUERY,
  ADD_TO_TEMPLATE_QUERY,
  REMOVE_FROM_TEMPLATE_QUERY,
  PARTNER_USAGE_QUERY_DATA_TYPE,
  ADD_TO_CHARTS_FILTER_QUERY,
  REMOVE_FROM_CHARTS_FILTER_QUERY
} = ActionTypes;

const FilterState = ({ children }) => {
  const initialState = {
    listingQueryData: { search: true },
    userQueryData: {},
    userRoleQueryData: {},
    partnerQueryData: {},
    phoneNumberQueryData: {},
    NetReceivedFileDataType: {},
    notificationLogsQueryData: {},
    payoutsQueryData: {},
    appHealthQueryData: {},
    templateQueryData: {},
    partnerUsageQueryDataType: {},
    chartsFilterQueryData: {}
  };

  const [state, dispatch] = useReducer(FilterReducer, initialState);

  // actions

  const addToListingQuery = (query) => {
    dispatch({
      type: ADD_TO_LISTING_QUERY,
      payload: query
    });
  };

  const removeFromListingQuery = (key) => {
    dispatch({
      type: REMOVE_FROM_LISTING_QUERY,
      payload: key
    });
  };

  const addToNotificationLogsQuery = (query) => {
    dispatch({
      type: ADD_TO_EMAIL_SMS_QUERY,
      payload: query
    });
  };
  const addToUserQuery = (query) => {
    dispatch({
      type: ADD_TO_USER_QUERY,
      payload: query
    });
  };

  const removeFromUserQuery = (key) => {
    dispatch({
      type: REMOVE_FROM_USER_QUERY,
      payload: key
    });
  };

  const removeFromNotificationLogsQuery = (key) => {
    dispatch({
      type: REMOVE_FROM_EMAIL_SMS_QUERY,
      payload: key
    });
  };

  const addToUserRoleQuery = (query) => {
    dispatch({
      type: ADD_TO_USER_ROLE_QUERY,
      payload: query
    });
  };

  const removeFromUserRoleQuery = (key) => {
    dispatch({
      type: REMOVE_FROM_USER_ROLE_QUERY,
      payload: key
    });
  };

  const addToPartnerQuery = (query) => {
    dispatch({
      type: ADD_TO_PARTNER_QUERY,
      payload: query
    });
  };

  const removeFromPartnerQuery = (key) => {
    dispatch({
      type: REMOVE_FROM_PARTNER_QUERY,
      payload: key
    });
  };

  const addToPhoneNumberQuery = (query) => {
    dispatch({
      type: ADD_TO_PHONE_NUMBER_QUERY,
      payload: query
    });
  };

  const removeFromPhoneNumberQuery = (key) => {
    dispatch({
      type: REMOVE_FROM_PHONE_NUMBER_QUERY,
      payload: key
    });
  };

  const addToIncomingPaymentQuery = (query) => {
    dispatch({
      type: ADD_TO_INCOMING_PAYMENTS,
      payload: query
    });
  };

  const removeFromIncomingPaymentQuery = (key) => {
    dispatch({
      type: REMOVE_INCOMING_PAYMENTS,
      payload: key
    });
  };
  const addToPayoutsQuery = (query) => {
    dispatch({
      type: ADD_TO_PAYOUTS_QUERY,
      payload: query
    });
  };

  const removeFromPayoutsQuery = (key) => {
    dispatch({
      type: REMOVE_FROM_PAYOUTS_QUERY,
      payload: key
    });
  };

  const addToAppHealthQuery = (query) => {
    dispatch({
      type: ADD_TO_APP_HEALTH_QUERY,
      payload: query
    });
  };

  const addPartnerUsageQueryDataType = (key) => {
    dispatch({
      type: PARTNER_USAGE_QUERY_DATA_TYPE,
      payload: key
    });
  };

  const removeFromAppHealthQuery = (key) => {
    dispatch({
      type: REMOVE_FROM_APP_HEALTH_QUERY,
      payload: key
    });
  };

  const addToTemplateQuery = (query) => {
    dispatch({
      type: ADD_TO_TEMPLATE_QUERY,
      payload: query
    });
  };

  const removeFromTemplateQuery = (key) => {
    dispatch({
      type: REMOVE_FROM_TEMPLATE_QUERY,
      payload: key
    });
  };

  const addToChartsFilterQuery = (query) => {
    dispatch({
      type: ADD_TO_CHARTS_FILTER_QUERY,
      payload: query
    });
  };

  const removeFromChartsFilterQuery = (key) => {
    dispatch({
      type: REMOVE_FROM_CHARTS_FILTER_QUERY,
      payload: key
    });
  };

  return (
    <FilterContext.Provider
      value={{
        listingQueryData: state.listingQueryData,
        userQueryData: state.userQueryData,
        userRoleQueryData: state.userRoleQueryData,
        partnerQueryData: state.partnerQueryData,
        phoneNumberQueryData: state.phoneNumberQueryData,
        NetReceivedFileDataType: state.NetReceivedFileDataType,
        payoutsQueryData: state.payoutsQueryData,
        notificationLogsQueryData: state.notificationLogsQueryData,
        appHealthQueryData: state.appHealthQueryData,
        templateQueryData: state.templateQueryData,
        partnerUsageQueryDataType: state.partnerUsageQueryDataType,
        chartsFilterQueryData: state.chartsFilterQueryData,
        addToListingQuery,
        removeFromListingQuery,
        addToNotificationLogsQuery,
        removeFromNotificationLogsQuery,
        addToUserQuery,
        removeFromUserQuery,
        addToUserRoleQuery,
        removeFromUserRoleQuery,
        addToPartnerQuery,
        removeFromPartnerQuery,
        addToPhoneNumberQuery,
        removeFromPhoneNumberQuery,
        addToIncomingPaymentQuery,
        removeFromIncomingPaymentQuery,
        addToPayoutsQuery,
        removeFromPayoutsQuery,
        addToAppHealthQuery,
        removeFromAppHealthQuery,
        addToTemplateQuery,
        removeFromTemplateQuery,
        addPartnerUsageQueryDataType,
        addToChartsFilterQuery,
        removeFromChartsFilterQuery
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterState;
