import React from 'react';

const LockInvert = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33333 7.83301H12.6667C13.1269 7.83301 13.5 8.2061 13.5 8.66634V13.333C13.5 13.7932 13.1269 14.1663 12.6667 14.1663H3.33333C2.8731 14.1663 2.5 13.7932 2.5 13.333V8.66634C2.5 8.2061 2.8731 7.83301 3.33333 7.83301Z"
      fill="#333333"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66602 7.33301V4.66634C4.66602 3.78229 5.01721 2.93444 5.64233 2.30932C6.26745 1.6842 7.11529 1.33301 7.99935 1.33301C8.8834 1.33301 9.73125 1.6842 10.3564 2.30932C10.9815 2.93444 11.3327 3.78229 11.3327 4.66634V7.33301"
      fill="white"
    />
    <path
      d="M4.66602 7.33301V4.66634C4.66602 3.78229 5.01721 2.93444 5.64233 2.30932C6.26745 1.6842 7.11529 1.33301 7.99935 1.33301C8.8834 1.33301 9.73125 1.6842 10.3564 2.30932C10.9815 2.93444 11.3327 3.78229 11.3327 4.66634V7.33301"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LockInvert;
