/* eslint-disable no-plusplus */
import React from 'react';
import { Bar, defaults } from 'react-chartjs-2';

defaults.animation = false;

const RangeHistogram = ({ data, highlight, range }) => {
  // calculate frequency of data
  const counts = {};
  for (let i = 0; i < data.length; i++) counts[data[i]] = counts[data[i]] + 1 || 1;

  // generate data
  const barDataValues = [];
  for (let i = 0; i < range[1]; i++) {
    barDataValues.push(counts[i] || 0);
  }

  const barData = {
    labels: barDataValues.map((val, i) => i),
    datasets: [
      {
        backgroundColor: barDataValues.map((val, i) =>
          i >= highlight[0] && i <= highlight[1] ? '#1c2e45' : '#EEEEEE'
        ),
        data: barDataValues,
        borderWidth: 0
      }
    ]
  };
  const options = {
    responsive: true,
    maxBarThickness: 4,
    events: [],
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      xAxis: {
        display: false
      },
      yAxes: {
        display: false,
        ticks: {
          min: 0
        }
      }
    }
  };
  return <Bar data={barData} options={options} height={15} />;
};

export default RangeHistogram;
