import { Trans } from 'react-i18next';

const translateLanguage = (langKey, values) => (
  <Trans
    i18nKey={langKey} // optional -> fallbacks to defaults if not provided
    values={values}
    components={{ bold: <strong />, span: <span /> }}
  />
);
export default translateLanguage;
