import React from 'react';
import { X, User } from 'react-feather';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppModal, AppButton, LandlordIcon } from '@/components/ui';

const RegisterModal = ({ id, signInModalId }) => {
  const { t } = useTranslation();
  return (
    <>
      <AppModal
        id={id}
        type="modal-dialog-centered"
        options={{
          modalDialogClass: 'width-25'
        }}
      >
        <div className="savePropertyModal">
          <div
            className="float-end cursor-pointer padding-10"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <X />
          </div>
          <div className="padding-30">
            <div className="d-flex justify-content-center align-items-center margin-t-30">
              <img src="/assets/images/ULlogoIcon.png" alt="" />
            </div>

            <h5 className="montserrat-700 black-text-color text-center my-4 ">
              {t('label.create_an_account')}
            </h5>

            <h6 className="montserrat-500 text-faded-black text-center my-3 line-height-26 ">
              {t('label.optimize_first_experience')}
            </h6>

            <div className="mt-4">
              <Link to="/register-as-tenant/create-account">
                <span data-bs-dismiss="modal">
                  <AppButton
                    btnClass="btn-primary public-btn-block"
                    label={t('label.register_as_a_tenant')}
                    icon={<User />}
                    block
                  />
                </span>
              </Link>
            </div>

            <div className="mt-4">
              <Link to="/register-as-landlord/create-account" data-bs-dismiss="modal">
                <span data-bs-dismiss="modal">
                  <AppButton
                    btnClass="btn-sub-primary public-btn-block"
                    label={t('label.register_as_a_landlord')}
                    block
                    icon={<LandlordIcon />}
                  />
                </span>
              </Link>
            </div>

            <div className="block-divider" />

            <div className="margin-t-30 text-center poppins-400">
              {t('label.already_registered')}
              <span
                className="poppins-500 text-primary ms-2"
                role="button"
                data-bs-target={`#${signInModalId}`}
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                {t('common.log_in')}
              </span>
            </div>
          </div>
        </div>
      </AppModal>
    </>
  );
};

export default RegisterModal;
