import React, { useState } from 'react';

const AppFilterSelect = ({ selectValues, isClearable, callBack, customClass = '' }) => {
  const [state, setState] = useState('');
  const handleChange = (item) => {
    setState(item);
  };

  // eslint-disable-next-line no-unused-expressions
  callBack && callBack(state);

  const handleClear = () => {
    setState('');
  };
  return (
    <ul
      className={`dropdown-menu dropdown-select ${customClass}`}
      aria-labelledby="dropdownMenuButton"
    >
      <div className="dropdown-wrapper custom-scrollbar">
        {state && isClearable ? (
          <li>
            <span
              className="dropdown-item"
              role="button"
              tabIndex="0"
              onClick={() => handleClear()}
            >
              Clear
            </span>
          </li>
        ) : (
          ''
        )}
        {selectValues.map((item) => (
          <li key={item.id}>
            <span
              role="button"
              tabIndex="0"
              className={`dropdown-item text-capitalize ${
                Object.values(state).includes(item.title) ? 'disabled selected' : ''
              }`}
              onClick={() => handleChange(item)}
            >
              {item.title}
            </span>
          </li>
        ))}
      </div>
    </ul>
  );
};

export default AppFilterSelect;
