/* eslint-disable consistent-return */
import Cookies from 'js-cookie';
import JtwDecode from 'jwt-decode';
import { size } from 'lodash';
import requestForLogin, { requestForRefreshToken } from '@/views/login/apiRequest';
import {
  validateEmail,
  translateLanguage,
  toastCall,
  setUserInfo,
  getUserId,
  delayCall,
  logOut
} from '@/core/utils';

const setTokens = async (data) => {
  const { tokens = {}, error = '' } = data;
  const { accessToken = '', refreshToken = '' } = tokens;

  if (error || !accessToken || !refreshToken) {
    return {
      status: false,
      msg: error
    };
  }

  Cookies.set('accessToken', accessToken, { expires: 7 });
  Cookies.set('refreshToken', refreshToken, { expires: 7 });
  return { accessToken };
};

const handleUserDashboard = (tokens) => {
  if (!tokens) return false;
  const userInfo = JtwDecode(tokens);
  const { roles } = userInfo;
  const userId = userInfo?.data?.userId || getUserId();

  if (roles.includes('partner_landlord') || roles.includes('partner_tenant')) {
    Cookies.set(`${userId}_roles`, JSON.stringify(roles), { expires: 7 });
  } else {
    Cookies.remove(`${userId}_roles`);
  }
  return true;
};

export const handleLoginResponse = async (data, isFbLogin) => {
  Cookies.remove('publicToken');
  if (data?.tokens?.accessToken) handleUserDashboard(data?.tokens?.accessToken);
  if (data?.userInfo) {
    await setUserInfo(data?.userInfo, data?.tokens?.accessToken);
  } else if (!data?.userInfo && isFbLogin) {
    // delayCall(() => {
    //   toastCall(
    //     'info',
    //     translateLanguage('errors.we_are_fetching_information_please_try_again_after_some_time'),
    //     'top-right'
    //   );
    // }, 100);
  }
};
// validation
export const validate = (fieldName, value) => {
  switch (fieldName) {
    case 'email':
      if (!value) {
        return translateLanguage('register.create_account.email_is_required');
      }
      if (!validateEmail(value)) {
        return translateLanguage('register.create_account.please_provide_a_valid_email');
      }
      break;
    case 'password':
      if (!value) {
        return translateLanguage('register.create_account.password_is_required');
      }
      break;
    default: {
      return '';
    }
  }
  return '';
};

// check validation error
export const checkValidationError = (login, setLogin) => {
  const validationErrors = {};
  // check errors
  Object.keys(login.fields).forEach((keyName) => {
    const error = validate(keyName, login.fields[keyName]);
    if (error && error.length > 0) {
      validationErrors[keyName] = error;
    }
  });
  // check validation
  if (Object.keys(validationErrors).length > 0) {
    setLogin({
      fields: {
        ...login.fields
      },
      errors: {
        ...validationErrors
      }
    });
  }
};

// login helper method
export const handleLogin = async (email, password, setLoading, handleTokenResult, navigate) => {
  if (email && password) {
    // navigate(loginPath);
    const loginValue = {
      user: email,
      password
    };

    const partnerId = Cookies.get('partnerId');
    if (partnerId) loginValue.partnerId = partnerId;

    try {
      const response = await requestForLogin(loginValue);
      const { data = {} } = response || {};

      if (!size(data) || data?.error || data?.code === 403 || data?.code === 404) {
        if (data?.msg === 'User not verified') {
          document.getElementById(`sign_in_modal`)?.click();
          navigate('/verify-email');
        } else if (data?.code === 404 || data?.error === 'User not found') {
          toastCall('danger', translateLanguage('errors.user_not_found'), 'top-right');
        } else {
          toastCall('danger', translateLanguage('errors.invalid_email_or_password'), 'top-right');
        }
        setLoading(false);
        return false;
      }
      await handleLoginResponse(data);
      const tokenResult = await setTokens(data);
      handleTokenResult(tokenResult, data?.userInfo);
      // if (!data.error) navigate('/find-home');
      return true;
    } catch (error) {
      setLoading(false);
      toastCall('danger', translateLanguage('errors.bad_request'), 'top-right');
    }
  }
};

export const handleUnauthorizedUser = async () => {
  try {
    let isTokenRefreshed = false;
    const existingTokens = {
      refreshToken: Cookies.get('refreshToken'),
      accessToken: Cookies.get('accessToken')
    };

    const partnerId = Cookies.get('partnerId');
    if (partnerId) existingTokens.partnerId = partnerId;

    if (!existingTokens.refreshToken || !existingTokens.accessToken) {
      return false;
    }
    const response = await requestForRefreshToken(existingTokens);
    const { data = '', error } = response || {};

    if (!data?.tokens || error || data?.error) {
      const errorMessage = data?.error || error;
      if (errorMessage === "Cannot read property 'id' of null") await logOut();
      return isTokenRefreshed;
    }

    await handleLoginResponse(data);
    const tokenResult = await setTokens(data);
    if (tokenResult?.accessToken) isTokenRefreshed = true;
    return isTokenRefreshed;
  } catch (err) {
    return false;
  }
};

export default setTokens;

const APP_URL = window?.location?.origin || '';
const FB_API_ID = process.env.REACT_APP_FB_APP_ID;

export const getFBRedirectUrl = () =>
  `https://www.facebook.com/v11.0/dialog/oauth?client_id=${FB_API_ID}&redirect_uri=${APP_URL}/&scope=email,public_profile`;
