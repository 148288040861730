const ActionTypes = {
  ADD_TO_LISTING_QUERY: 'ADD_TO_LISTING_QUERY',
  REMOVE_FROM_LISTING_QUERY: 'REMOVE_FROM_LISTING_QUERY',
  ADD_TO_EMAIL_SMS_QUERY: 'ADD_TO_EMAIL_SMS_QUERY',
  REMOVE_FROM_EMAIL_SMS_QUERY: 'REMOVE_FROM_EMAIL_SMS_QUERY',
  ADD_TO_USER_QUERY: 'ADD_TO_USER_QUERY',
  REMOVE_FROM_USER_QUERY: 'REMOVE_FROM_USER_QUERY',
  ADD_TO_USER_ROLE_QUERY: 'ADD_TO_USER_ROLE_QUERY',
  REMOVE_FROM_USER_ROLE_QUERY: 'REMOVE_FROM_USER_ROLE_QUERY',
  ADD_TO_PARTNER_QUERY: 'ADD_TO_PARTNER_QUERY',
  REMOVE_FROM_PARTNER_QUERY: 'REMOVE_FROM_PARTNER_QUERY',
  ADD_TO_PHONE_NUMBER_QUERY: 'ADD_TO_PHONE_NUMBER_QUERY',
  REMOVE_FROM_PHONE_NUMBER_QUERY: 'REMOVE_FROM_PHONE_NUMBER_QUERY',
  ADD_TO_INCOMING_PAYMENTS: 'ADD_TO_INCOMING_PAYMENTS',
  REMOVE_INCOMING_PAYMENTS: 'REMOVE_INCOMING_PAYMENTS',
  ADD_TO_PAYOUTS_QUERY: 'ADD_TO_PAYOUTS_QUERY',
  REMOVE_FROM_PAYOUTS_QUERY: 'REMOVE_FROM_PAYOUTS_QUERY',
  ADD_TO_APP_HEALTH_QUERY: 'ADD_TO_APP_HEALTH_QUERY',
  REMOVE_FROM_APP_HEALTH_QUERY: 'REMOVE_FROM_APP_HEALTH_QUERY',
  ADD_TO_TEMPLATE_QUERY: 'ADD_TO_TEMPLATE_QUERY',
  REMOVE_FROM_TEMPLATE_QUERY: 'REMOVE_FROM_TEMPLATE_QUERY',
  ADD_TO_CHARTS_FILTER_QUERY: 'ADD_TO_CHARTS_FILTER_QUERY',
  REMOVE_FROM_CHARTS_FILTER_QUERY: 'REMOVE_FROM_CHARTS_FILTER_QUERY'
};

export default ActionTypes;
