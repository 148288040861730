import React from 'react';
import { AlertCircle, CheckCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { AppModal } from '@/components/ui';

const AppConfirmationModal = ({ id, checked = false, modalText, handleCLick }) => {
  const { t } = useTranslation();
  return (
    <AppModal id={id} type="modal-dialog-centered">
      <div className="confirmationModal padding-30 text-center">
        <div className="confirmationModal__icon margin-tb-20">
          {checked ? (
            <span className="text-orange">
              <AlertCircle />
            </span>
          ) : (
            <span className="text-primary">
              <CheckCircle />
            </span>
          )}
        </div>
        <div className="confirmationModal__text">
          <h5 className="mb-2 title text-default">Are you sure?</h5>
          <p className="subtitle">{modalText}</p>
        </div>
        <div className="margin-t-30">
          <button
            type="button"
            className="btn btn-secondary modal-btn me-3"
            data-bs-dismiss="modal"
          >
            {t('label.no')}
          </button>
          <button
            type="button"
            className={`btn ${checked ? 'btn-orange' : 'btn-primary'} modal-btn`}
            onClick={handleCLick}
            data-bs-dismiss="modal"
          >
            {t('label.yes')}
          </button>
        </div>
      </div>
    </AppModal>
  );
};

export default AppConfirmationModal;
