/* eslint-disable no-restricted-syntax */
/* eslint-disable no-self-compare */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppButton } from '../button';
import { uniqueId } from '@/core/utils';

const AppChipsMultiSelect = ({ id, data, onChange, checkedAll, customBtnGap = 'me-2 mb-2' }) => {
  const [select, setSelect] = useState(data);
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  const handleSelect = (label) => {
    const copy = [...select];
    const updateState = copy?.map((item) => {
      if (label === item.label) {
        item.checked = !item.checked;
      }
      return item;
    });
    setSelect(updateState);
  };

  useEffect(() => {
    const val = JSON.parse(sessionStorage.getItem('data'));
    if (val) {
      for (const item of select) {
        if (val?.includedInRent) {
          for (const rent of val?.includedInRent) {
            if (item.label === rent.label) {
              item.checked = !item.checked;
            }
          }
        }
      }
      setSelect(select);
    }
  }, []);

  const handleAllChecked = (e) => {
    const copyState = [...select];
    const updateState = copyState?.map((item) => {
      item.checked = e.target.checked;
      return item;
    });

    setSelect(updateState);
  };

  useEffect(() => {
    const selectedItem = select?.filter((v) => v.checked === true);
    setSelected(selectedItem);
  }, [select]);

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }
  }, [selected]);

  return (
    <div>
      {checkedAll ? (
        <div className="customized-checkbox checkbox-default mb-4">
          <input id={`checkbox-${id}`} type="checkbox" onClick={(e) => handleAllChecked(e)} />
          <label htmlFor={`checkbox-${uniqueId()}`} className="form-check-label">
            Check all
          </label>
        </div>
      ) : (
        ''
      )}

      {select.map((item) => (
        <AppButton
          btnClass={`${
            item.checked ? 'btn-sub-primary' : 'btn-outline-sub-primary'
          } ${customBtnGap} rounded-pill`}
          label={t(`list_your_place.included_in_rent.${item.label}`)}
          key={uniqueId()}
          handleClick={() => handleSelect(item.label)}
        />
      ))}
    </div>
  );
};

export default AppChipsMultiSelect;
