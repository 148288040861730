import { arrayMove } from 'array-move';
import React, { useEffect, useState } from 'react';
import { Menu } from 'react-feather';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { AppDropdownFilter } from '../dropdown';
import { AppToggleSwitch } from '@/components/ui/toggle-switch';

const SortableItem = SortableElement(({ value }) => (
  <div className="row p-tb-10 sortable__item">
    <div className="col-12 d-flex justify-content-between align-items-center">
      <div>
        <span className="sortable__logo">
          <Menu />
        </span>
        <span className="ms-2 text-capitalize sortable__text">{value.title}</span>
      </div>
      <AppToggleSwitch id={value.key} />
    </div>
  </div>
));

const SortableList = SortableContainer(({ items, modifiedTableHeader }) => (
  <div className="sortable__container">
    {items.map((value, index) => (
      <SortableItem
        key={`item-${value.key}`}
        index={index}
        value={value}
        modifiedTableHeader={modifiedTableHeader}
      />
    ))}
  </div>
));

const AppFilterDraggable = ({ tableHeader }) => {
  const modifiedTableHeader = tableHeader.map((item) => ({ ...item, isChecked: true }));

  const [items, setItems] = useState(modifiedTableHeader);

  useEffect(() => {
    // console.log(items);
  }, [items]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex));
  };

  const handleClear = () => {
    // console.log();
  };

  const handleApply = () => {
    // console.log();
  };

  return (
    <AppDropdownFilter handleClear={handleClear} handleApply={handleApply} state={items?.length}>
      <div className="dropdown-header">
        <div className="dropdown-header-text">
          <h6 className="dropdown-header-title padding-t-30">Want to manage data table?</h6>
          <p className="dropdown-header-subtitle padding-t-10 text-wrap">
            Drag and drop columns to reorder the data table and enable visibility.
          </p>
        </div>
      </div>
      <div className="dropdown-item custom-scrollbar filter-draggable">
        <SortableList
          items={items}
          distance={1}
          onSortEnd={onSortEnd}
          modifiedTableHeader={modifiedTableHeader}
        />
      </div>
    </AppDropdownFilter>
  );
};

export default AppFilterDraggable;
