import React, { useContext } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { themeContext } from '../../layout/context';

const AppPreloader = ({ count, duration, width = '', height = '', circle }) => {
  // context
  const { theme } = useContext(themeContext);

  //  color code
  const colorCode = () => {
    if (theme === 'dark') {
      return {
        color: '#202020',
        highlightColor: '#444'
      };
    }
    if (theme === 'light') {
      return {
        color: '#eee',
        highlightColor: '#f8f8f8'
      };
    }
    return '';
  };
  return (
    <SkeletonTheme color={colorCode().color} highlightColor={colorCode().highlightColor}>
      <p>
        <Skeleton count={count} duration={duration} width={width} height={height} circle={circle} />
      </p>
    </SkeletonTheme>
  );
};

export default AppPreloader;
