/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CheckCircle, Edit3, Folder, Image, XCircle } from 'react-feather';
import { uid } from 'uid';
import { last, size } from 'lodash';
import { useApolloClient, useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AppModal } from '@/components/ui';
import { GET_UPLOAD_POLICY } from '../../list-your-place/graphql/getUploadPolicy.gql';
import { toastCall, getFormattedDate } from '@/core/utils';
import fileUploadWithUploadPolicy from '@/views/list-your-place/description-photos/helpers/fileUploadWithUploadPolicy';
import { UPLOAD_LEASE_FILES } from '../graphql/uploadFiles.gql';

const AddFilesModal = ({
  id,
  leaseInfo,
  handleNewFiles,
  openAddFileModal,
  setOpenAddFileModal
}) => {
  const toastId = useRef(null);
  const client = useApolloClient();
  const { t } = useTranslation();

  const [files, setFiles] = useState([
    {
      name: '',
      path: '',
      preview: '',
      type: '',
      edit: false
    }
  ]);
  const [isFileProcess, setIsFileProcess] = useState(false);
  const [loading, setLoading] = useState(false);

  // get upload policy data
  const getUploadPolicy = async (imageName) => {
    try {
      const { data, errors } = await client.query({
        query: GET_UPLOAD_POLICY,
        variables: {
          queryData: {
            filename: imageName,
            directive: 'Files',
            subFolderName: `${Cookies.get('partnerId')}/contract`
          }
        }
      });
      return size(errors) ? errors[0] : data;
    } catch (error) {
      return { error: error?.message || 'Internal server error' };
    }
  };

  const onDrop = async (acceptedFiles, fileRejections) => {
    if (fileRejections?.length !== 0) {
      toastCall(
        'danger',
        t('action.file_size_cannot_exceed', {
          countMax: 25000000 / 1000000
        }),
        'top-right'
      );
    }

    const newFiles = acceptedFiles?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );

    if (size(newFiles) && !size(fileRejections)) {
      setLoading(true);
      setIsFileProcess(true);

      const allSuccessFiles = [];
      const updatedFiles = await Promise.all(
        newFiles?.map(async (acceptedFile) => {
          const fileName = acceptedFile?.name?.split('.');
          const imageExt = last(fileName);
          const fileTitle = acceptedFile?.name;

          const fileInfo = {
            name: `${uid()}.${imageExt}`,
            title: fileTitle,
            path: acceptedFile?.path,
            preview: acceptedFile?.preview,
            type: acceptedFile?.type,
            file: acceptedFile,
            size: acceptedFile?.size,
            uploadedAt: getFormattedDate(new Date(), 'DD.MM.YYYY'),
            newTitle: fileTitle
          };

          await getUploadPolicy(fileInfo.name)
            .then(async (response) => {
              if (response && response?.error) {
                toast.dismiss(toastId.current);
                toastCall('danger', t('action.file_type_is_not_supported'), 'top-right');
              }
              if (size(response?.getUploadPolicy) && !response?.error) {
                const uploadPolicy = response?.getUploadPolicy;
                await fileUploadWithUploadPolicy(
                  uploadPolicy?.url,
                  uploadPolicy?.policyData,
                  fileInfo?.file,
                  fileInfo?.name
                )
                  .then((updateFile) => {
                    if (updateFile?.error) {
                      toast.dismiss(toastId.current);
                      toastCall('danger', t('action.failed_to_upload_file'), 'top-right');
                    }
                    if (updateFile?.success) {
                      allSuccessFiles.push(fileInfo);
                    }
                  })
                  .catch((error) => {
                    toast.dismiss(toastId.current);
                    toastCall('danger', t('action.failed_to_upload_file'), 'top-right');
                  });
              }
            })
            .catch((error) => {
              toast.dismiss(toastId.current);
              toastCall('danger', t('action.failed_to_upload_file'), 'top-right');
            });
        })
      );

      if (size(updatedFiles)) {
        setFiles((prevData) => [...prevData, ...allSuccessFiles]);
        setLoading(false);
        setIsFileProcess(false);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxSize: 25000000,
    multiple: true
  });

  const [uploadFiles] = useMutation(UPLOAD_LEASE_FILES);
  const handleUploadLeaseFiles = async () => {
    const leaseFiles = files?.map((item) => ({
      propertyId: leaseInfo.propertyId,
      contractId: leaseInfo.id,
      directive: 'Files',
      context: 'contract',
      subContext: 'lease',
      leaseSerial: leaseInfo.leaseSerial,
      isVisibleToTenant: true,
      isVisibleToLandlord: true,
      title: item.title,
      size: item.size,
      name: item?.name
    }));

    if (size(leaseFiles)) {
      setLoading(true);
      setIsFileProcess(true);

      try {
        const response = await uploadFiles({
          variables: {
            inputData: { files: leaseFiles, requestFrom: 'partner_public' }
          }
        });
        if (size(response?.data?.uploadFiles)) {
          const uploadedFile = response?.data?.uploadFiles
            ?.map((resultInfo) => ({
              _id: resultInfo?._id,
              title: resultInfo?.title,
              uploadedAt: resultInfo?.createdAt
                ? getFormattedDate(resultInfo?.createdAt, 'DD.MM.YYYY')
                : ''
            }))
            ?.reverse();

          if (size(uploadedFile)) {
            handleNewFiles(uploadedFile);
            setLoading(false);
            setIsFileProcess(false);
            toastCall('success', t('action.file_uploaded_successfully'), 'top-right');
            setFiles([]);
            if (id) {
              document.getElementById(id)?.click();
            }
          }
        }
      } catch (error) {
        setLoading(false);
        setIsFileProcess(false);
        toast.dismiss(toastId.current);
        toastCall('danger', t('action.failed_to_upload_file'), 'top-right');
      }
    }
  };

  const handleTitleChange = (name, newTitle) => {
    setFiles(
      files.map((file) => {
        if (file.name === name) {
          return { ...file, newTitle };
        }
        return file;
      })
    );
  };

  const handleSaveChangedTitle = (name) => {
    setFiles(
      files.map((file) => {
        if (file.name === name) {
          return { ...file, edit: false, title: file.newTitle ? file.newTitle : file.title };
        }
        return file;
      })
    );
  };

  // update by enter key event
  const enterKeyEvent = async (e, name) => {
    if (e.keyCode === 13) {
      if (name) {
        await handleSaveChangedTitle(name);
      }
    }
  };

  const ButtonText = () => {
    if (loading) {
      return 'loading..';
    }
    // if (size(failedFiles)) return 'Retry to save';
    return t('user_profile.save');
  };

  const customBtn = (
    <button
      type="button"
      className={`btn btn-primary ms-2 `}
      disabled={!size(files) || loading}
      onClick={() => handleUploadLeaseFiles()}
      // data-bs-dismiss="modal"
    >
      <CheckCircle /> <ButtonText />
    </button>
  );

  const modal = document.getElementById(id);
  useEffect(() => {
    modal?.addEventListener('shown.bs.modal', () => {
      setFiles([]);
      setOpenAddFileModal(false);
    });
  }, [modal]);

  return (
    <AppModal
      id={id}
      type="modal-lg"
      options={{
        title: t('leases.add_files'),
        modalHeader: true,
        modalFooter: true,
        btnClose: true,
        customBtn,
        modalDialogClass: 'modal-dialog-centered width-30'
      }}
    >
      <div className="padding-lr-30 padding-tb-20 add-files-modal">
        <div className={`custom-image-upload-wrapper ${isFileProcess ? 'disabled' : ''}`}>
          {files?.map((file, index) => (
            <>
              {file.name !== '' && (
                <div className="d-flex justify-content-start flex-wrap align-items-center mb-3 ">
                  {file.edit ? (
                    <>
                      <div className="appEditableInput">
                        <div className="editableInputs">
                          <input
                            id={file.name}
                            type="text"
                            className="form-control"
                            value={file.newTitle}
                            onKeyDown={(e) => enterKeyEvent(e, file.name)}
                            onChange={(e) => handleTitleChange(file.name, e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="inline-edit-input-button d-flex align-items-center margin-l-5">
                        <div
                          role="button"
                          className={`text-success  me-2 `}
                          tabIndex="0"
                          onClick={() => handleSaveChangedTitle(file.name)}
                        >
                          <CheckCircle />
                        </div>
                        <div
                          role="button"
                          className="text-muted me-2"
                          tabIndex="0"
                          onClick={() =>
                            setFiles(
                              files.map((el) => {
                                if (el.name === file.name) {
                                  return { ...el, edit: false };
                                }
                                return el;
                              })
                            )
                          }
                        >
                          <XCircle />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="svg-icon-20 fill-tertiary icon-hover"
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          const temp = [...files];
                          temp.splice(index, 1);
                          setFiles(temp);
                        }}
                      >
                        <XCircle />
                      </div>
                      <div>
                        <h6 className="montserrat-500 font-16 text-sub-primary margin-l-15">
                          {file.title}
                        </h6>
                      </div>

                      <div
                        className=" margin-l-20 cursor-pointer d-flex align-items-center edit-file-name"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                          setFiles(
                            files.map((el) => {
                              if (el.name === file.name) {
                                return { ...el, newTitle: el.title, edit: true };
                              }
                              return el;
                            })
                          )
                        }
                      >
                        <div className="svg-icon-18">
                          <Edit3 />
                        </div>

                        <h6 className="montserrat-500 font-14 text-sub-primary margin-l-15">
                          {t('leases.rename')}
                        </h6>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          ))}

          <div
            className={`dropzone dropzone2 ${isDragAccept ? 'acceptDropzone2' : 'dropzone2'} ${
              isDragReject ? 'rejectDropzone2' : 'dropzone2'
            }`}
            {...getRootProps(isDragActive, isDragAccept, isDragReject)}
          >
            <div className="dz-default dz-message">
              <input {...getInputProps()} />
              <button type="button" className="btn btn-light">
                <Folder />
                {t('leases.choose_from_file')}
              </button>
              <h6 className="text-center text-gray mt-3">{t('leases.or_drag_drop_here')}</h6>
            </div>
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default AddFilesModal;
