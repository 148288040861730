import axios from 'axios';
import Cookies from 'js-cookie';
import fx from '@/lib/money';

import getApiBaseUrl from '@/core/utils/getApiBaseUrl';

export const getExchangeInfo = async () => {
  try {
    const exchangeInfo = sessionStorage.getItem('openExchangeInfo');
    if (exchangeInfo) return exchangeInfo;

    const BASE_API_URL = getApiBaseUrl();
    const url = `${BASE_API_URL}/graphql`;
    const accessToken =
      Cookies.get('accessToken') ||
      process.env.REACT_APP_API_KEY ||
      process.env.REACT_APP_SYSTEM_API_KEY;
    if (!accessToken) return {};

    const exchangeQuery = {
      query: `
      query GET_EXCHANGE_INFO {
        settings{
          data {
            openExchangeInfo{
              base
              rates
            }
          }
        }
     }`
    };

    const response = await axios.post(url, exchangeQuery, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    const openExchangeInfo = response?.data?.data?.settings?.data[0]?.openExchangeInfo;

    if (response && openExchangeInfo) {
      sessionStorage.setItem('openExchangeInfo', JSON.stringify(openExchangeInfo));
      fx.fxSetup = {
        base: openExchangeInfo?.base,
        rates: openExchangeInfo?.rates
      };
      return openExchangeInfo;
    }
    return {};
  } catch (e) {
    return {};
  }
};

export const getSelectedCurrency = () => sessionStorage.getItem('currency') || 'NOK';

const getPriceByCurrency = (price = 0, ceil = false, fromCurrency = 'USD', toCurrency) => {
  try {
    if (!fx.base || !fx.rates) {
      const exchangeInfo = sessionStorage.getItem('openExchangeInfo');
      const result = JSON.parse(exchangeInfo);

      fx.base = result?.base;
      fx.rates = result?.rates;
    }

    const newCurrency = toCurrency || 'NOK';

    const convertedAmount = fx.convert(price, { from: fromCurrency, to: newCurrency });
    return ceil ? Math.ceil(convertedAmount) : convertedAmount;
  } catch (e) {
    return ceil ? Math.ceil(price) : price;
  }
};
export default getPriceByCurrency;
