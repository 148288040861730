import React from 'react';

const Krone = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M8.74995 7.58349C8.74995 6.61699 9.27229 5.8335 9.91661 5.8335"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.08331 9.3335L4.08331 4.08352"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74995 9.3335L8.74996 5.83351"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99997 9.33348L4.66665 7.29182L6.99997 5.8335"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7727 1.1665H2.22726C1.6415 1.1665 1.16666 1.81942 1.16666 2.62483V11.3748C1.16666 12.1802 1.6415 12.8331 2.22726 12.8331H11.7727C12.3584 12.8331 12.8333 12.1802 12.8333 11.3748V2.62483C12.8333 1.81942 12.3584 1.1665 11.7727 1.1665Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="13.9999" height="13.9999" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Krone;
