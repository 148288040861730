// check environment, and get appropriate base API URL

const getApiBaseUrl = () => {
  let BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
  if (process.env.REACT_APP_STAGE === 'dev') BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
  if (process.env.REACT_APP_STAGE === 'staging') BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
  if (process.env.REACT_APP_STAGE === 'demo') BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
  return BASE_API_URL;
};

export default getApiBaseUrl;
